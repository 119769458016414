import firebase from './Firebase';
const moment = require('moment');
export const utils = {
  getRidOfChat: () => {
    let chatEl = document.querySelector("#tlx-webchat-container");
    if (chatEl) {
      chatEl.remove();
    }
  },
  formatNumberWithCommas: (arg) => {
    if (arg) {
      if (parseFloat(arg) >= 1000000) {
        return `${utils.convertToInternationalCurrencySystem(arg)}`;
      } else {
        return `${arg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      }
    } else {
      return "N/A";
    }
  },
  uploadFromBlobAsync: async ({ file, name }) => {
    if (!file || !name) return null;
    try {
      // const blob = await fetch(blobUrl).then((r) => r.blob())
      // const snapshot = await firebase.storage.ref().child(name).put(blob)
      const snapshot = await firebase.uploadBytes(firebase.storageRef(firebase.storage, name), file);
      // return await snapshot.ref.getDownloadURL();
      return await firebase.getDownloadURL(snapshot.ref);
    } catch (error) {
      throw error;
    }
  },
  cloneObj: (obj) => {
    return JSON.parse(JSON.stringify(obj));
  },
  isNullOrEmpty: (item) => {
    if (typeof item === "undefined" || item === null) {
      return true;
    }
    if (typeof item === "string") {
      return item.trim() === "";
    } else if (typeof item === "object") {
      return Object.keys(item).length === 0;
    } else if (typeof item === "number") {
      return item < 0;
    }
    return false;
  },
  getDate: () => {
    return moment().format("L");
  },
  convertToInternationalCurrencySystem: (labelValue) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
      : // Three Zeroes for Thousands

        Math.abs(Number(labelValue));
  },
  formatAsCAD: (arg) => {
    if (arg) {
      if (parseFloat(arg) >= 1000000) {
        return `$${utils.convertToInternationalCurrencySystem(arg)}`;
      } else {
        return `$${(+arg)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      }
    } else {
      return "N/A";
    }
  },
  formatDate: (date) => {
    return moment(date).format("DD MMM YYYY");
  },
  formatAmericanDate: (date) => {
    return moment(date).format("MM/DD/YYYY");
  },
  formatPhoneNumber: (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  },
  formatLogTime: (date) => {
    return moment(date).format("MM-DD-YY hh:mm:ss");
  },
  addDays: (date, numDays) => {
    return moment(utils.formatAmericanDate(date)).add(numDays, "days").format("MM/DD/YYYY");
  },
  getDaysFromToday: (targetDate) => {
    if (targetDate) {
      var start = moment(new Date(), "YYYY-MM-DD");
      var end = moment(targetDate, "MM/DD/YYYY");
      //Difference in number of days
      return Math.ceil(moment.duration(end.diff(start)).asDays());
    } else {
      return 0;
    }
  },
  convertCADateToDate: (targetDate) => {
    return moment(targetDate, "MM/DD/YYYY").toDate();
  },
  formatTitle: (text) => {
    return text.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },
  validateEmail: (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/.test(email)) {
      return true;
    }
    return false;
  },
  hasNumber: (myString) => {
    return /\d/.test(myString);
  },
  hasLowerCase: (str) => {
    return /[a-z]/.test(str);
  },
  hasUpperCase: (str) => {
    return /[A-Z]/.test(str);
  },
  hasSpecialChar: (str) => {
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (format.test(str)) {
      return true;
    } else {
      return false;
    }
  },
  getParameterByName: (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },
  validateCAPostalCode: (postalCode) => {
    if (!postalCode) {
      return false;
    }
    postalCode = postalCode.toString().trim();
    let ca = new RegExp(/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i);
    if (ca.test(postalCode.toString())) {
      return true;
    }
    return false;
  },
  formatCAPostalCode: (postalCode) => {
    postalCode = postalCode.replace(/\s/g, "");
    let partA = postalCode.substring(0, 3).toUpperCase();
    let partB = postalCode.substring(3, 6).toUpperCase();
    return `${partA} ${partB}`;
  },
  validatePassword: (pass) => {
    let validCount = 0;
    if (pass.length < 8) {
      return false;
    }
    if (utils.hasNumber(pass)) {
      validCount++;
    }
    if (utils.hasUpperCase(pass)) {
      validCount++;
    }
    if (utils.hasLowerCase(pass)) {
      validCount++;
    }
    if (utils.hasSpecialChar(pass)) {
      validCount++;
    }
    return validCount >= 3;
  },
  getAdminConfigDoc: (docName) => {
    return new Promise(function (resolve, reject) {
      firebase.db
        .collection("era")
        .doc("speed")
        .collection("adminConfig")
        .doc(docName)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            resolve(doc.data());
          } else {
            reject("Cannot find config");
          }
        })
        .catch(function (error) {
          console.log("Cannot find config", error);
        });
    });
  },
  handleFileUpload: (event, folderPath) => {
    console.log("Event", event);
    const files = event;
    const formData = new FormData();
    formData.append("files", files);
    formData.append("documentPath", folderPath);
    return new Promise(function (resolve, reject) {
      fetch(firebase.fileUploadUrl, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  makeWebRequest: (url, method, body) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: method,
      headers: myHeaders,
      redirect: "follow",
    };

    if (body) {
      var raw = JSON.stringify(body);
      requestOptions.body = raw;
    }

    return new Promise(function (resolve, reject) {
      fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let json = JSON.parse(result);
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  makeWebRequestForApplications: (url, method, body) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: method,
      headers: myHeaders,
      redirect: "follow",
    };

    if (body) {
      var raw = JSON.stringify(body);
      requestOptions.body = raw;
    }

    return new Promise(function (resolve, reject) {
      firebase.auth.currentUser.getIdToken().then((token) => {
        url = `${url}?token=${token}`;
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            let json = JSON.parse(result);
            if (json.success) {
              resolve(json.data);
            } else {
              reject(json.error);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  },
  sortData: (property, order = "desc", columnType = "string") => {
    var sort_order = 1;
    if (order === "desc") {
      sort_order = -1;
    }
    switch (columnType) {
      case "string":
        return function (a, b) {
          let varA = typeof a[property] === "string" ? a[property].toUpperCase() : a[property];
          let varB = typeof b[property] === "string" ? b[property].toUpperCase() : b[property];
          if (varA < varB) {
            return -1 * sort_order;
          } else if (varA > varB) {
            return 1 * sort_order;
          } else {
            return 0 * sort_order;
          }
        };
      case "date":
        return function (a, b) {
          let dateA = new Date(a[property]);
          let dateB = new Date(b[property]);
          if (dateA < dateB) {
            return -1 * sort_order;
          } else if (dateA > dateB) {
            return 1 * sort_order;
          } else {
            return 0 * sort_order;
          }
        };
      case "number":
        return function (a, b) {
          a = parseFloat(a[property]);
          b = parseFloat(b[property]);
          if (a < b) {
            return -1 * sort_order;
          } else if (a > b) {
            return 1 * sort_order;
          } else {
            return 0 * sort_order;
          }
        };
      default:
        break;
    }
  },
  cleanHTMLText: (html) => {
    var div = document.createElement("div");
    div.innerHTML = html;
    let ans = div.textContent;
    div.remove();
    return ans;
  },
  getTEM: (measure) => {
    let options = [
      "Total Emissions Reduction",
      "Total Emissions Reduction - VFD for Fan/Pump Motor",
      "Total Emissions Reduction - Strip Curtain",
      "Total Emissions Reduction - Water Heater",
      "Total Emissions Reduction - Insulation",
      "Total Emissions Reduction - Windows",
      "Total Emissions Reduction - Economizer",
      "Total Emissions Reduction - Steam Fittings and Pipe Insulation",
      "Total Emissions Reduction - Steam Boiler",
      "Total Emissions Reduction - Boiler or Heater",
      "Total Emissions Reduction - ASHP or VRF",
      "Total Emissions Reduction - Make-Up Air Unit",
      "Total Emissions Reduction - HRV/ERV",
      "Total Emissions Reduction - Pipe Insulation",
    ];
    for (let item of options) {
      if (measure[item]) {
        return parseFloat(measure[item]).toFixed(2);
      }
    }
    return "";
  },
  sumOfTEMs: (measures) => {
    let ans = 0.0;
    for (let item of measures) {
      ans += parseFloat(utils.getTEM(item));
    }
    return ans.toFixed(2);
  },
  getTEMI: (measure) => {
    let options = [
      "Total Eligible Measure Incentive",
      "Total Eligible Measure Incentive - VFD for Fan/Pump Motor",
      "Total Eligible Measure Incentive - Strip Curtain",
      "Total Eligible Measure Incentive - Water Heater",
      "Total Eligible Measure Incentive - Insulation",
      "Total Eligible Measure Incentive - Windows",
      "Total Eligible Measure Incentive - Economizer",
      "Total Eligible Measure Incentive - Steam Fittings and Pipe Insulation",
      "Total Eligible Measure Incentive - Steam Boiler",
      "Total Eligible Measure Incentive - Boiler or Heater",
      "Total Eligible Measure Incentive - ASHP or VRF",
      "Total Eligible Measure Incentive - Make-Up Air Unit",
      "Total Eligible Measure Incentive - HRV/ERV",
      "Total Eligible Measure Incentive - Pipe Insulation",
    ];
    for (let item of options) {
      if (measure[item]) {
        return parseFloat(measure[item]).toFixed(2);
      }
    }
    return "";
  },
  sumOfTEMIs: (measures) => {
    let ans = 0.0;
    for (let item of measures) {
      ans += parseFloat(utils.getTEMI(item));
    }
    if (ans >= 1000000) {
      ans = 1000000.0;
    }
    return ans.toFixed(2);
  },
  getApplicableTEMI: (measure) => {
    let options = [
      "Total Applicable Measure Incentive",
      "Total Applicable Measure Incentive - VFD for Fan/Pump Motor",
      "Total Applicable Measure Incentive - Strip Curtain",
      "Total Applicable Measure Incentive - Water Heater",
      "Total Applicable Measure Incentive - Insulation",
      "Total Applicable Measure Incentive - Windows",
      "Total Applicable Measure Incentive - Economizer",
      "Total Applicable Measure Incentive - Steam Fittings and Pipe Insulation",
      "Total Applicable Measure Incentive - Steam Boiler",
      "Total Applicable Measure Incentive - Boiler or Heater",
      "Total Applicable Measure Incentive - ASHP or VRF",
      "Total Applicable Measure Incentive - Make-Up Air Unit",
      "Total Applicable Measure Incentive - HRV/ERV",
      "Total Applicable Measure Incentive - Pipe Insulation",
    ];
    for (let item of options) {
      if (measure[item]) {
        return parseFloat(measure[item]).toFixed(2);
      }
    }
    return 0.0;
  },
  sumOfApplicableTEMIs: (measures, applicationObj) => {
    let maxVal = 1000000.0;
    if (applicationObj && applicationObj["Reviewer Estimated Incentive"]) {
      maxVal = parseFloat(applicationObj["Reviewer Estimated Incentive"]);
      maxVal = maxVal * 1.05; // Set to 105% of original
    }
    let ans = 0.0;
    for (let item of measures) {
      ans += parseFloat(utils.getApplicableTEMI(item));
    }
    if (ans >= maxVal) {
      ans = maxVal;
    }
    return ans.toFixed(2);
  },
};