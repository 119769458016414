import React from 'react';
import Button from '../../Common/Button/Button'
import Input from '../../Common/Input/Input'
import './Login.css';
import { connect } from 'react-redux';
import { userActions } from '../../../redux/actions/userActions';
import { userService } from '../../../services/userService';
import { Link, Redirect } from 'react-router-dom';
import { utils } from '../../../helpers/utils';
import { AuthContext } from '../../../firebaseAuthContext';
import FrontendLayout from '../../Common/FrontendLayout';
import Modal from '../../Common/Modal/Modal';
import SpeedOverlayLogo from "../../../images/SpeedOverlayLogo.svg"

class Login extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      forgotPasswordModalOpen: false,
      userBlockInfoModal: false,
    }
  }

  onChangeHandler = (name, value) => {
    this.props.setField(name, value);
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.submitForm();
    }
  }

  verifyForm = () => {
    const { email, password } = this.props;
    let errors = {};
    let errorCount = 0;
    if (utils.isNullOrEmpty(email)) {
      errors.email = 'Email is required.'
      errorCount++;
    }

    if (utils.isNullOrEmpty(password)) {
      errors.password = 'Password is required.'
      errorCount++;
    }

    if (errorCount > 0) {
      this.props.setError(errors);
    }
    return errorCount === 0;
  }


  submitForm = () => {
    const { email, password } = this.props;
    if (this.verifyForm()) {
      // this.props.login(email, password);
      userService.getUserLoginActivities(email).then((activities) => {
        const { failCount, userExists } = activities;
        if (userExists === false) {
          let errors = {};
          errors.password = "You have entered an invalid username/password. Please try again.";
          this.props.setError(errors);
        }
        if (failCount === 5) {
          this.setState({
            userBlockInfoModal: true,
          });
        } else {
          userService
            .login(email, password)
            .then(async (user) => {
              userService.unblockUserAccount({ email: email, program: "speed" });
              return user;
            })
            .catch((error) => {
              let errors = {};
              let { code, message } = error;
              if (code === "auth/user-not-found") {
                errors.password = "You have entered an invalid username/password. Please try again.";
                this.props.setError(errors);
              } else {
                if (failCount < 2 && code === "auth/wrong-password") {
                  userService.updateUserLoginActivities({ email: email, program: "speed" }).then((data) => {
                    errors.password = "You have entered an invalid username/password. Please try again.";
                    this.props.setError(errors);
                  });
                } else if (failCount >= 2 && (code === "auth/wrong-password" || code === "auth/too-many-requests")) {
                  if(failCount === 4) {
                    this.setState({
                      userBlockInfoModal: true,
                    });
                  }
                  userService.updateUserLoginActivities({ email: email, program: "speed" }).then((data) => {
                    errors.password = `You have entered an invalid username/password (${+(failCount)+1}/5). After 5 unsuccessful attempts, the account associated with the email id will be blocked`;
                    this.props.setError(errors);
                  });
                }
              }
            });
        }
      });
    }
  }
  openResetPasswordModal = () => {
    this.closeBlockInfoModal();
    this.setState({
      forgotPasswordModalOpen: "reset"
    })
  }

  checkError = (err) => {
    if (err.code === "auth/wrong-password") {
      return "The password does not match the email";
    }
  }
  clearLoginForm = () => {
    this.props.clearLoginForm();
  }
  openForgotPassword = (open) => {
    if (!open) {
      this.props.clearPasswordReset();
    }
    this.setState({ forgotPasswordModalOpen: open });
  }
  closeBlockInfoModal = () => {
    this.setState({
      userBlockInfoModal: false,
    });
  };

  render() {
    if (this.context.user) {
      console.log('Redirecting')
      return <Redirect to='/account' />;
    }
    const { userBlockInfoModal } = this.state;
    const { email, password, errors, sendPasswordReset, passwordResetting, passwordReset, passwordResetError } = this.props;
    const thereAreErrors = (!utils.isNullOrEmpty(errors.email) || !utils.isNullOrEmpty(errors.password));
    const validEmail = utils.validateEmail(email);
    return <FrontendLayout wrapperClass={'login-wrapper'}>
      <div className='image-container'>
        <img className='spp-logo' src={SpeedOverlayLogo} />
      </div>
      <div className="login-container">
      <div className="container-box">
        <div className="section-header">
          <h2 className="section-title">WELCOME</h2>
        </div>
        <Modal
          className="user-blocked-modal"
          HeaderText={"ACCOUNT BLOCKED"}
          IsOpen={userBlockInfoModal}
          handleCloseModal={this.closeAdminBlockInfoModal}
          modalSize={"small"}
        >
          <React.Fragment>
            <p>You have entered the incorrect password too many times. Your account has been blocked for security purposes.</p>
            <p>Reset your password now to unblock your account.</p>
            <div className="button-set">
              <Button className="inversed-btn" title={"Go Back"} onClick={this.closeBlockInfoModal} uppercase />
              <Button title={"Reset Password"} onClick={this.openResetPasswordModal} uppercase />
            </div>
          </React.Fragment>
        </Modal>
        <Modal
          HeaderText={this.state.forgotPasswordModalOpen === 'reset' ? 'RESET PASSWORD' : passwordResetError ? 'ERROR' :
          passwordReset ? 'PASSWORD RESET SUCCESSFUL' : 'FORGOT PASSWORD?'}
          IsOpen={this.state.forgotPasswordModalOpen}
          handleCloseModal={() => this.openForgotPassword(false)}
          loading={passwordResetting}
        >
          {passwordReset ? <div>
            <p>A password reset email has been sent to <span className="highlighted-text">{email}</span></p>
          </div> : passwordResetError ? <div>
            <p className="error-text">{passwordResetError}</p>
          </div> : <div>
                {validEmail ? <p>A <span className="highlighted-text">reset password</span> email will be sent to <span className="highlighted-text">{email}</span></p> : ''}
                {!validEmail ? <p>First please enter a valid email value in the <span className="highlighted-text">email</span> field</p> : ''}
                <div className='button-set'>
                  <Button
                    onClick={() => this.openForgotPassword(false)}
                    className='inversed-btn'
                    title={this.state.forgotPasswordModalOpen === 'reset' ? "Cancel" : 'Cancel Forgot Password'}
                    uppercase
                  />
                  <Button
                    title={'SEND'}
                    uppercase
                    disabled={!validEmail}
                    onClick={() => {sendPasswordReset(email); userService.unblockUserAccount({ email: email, program: "speed" });}}
                  />
                </div>
              </div>}
        </Modal>
        <div className="form-section">
          <Input
            onChange={this.onChangeHandler}
            placeholder={'Enter Email'}
            type='email'
            name='email'
            value={email}
            error={errors ? errors['email'] : null}
          />
          <Input
            value={password}
            name='password'
            onChange={this.onChangeHandler}
            placeholder={'Enter Password'}
            type={'password'}
            onKeyDown={this._handleKeyDown}
            error={errors ? errors['password'] : null}
          />
          <Button
            className='login-btn'
            title={'Login'}
            large
            onClick={this.submitForm}
            disabled={thereAreErrors}
            uppercase
          />
          <div className="login-links"><p onClick={() => this.openForgotPassword(true)} className='clickable'>Forgot Password?</p></div>
          <div className="login-links">Don't have an account? <Link to='/register'  onClick={() => this.clearLoginForm} >Register for the program</Link></div>
        </div>
      </div>
    </div></FrontendLayout>
  }
}

function mapState(state) {
    const { loggingIn, errors, email, password, passwordResetting, passwordReset, passwordResetError  } = state.authentication;
    return { loggingIn, errors, email, password, passwordResetting, passwordReset, passwordResetError };
}

const actionCreators = {
  login: userActions.login,
  setError: userActions.setLoginError,
  setField: userActions.setLoginField,
  sendPasswordReset: userActions.passwordReset,
  clearPasswordReset: userActions.clearPasswordReset,
  clearLoginForm:userActions.clearLoginForm
}

export default connect(mapState, actionCreators)(Login);
