import React from "react";
import ReactDOM from "react-dom";
import "./RegisterLoginButton.css";
import { userActions } from "../../../redux/actions/userActions";
import { Link } from "react-router-dom";
import { ReferenceData } from "../../../contentFile";
import { connect } from "react-redux";
import { AuthContext } from "../../../firebaseAuthContext";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import PostProjectReview from '../PostProjectReview/PostProjectReview';
import MailIcon from "../../../images/MailVector.svg";
import PhoneIcon from "../../../images/CallIcon.svg";
import Input from "../Input/Input";
import { appActions } from "../../../redux/actions/appActions";
import firebase from "../../../helpers/Firebase";
import history from "../../../services/history";
class RegisterLoginButton extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      userMenuExpand: false,
      menuOpen: false,
      openModal: false,
      openPostApplicationModal: false,
      contactModalOpen: false,
      applicationModalOpen: false,
      newApplicationType: "0",
      startAppTypes: [
        // { value: 1, label: `Start New Application` },
        { value: 2, label: "Start Post-project" },
      ],
    };
  }

  handlenewAppChange = (name, data) => {
    if (this.state.menuOpen === true) {
      this.setMenuOpen();
    }
    this.setState({
      newApplicationType: data.value,
    });

    if (data.value === 1) {
      this.toggleApplicationModal(true);
      this.setOpenPOSTApplicationModal(false);
    } else {
      this.setOpenPOSTApplicationModal(true);
      this.toggleApplicationModal(false);
    }
  };
  setOpenModal = (status) => {
    this.setState({
      openModal: status,
    });
  };
  setOpenPOSTApplicationModal = (status) => {
    this.setState({
      openPostApplicationModal: status
    });
  };
  setUserMenuExpand = (e) => {
    console.log(e);
    e.nativeEvent.stopImmediatePropagation();
      this.setState({
        userMenuExpand: !this.state.userMenuExpand,
      });
  };
  // closeUserMenuExpand = () => {
  //   this.setState({ userMenuExpand : false});
  // }

  setMenuOpen = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  };

  toggleContactModal = (open) => {
    this.setMenuOpen();
    this.setState({
      contactModalOpen: open,
    });
  };

  toggleApplicationModal = (open) => {
    this.setState({
      applicationModalOpen: open,
    });
  };

  handleClick = () => {
    const loggedIn = this.context.user ? true : false;
    if (loggedIn) {
      this.setMenuOpen();
      this.props.showLogoutModal(true);
    } else {
      this.props.showRegisterModal(true);
    }
  };
  componentDidMount() {
    const { uid } = firebase.auth.currentUser;
    const { userInfo } = this.props;
    this.props.loadUserDetails(uid);
    this.props.getPreapprovedApps(uid);
    document.addEventListener('click', this.handleClickOutside, true);
  }
  componentWillUnmount(){
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  startNewApplication = () => {
    this.toggleApplicationModal(false);
    window.location = `/application/new`;
  }
  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
    // event.stopPropagation();
    if(!domNode || !domNode.contains(event.target)){
      this.setState({userMenuExpand: false});
    }
  }

  render() {
    const user = this.context.user;
    const loggedIn = user ? true : false;
    const { userInfo } = this.props;
    const { showRegisterModal, showLogoutModal, preapprovedApps } = this.props;
    const {
      menuOpen,
      userMenuExpand,
      contactModalOpen,
      applicationModalOpen,
      openPostApplicationModal,
    } = this.state;
    return (
      <React.Fragment>
        <Modal
          HeaderText={"START A NEW APPLICATION"}
          IsOpen={applicationModalOpen}
          handleCloseModal={() => this.toggleApplicationModal(false)}
        >
          <div>
            <p>This will start a new application process.</p>
            <p>Are you sure?</p>
            <div className="button-set">
              <Button title={"GO BACK"} uppercase className="inversed-btn" onClick={() => this.toggleApplicationModal(false)} />
              <Button title={"START NEW APPLICATION"} uppercase onClick={() => this.startNewApplication()} />
            </div>
          </div>
        </Modal> 
        <Modal
          HeaderText={"START POST-PROJECT APPLICATION"}
          IsOpen={openPostApplicationModal}
          handleCloseModal={() => this.setOpenPOSTApplicationModal(false)}
        >
          <PostProjectReview history={this.props.history} closeModal={() => this.setOpenPOSTApplicationModal(false)}/>
        </Modal>
        
        <Modal HeaderText={"REGISTER/LOG IN"} IsOpen={this.props.registerModalShown} handleCloseModal={() => showRegisterModal(false)}>
          <p>Do you currently have an account with us?</p>
          <div className="button-set">
            <Link to="/">
              <Button className="inversed-btn" title={"YES, LOG IN"} uppercase onClick={() => showRegisterModal(false)} />
            </Link>
            <Link to="/register">
              <Button title={"NO, CREATE ACCOUNT"} uppercase onClick={() => showRegisterModal(false)} />
            </Link>
          </div>
        </Modal>
        <Modal HeaderText={"LOG OUT"} IsOpen={this.props.logoutConfirmationModalShown} handleCloseModal={() => showLogoutModal(false)}>
          <p>Are you sure you want to log out?</p>
          <div className="button-set logout">
            <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => showLogoutModal(false)} />
            <Button
              title={"YES, LOG ME OUT"}
              uppercase
              onClick={() => {
                showLogoutModal(false);
                this.props.logOut();
              }}
            />
          </div>
        </Modal>
        <Modal HeaderText={"CONTACT US"} IsOpen={contactModalOpen} handleCloseModal={() => this.toggleContactModal(false)}>
          <div>
            <div className="flexHorizontal modal-section">
              <img alt='mail icon' src={MailIcon} />
              <p>
                <a href="mailto:speed@esbprogram.ca">speed@esbprogram.ca</a>
              </p>
            </div>
            <div className="flexHorizontal modal-section">
              <img alt='phone icon' src={PhoneIcon} />
              <p>
                Toll-Free Number - <a href="tel:+18444070025">844-407-0025</a>
              </p>
            </div>
          </div>
        </Modal>
        {loggedIn ? (
          <React.Fragment>
            <div className={`burger-menu`} onClick={this.setMenuOpen}>
              <div className={`burger-icon ${menuOpen ? "open-menu" : "close-menu"}`} />
              <div className={`burger-icon ${menuOpen ? "open-menu" : "close-menu"}`} />
              <div className={`burger-icon ${menuOpen ? "open-menu" : "close-menu"}`} />
            </div>
            <ul className={`nav-ul ${menuOpen ? "menu-expand" : ""}`}>
              <li className={`menuItem menu-title`}>
                <a rel="noreferrer" target="_blank" href="https://eralberta.ca/speed">
                  Program
                </a>
              </li>
              <li className={`menuItem menu-title `} onClick={() => this.toggleContactModal(true)}>
                <p>Contact Us</p>
              </li>
              {/* {userInfo &&
              userInfo.accountCategory === "customer" &&
              (userInfo.status === "Participating" || userInfo.status === "Flagged for QA/QC") ? (
                //
                <React.Fragment>
                  {(preapprovedApps && preapprovedApps.length > 0) ? (
                    <li className={`menuItem menu-title new-application-dropdown start-project`}>
                      <span>
                        <div className="start-application-dropdown">
                          <Input
                            placeholder={"Start an Application"}
                            type="dropdown"
                            name="applicationType"
                            onChange={this.handlenewAppChange}
                            value={newApplicationType}
                            iconEnabled={true}
                            uppercase
                            options={startAppTypes}
                            enableTyping={'no'}
                          />
                        </div>
                      </span>
                    </li>
                  ) : ( 
                    <li className={`menuItem menu-title new-application-link`}>
                      <p onClick={() => this.toggleApplicationModal(true)}>Start New Application</p>{" "}
                    </li>
                  )}{" "}
                </React.Fragment>
              ) : (
                ""
              )} */}
              <li className="menuItem account-name-menu-item">
                <div className={`account-name-container ${userMenuExpand ? "user-menu-expanded" : ""}`}>
                  <div className="user-menu-items logout-icon" onClick={(e) => this.setUserMenuExpand(e)} title={user.displayName}>
                    {user.displayName}
                  </div>
                  <div className={`user-menu-items ${userMenuExpand ? "" : "hide-menu-items"}`}>
                    <Link to="/account">View Account</Link>
                  </div>
                  <div onClick={this.handleClick} className={`user-menu-items ${userMenuExpand ? "" : "hide-menu-items"}`}>
                    Log Out
                  </div>
                </div>
                <div className={`user-menu-container`}>
                  <div className="user-menu-items user-title">
                    Signed in as <br />
                    {user.displayName}
                  </div>
                  <div className="user-menu-items">
                    <a rel="noreferrer" target="_blank" href="https://eralberta.ca/speed">
                      Program
                    </a>
                  </div>
                  <div className="user-menu-items" onClick={() => this.toggleContactModal(true)}>
                    Contact Us
                  </div>
                  <div className="user-menu-items start-application-menu-item">
                    {userInfo &&
                    userInfo.accountCategory === "customer" &&
                    (userInfo.status === "Participating" || userInfo.status === "Flagged for QA/QC") ? (
                      <React.Fragment>
                        {(preapprovedApps && preapprovedApps.length > 0)  ? 
                        <div className="user-menu-items" onClick={() => this.setOpenPOSTApplicationModal(true)}>
                        Start Post-Project Application
                      </div> :  null }
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="user-menu-items" onClick={this.setMenuOpen}>
                    <Link to="/account">View Account</Link>
                  </div>
                  <div onClick={this.handleClick} className="user-menu-items">
                    Logout
                  </div>
                </div>
              </li>
            </ul>
          </React.Fragment>
        ) : (
          <div onClick={this.handleClick} className="register-button-container">
            <span className="register-label">{ReferenceData.SignUpLinkText}</span>
            <span className="login-register-link login-icon"></span>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { registerModalShown } = state.registration;
  const { userInfo, logoutConfirmationModalShown } = state.authentication;
  const { preapprovedApps,loadingPreapprovedApps } = state.application;
  return { userInfo, registerModalShown, logoutConfirmationModalShown,preapprovedApps,loadingPreapprovedApps };
}

const actionCreators = {
  loadUserDetails : userActions.getUserDetails,
  showRegisterModal: userActions.showRegisterModal,
  showLogoutModal: userActions.showLogoutConfirmationModal,
  updateApplication: appActions.updateApplication,
  getPreapprovedApps : appActions.getPreapprovedApplications,
  logOut: userActions.logout,
};

export default connect(mapState, actionCreators)(RegisterLoginButton);
