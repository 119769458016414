import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { appActions } from "../../../redux/actions/appActions";
import "./PostProjectReview.css";
import Input from "../Input/Input";
import Button from "../Button/Button";
import { AuthContext } from "../../../firebaseAuthContext";
import firebase from "../../../helpers/Firebase";
import Checkbox from '@material-ui/core/Checkbox';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { applicationService } from "../../../services/applicationService";

  const theme = createMuiTheme({
    palette: {
      primary: { 
          main : '#048041'
      },
      secondary : {
        main : '#048041'
      },
      text:{
          secondary: '#535765'
      }
    }
  });
class PostProjectReview extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      startPostProjectStep: props.preloadApp ? 2 : 1,
      postProjectAppIDSelection: props.preloadApp ? props.preloadApp : null,
      selectedAppNO: "",
      selectedAppID: "",
      checklist : {
        one : false,
        two : false,
        three : false
      }
    }
    };

  handleApprovalMessageChange = (name, value) => {
    this.setState({
      approvalMessage: value,
    });
  };

  async componentDidMount() {
    const { uid } = firebase.auth.currentUser;
    this.props.getPreapprovedApps(uid);
  }

  confirmApproval = () => {
    this.setState({
      confirmingApproval: !this.state.confirmingApproval,
    });
  };

  handlePostProjectAppIdChange = (name, data) => {
    this.setState({
      postProjectAppIDSelection: data,
    });
  };

  startPostProject=async()=>{
    const { startPostProjectStep } = this.state;
    if(startPostProjectStep <= 3){
      this.setState({
        startPostProjectStep : (startPostProjectStep +1)
      });
    } else {
      if(this.state.selectedAppID===''){
        await applicationService.updateApplication(this.state.postProjectAppIDSelection.value, {
          status : 'Application Draft', 
          stage:'Post-Project'
        });
        window.location = `/application/${this.state.postProjectAppIDSelection.value}`;
      } else{
        await applicationService.updateApplication(this.state.postProjectAppIDSelection.value, {
          status : 'Application Draft', 
          stage:'Post-Project'
        });
        window.location = `/application/${this.state.selectedAppID}`;
      }
    }
  }

  setCheckBox = (checkboxPos)=>{
    this.setState({
      checklist : {
        ...this.state.checklist,
        [checkboxPos] : !this.state.checklist[checkboxPos]
      }
    })
  }

  handleCheckBoxChanged = (checkboxPos, checked)=>{
    this.setState({
      checklist : {
        ...this.state.checklist,
        [checkboxPos] : checked
      }
    })
  }

  render() {
    const { preapprovedApps } = this.props;
    const { startPostProjectStep, postProjectAppIDSelection, selectedAppNO, checklist } = this.state;
    let canGoToNextStep = false;
    let buttonTitle = 'Proceed';
    switch (startPostProjectStep) {
      case 1:
        buttonTitle = "Proceed";
        break;
      case 2:
        buttonTitle = "No Change Required";
        break;
      case 3:
        buttonTitle = "Start Post-Project";
        break;
      case 4:
        buttonTitle = "Yes, Start Application";
        break;
      default:
        buttonTitle = "Proceed";
    }

    if(startPostProjectStep <=2){
      canGoToNextStep = !(selectedAppNO==='' && !postProjectAppIDSelection);
    } else if (startPostProjectStep >= 3) {
      canGoToNextStep = (!(selectedAppNO==='' && !postProjectAppIDSelection)) && (checklist.one && checklist.two && checklist.three);
    }

    return <ThemeProvider theme={theme}><div>
      <div className="post-project-review-container">
      {startPostProjectStep === 1 ? 
              <div className="form-inner-section">
                <div className="form-label">Select a submitted application</div>
                <div className="form-element">
                  <Input
                    placeholder={"Select"}
                    type="dropdown"
                    name="postProjectAppIDSelection"
                    onChange={this.handlePostProjectAppIdChange}
                    value={postProjectAppIDSelection?postProjectAppIDSelection:undefined}
                    options={preapprovedApps && preapprovedApps.length>0 && preapprovedApps.map((app) => {
                      return { label: `#SPEED-${app.appNo}`, value: app.id };
                    })}
                    isSearchable={false}
                  />
                </div>
              </div>
      : ''}
      {startPostProjectStep === 2 ? <React.Fragment>
          <h3>Are you requesting a change to your application?</h3>
          <div className="form-text-section">
          <p>If you wish to add or change measures, apply for an extension or request and incentive increase of more than 5% of the pre-approved incentive, please go back and submit a Change Request</p>
          <p>If you require assistance, please email <a href="mailto:speed@esbprogram.ca">speed@esbprogram.ca</a></p>
          <p>If you start a Post-Project review, the <span>Change Request functionality will be disabled</span> and you will not be able to make any further changes to your application.</p>
          </div>
        </React.Fragment> : ''}
      {startPostProjectStep === 3 ? <React.Fragment>
        <h3>Review Post-Project Eligibility Checklist</h3>
        <div className="form-text-section">
          <div className="check-box-line" onClick={()=>{this.setCheckBox('one')}}>
            <Checkbox className="check-box" checked={checklist.one ? true : false} onChange={(e)=>{this.handleCheckBoxChanged('one',e.target.checked)}} />
            <p>Your project is completed. Your measures have been installed & are operational</p>
          </div>
          <div className="check-box-line" onClick={()=>{this.setCheckBox('two')}}>
            <Checkbox className="check-box" checked={checklist.two ? true : false} onChange={(e)=>{this.handleCheckBoxChanged('two',e.target.checked)}} />
            <p>You have obtained invoices, proof of payment, electrical safety permits and other documents.</p>
          </div>
          <div className="check-box-line" onClick={()=>{this.setCheckBox('three')}}>
            <Checkbox className="check-box" checked={checklist.three ? true : false} onChange={(e)=>{this.handleCheckBoxChanged('three',e.target.checked)}} />
            <p>Ensured your project's compliance with the <a href="https://firebasestorage.googleapis.com/v0/b/dolphin-live-a906a.appspot.com/o/SPEED%20TermsAndConditions.pdf?alt=media&token=bf02d654-d692-4b34-936f-d64334a86aea" target="_blank" rel="no-referrer">Terms and Conditions</a>, Eligible Measures List, conditions outlined in the Pre-Approval Notice and the signed Participant Acceptance</p>
          </div>
        </div>
      </React.Fragment> : ''}
      {startPostProjectStep === 4 ? 
              <div className="form-text-section">
              <p className="centered-text">This will start post-project Application process for Application ID <span className="green-label">{postProjectAppIDSelection.label}</span>. Are you sure?</p>
              </div>
      : ''}
      </div>
      <div className="button-set"> 
        <Button title={"GO BACK"} uppercase className="inversed-btn" onClick={this.props.closeModal} />
        <Button 
        onClick={() => this.startPostProject()} 
        title={buttonTitle} 
        uppercase disabled={!canGoToNextStep} 
        />
      </div>
    </div></ThemeProvider>
  }


}

function mapState(state) {
  const { preapprovedApps,loadingPreapprovedApps } = state.application;
  return { preapprovedApps,loadingPreapprovedApps };
}

const actionCreators = {
  showModal: userActions.showEditParticipantModal,
  updateApplication: appActions.updateApplication,
  getPreapprovedApps : appActions.getPreapprovedApplications
};

export default connect(mapState, actionCreators)(PostProjectReview);
