import React from "react";
import {
    Link
} from "react-router-dom";
import './Header.css';
import Modal from '../Modal/Modal';
import MailIcon from '../../../images/MailVector.svg';
import PhoneIcon from '../../../images/CallIcon.svg';
class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userMenuExpand: false,
            menuOpen: false,
            contactModalOpen:false
        }
    }
    
    setMenuOpen = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    toggleContactModal = (open)=>{
        this.setState({
            contactModalOpen : open
        })
    }

    render(){
    const { menuOpen, contactModalOpen } = this.state;

    return (
        <React.Fragment>
            <header className="header">
            <Modal
                HeaderText={'CONTACT US'}
                IsOpen={contactModalOpen}
                handleCloseModal={() => this.toggleContactModal(false)}
            >
                <div>
                    <div className="flexHorizontal modal-section">
                        <img src={MailIcon}/>
                        <p><a href="mailto:speed@esbprogram.ca">speed@esbprogram.ca</a></p>
                    </div>
                    <div className="flexHorizontal modal-section">
                        <img src={PhoneIcon}/>
                        <p>
                            Toll-Free Number - <a href='tel:+18444070025'>844-407-0025</a><br/>
                        </p>
                    </div>
                </div>
        </Modal>
            <div className="header-inner-container">
                <nav>
                    <Link to='/' className="logo">
                    </Link>
                    <div className={`burger-menu`} onClick={this.setMenuOpen}>
                        <div className={`burger-icon ${menuOpen ? 'open-menu' : 'close-menu'}`} />
                        <div className={`burger-icon ${menuOpen ? 'open-menu' : 'close-menu'}`} />
                        <div className={`burger-icon ${menuOpen ? 'open-menu' : 'close-menu'}`} />
                    </div>
                    <ul className={`nav-ul ${menuOpen ? 'menu-expand' : ''}`} >
                        <li className={`menuItem`}><a rel="noreferrer" target="_blank" href="https://eralberta.ca/speed">Program</a></li>
                        <li onClick={()=>this.toggleContactModal(true)} className={`menuItem`}>Contact Us</li>
                    </ul>
                </nav>
            </div> 
        </header>
        </React.Fragment>
    )
    }
}

export default (HeaderComponent);