export const MenuList = [
    {
        menuId: 1,
        path: '/programs',
        menuTitle: 'Programs'
    },
    {
        menuId: 2,
        path: '/applications',
        menuTitle: 'Applications'
    },
    {
        menuId: 3 ,
        path: '/support',
        menuTitle: 'Support'
    },
    {
        menuId: 4 ,
        path: '/contact',
        menuTitle: 'Contact'
    }
]

export const SignUpFormData = {
    title : `Register now to participate in<br/>Small Producers Program`
}

export const AddParticipantTitle = {
    title : `Add a participant to the Small Producers Program`
}

export const EditDetailsFormData = {
    incompleteTitle : `COMPLETE PROFILE AND SUBMIT FOR REGISTRATION`,
    completeTitle : `EDIT PROFILE DATA`
}

export const ReferenceData = {
    SignUpLinkText : 'Register/Log In',
    LogOutLinkText : 'LOG OUT',
    FooterText : 'Developed by Enerva Energy Solutions Inc.'
}
export const AccountDetailsPending = {
    ParticipantCategory: 'Contractor',
    FirstName: 'Mayuran',
    LastName: 'Srikantha',
    CompanyName: 'Enerva Energy Solutions Inc.',
    PhoneNumber: '647-500-1800',
    Email: 'mayuran@enerva.ca', 
    Address_Line_1: '1030 King Street West',
    Address_Line_2: '',
    City: 'Edmonton',
    Province: 'Alberta',
    PostalCode: 'M6K 0B4',
    Status: 'Pending',
}
export const AccountDetailsReview = {
    accountCategory: 'Contractor',
    FirstName: 'Mayuran',
    LastName: 'Srikantha',
    CompanyName: 'Enerva Energy Solutions Inc.',
    PhoneNumber: '647-500-1800',
    Email: 'mayuran@enerva.ca', 
    Address_Line_1: '1030 King Street West',
    Address_Line_2: '',
    City: 'Edmonton',
    Province: 'Alberta',
    PostalCode: 'M6K 0B4',
    Status: 'Review',
}
export const AccountDetailsVerified = {
    accountCategory: 'Contractor',
    FirstName: 'Mayuran',
    LastName: 'Srikantha',
    CompanyName: 'Enerva Energy Solutions Inc.',
    PhoneNumber: '647-500-1800',
    Email: 'mayuran@enerva.ca', 
    Address_Line_1: '1030 King Street West',
    Address_Line_2: '',
    City: 'Edmonton',
    Province: 'Alberta',
    PostalCode: 'M6K 0B4',
    Status: 'Verified',
}

export const CustomerList = [
    {
        CustomerID: 'CUS-87239',
        Name: 'Belle Gonzales',
        Status: 'Email Verification Pending',
        CreatedDate: '29 Mar 2019',
        EmailID: 'belle@hotmail.com'
    },
    {
        CustomerID: 'CUS-87239',
        Name: 'Belle Gonzales',
        Status: 'Email Verification Pending',
        CreatedDate: '29 Mar 2019',
        EmailID: 'belle@hotmail.com'
    },
    {
        CustomerID: 'CUS-87239',
        Name: 'Belle Gonzales',
        Status: 'Email Verification Pending',
        CreatedDate: '29 Mar 2019',
        EmailID: 'belle@hotmail.com'
    },
    {
        CustomerID: 'CUS-87239',
        Name: 'Belle Gonzales',
        Status: 'Email Verification Pending',
        CreatedDate: '29 Mar 2019',
        EmailID: 'belle@hotmail.com'
    },
    {
        CustomerID: 'CUS-87239',
        Name: 'Belle Gonzales',
        Status: 'Email Verification Pending',
        CreatedDate: '29 Mar 2019',
        EmailID: 'belle@hotmail.com'
    }
]

export const ContractorList = [
    {
        ContractorID: 'CUS-87239',
        Name: 'Belle Gonzales',
        Status: 'Email Verification Pending',
        CreatedDate: '29 Mar 2019',
        EmailID: 'belle@hotmail.com'
    },
    {
        ContractorID: 'CUS-87239',
        Name: 'Belle Gonzales',
        Status: 'Email Verification Pending',
        CreatedDate: '29 Mar 2019',
        EmailID: 'belle@hotmail.com'
    },
    {
        ContractorID: 'CUS-87239',
        Name: 'Belle Gonzales',
        Status: 'Email Verification Pending',
        CreatedDate: '29 Mar 2019',
        EmailID: 'belle@hotmail.com'
    },
    {
        ContractorID: 'CUS-87239',
        Name: 'Belle Gonzales',
        Status: 'Email Verification Pending',
        CreatedDate: '29 Mar 2019',
        EmailID: 'belle@hotmail.com'
    },
    {
        ContractorID: 'CUS-87239',
        Name: 'Belle Gonzales',
        Status: 'Email Verification Pending',
        CreatedDate: '29 Mar 2019',
        EmailID: 'belle@hotmail.com'
    }
]