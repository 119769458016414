
import firebase from '../helpers/Firebase';
import { utils } from '../helpers/utils';
const API_URL = firebase.apiUrl;

export const userService = {
    getAdminConfig,
    checkIfEmailExists,
    checkEmailVerification,
    login,
    logout,
    register,
    getUserDetails,
    resetPassword,
    changePassword,
    sendEmailVerificationLink,
    sendPasswordResetEmail,
    updateUserDetailsSPP,
    getLegalDoc,
    getLegalDocs,
    sendApplicationSharedEmail,
    getPaymentPin,
    resendEmail,
    getUserLoginActivities,
    updateUserLoginActivities,
    unblockUserAccount
};

function checkIfEmailExists(emailId){
    return utils.makeWebRequest(`${API_URL}/users/emailexists`,"POST", {emailId});
}

function resendEmail(email){
    return utils.makeWebRequest(`${API_URL}/users/spp/participant/resendemail`,"POST", {email});
}

function checkEmailVerification(verifyObj){
    return utils.makeWebRequest(`${API_URL}/users/spp/emailverificationcheck`,"POST", verifyObj);
}

function sendAccountManagementEmail(task, email){
    return utils.makeWebRequest(`${API_URL}/users/accountmanagement`,"POST", {task,email});
}

function sendAccountManagementEmailSPP(task, email){
    return utils.makeWebRequest(`${API_URL}/users/spp/accountmanagement`,"POST", {task,email});
}

function sendApplicationSharedEmail(email, applicationObj){
    let task = 'contractor-save-inform-customer';
    return utils.makeWebRequest(`${API_URL}/users/accountmanagement`,"POST", {task,email,applicationObj});
}

function login(username, password) {
    return firebase.auth.signInWithEmailAndPassword(username, password);
}

function changePassword(username, oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
        firebase.auth.signInWithEmailAndPassword(username, oldPassword)
        .then((_) => {
            let user = firebase.auth.currentUser;
            user.updatePassword(newPassword).then(function() {
                resolve("Successfully changed password");
              }).catch(function(error) {
                // An error happened.
                reject(error);
              });
        })
        .catch((error) => {
            reject(error);
        });
    });
}

function logout() {
    return firebase.auth.signOut().then(() => {
        // Sign-out successful.
      });
}

function getUserDetails(userId, callback) {
    firebase.db.collection('era').doc('speed').collection('userInfo').doc(userId).get().then(function(doc) {
        if (doc.exists) {
            callback(true, doc.data());
        } else {
            callback(false);
        }

    }).catch(function(error) {
        console.log("Error getting document:", error);
        callback(false);
    });
}

function getPaymentPin(appId,callback){ 
    firebase.db.collection('paymentPins').doc(appId).get().then(function(doc) {
        if (doc.exists) {
            callback(true, doc.data());
        } else {
            callback(false);
        }

    }).catch(function(error) {
        console.log("Error getting document:", error);
        callback(false);
    });
}
function resetPassword(email){
    return firebase.auth.sendPasswordResetEmail(email).then(function() {
        // Email sent.
    });
}

function getLegalDocs(){
    return utils.makeWebRequest(`${API_URL}/admin/spp/legaldocs`,"GET");
}

function getLegalDoc(legalDocName){
    return utils.makeWebRequest(`${API_URL}/admin/spp/legaldocs/${legalDocName}`,"GET");
}

function getAdminConfig(formName){
    return utils.makeWebRequest(`${API_URL}/admin/spp/adminconfig${formName ? '/' + formName : ''}`,"GET");
}

async function sendEmailVerificationLink(email){
    return sendAccountManagementEmailSPP('resendEmailVerification',email);
}

async function sendPasswordResetEmail(email){
    return utils.makeWebRequest(`${API_URL}/users/spp/resetpassword`,"PUT", { email });
}

async function updateUserDetailsSPP(uid, userInfo) {
    return firebase.db.collection('era').doc('speed').collection('userInfo').doc(uid).update(userInfo);
}

function register(user) {
    const passwordToSet = user.passwordOne;
    let postalCode = '';
    if(!utils.isNullOrEmpty(user.manualPostalCode)){
      postalCode = utils.formatCAPostalCode(user.manualPostalCode);
    } 
    else{
      postalCode = user.locationInfo ? user.locationInfo.postalCode : '';
    }

    const userInfo = {
        accountCategory : user.accountCategory,
        firstName : user.firstName.trim(),
        lastName : user.lastName.trim(),
        howDidYouHear : user.howDidYouHear,
        email:user.email.trim(),
        terms:user.terms,
        companyDetails : {
            name : user.companyName.trim(),
            website : user.companyWebsite.trim(),
            address1 : user.locationInfo.address,
            address2 : user.address2,
            city : user.locationInfo.city,
            province : user.locationInfo.province,
            postalCode : postalCode.trim(),
            location : user.locationInfo.location_data
        },
        // isIndigenousOwned:user.isIndigenousOwned,
        isOwnedBySomeoneFromURG:user.isOwnedBySomeoneFromURG,
        phoneNum : user.phoneNum.trim(),
        status : 'Email Verification Pending'
    }    

    return utils.makeWebRequest(`${API_URL}/users/spp/participant`,"POST", {
        email : user.email,
        password : passwordToSet,
        userInfo
    });
}

//block and unblock user api
function getUserLoginActivities(email) {
    return utils.makeWebRequest(`${API_URL}/useractivity/checkforblock?email=${email}`,"GET");
}

function updateUserLoginActivities(data) {
    return utils.makeWebRequest(`${API_URL}/useractivity/checkforblock`,"PUT", data);
}

function unblockUserAccount(data) {
    return utils.makeWebRequest(`${API_URL}/useractivity/unblock`,"PUT", data);
}
// function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 // auto logout if 401 response returned from api
//                 logout();
//                 location.reload(true);
//             }

//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }

//         return data;
//     });
// }