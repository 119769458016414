import React from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../firebaseAuthContext';
import InternalLayout from '../../Common/InternalLayout';
import Button from '../../Common/Button/Button';
import Loader from '../../Common/Loader/Loader';
import './ApplicationSubmitted.css';
import { applicationService } from '../../../services/applicationService';
import { userService } from '../../../services/userService';
class ApplicationSubmitted extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      applicationId: props.match.params.appid,
      appData : null,
      loaded : false    
    }
  }

  componentDidMount(){
    applicationService.getApplication(this.state.applicationId).then((appData)=>{
      this.setState({
        appData,
        loaded : true
      });
    });
  }

  render() {
    const { appData } = this.state;
    return <InternalLayout showHeader={true}>
      <div className="app-confirmation-wrapper-3">
      {this.state.loaded ? <React.Fragment>
        <h3>Application Submitted</h3>
        <p>Your application has been successfully submitted.</p>
        <p>Your application ID# is <span>SPEED-{appData.appNo}</span>.</p>
        <p>Once you complete your project, you can log-in and create and submit the post-project application and apply for your incentive payment.</p>
        <div className="button-set">
          <Link to="/account">
            <Button
              title={'LOG OUT'}
              uppercase
              className="inversed-btn"
              onClick={userService.logout}
            />
            <Button
              title={'Go to Account Page'}
              uppercase
            />
          </Link>
          
        </div>
        </React.Fragment> : <Loader/>}
      </div>
    </InternalLayout>
  }
}

export default ApplicationSubmitted;