import {userActionTypes} from './userActionTypes';
import {userService} from '../../services/userService';
import firebase from '../../helpers/Firebase';


export const userActions = {
    getAdminConfig,
    checkIfEmailExists,
    setLoginError,
    setLoginField,
    showRegisterModal,
    showSubmitSuccessModal,
    showSubmitProfileModal,
    showLogoutConfirmationModal,
    showAddParticipantModal,
    passwordReset,
    changePassword,
    clearPasswordReset,
    clearChangePassword,
    clearLoginForm,
    login,
    logout,
    register,
    updateUserDetails,
    submitProfile,
    getUserDetails,
    verifyEmail,
    delete: _delete,
    setEmailVerified,
  //  getPaymentPin
};


function setEmailVerified(isVerified){
    return { type :  userActionTypes.SET_EMAIL_VERIFIED, isVerified };
}

function setLoginError(errors){
    return { type : userActionTypes.LOGIN_SET_ERROR, errors};
}

function setLoginField(key, val){
    return { type : userActionTypes.LOGIN_SET_FIELD, fieldName : key, fieldVal : val};
}

function showSubmitSuccessModal(show){
    return { type : userActionTypes.SHOW_SUBMIT_SUCCESS_MODAL, show};
}

function showRegisterModal(show){
    return { type : userActionTypes.SHOW_REGISTER_MODAL, show};
}

function showSubmitProfileModal(show){
    return { type : userActionTypes.SHOW_SUBMIT_PROFILE_MODAL, show};
}

function showLogoutConfirmationModal(show){
    return { type : userActionTypes.SHOW_LOGOUT_CONFIRMATION_MODAL, show};
}

function showAddParticipantModal(show){
    return { type : userActionTypes.SHOW_ADD_PARTICIPANT_MODAL, show};
}

function mapErrors(firebaseError){
    let errors = {};
    let { code, message } = firebaseError;
    if(code === 'auth/wrong-password'){
        errors['password'] = 'You have entered an invalid username or password';
    } else if(code === 'auth/user-not-found'){
        errors['password'] = 'You have entered an invalid username or password';
    } else {
        errors['password'] = message;
    }
     return errors;
}

function extractErrMessage(firebaseError){
    return firebaseError.message;
}

function clearPasswordReset(){
    return { type: userActionTypes.PASSWORD_RESET_CLEAR }
}
function clearLoginForm(){
    return { type: userActionTypes.LOGIN_FORM_RESET_CLEAR }
}
function clearChangePassword(){
    return { type: userActionTypes.CHANGE_PASSWORD_CLEAR }
}

function checkIfEmailExists(email){
    return dispatch => {
        userService.checkIfEmailExists(email)
            .then(
                result => { 
                    dispatch(request(result.data.userExists));
                },
                error => {
                    dispatch(request(false));
                }
            );
    };

    function request(userExists) { return { type: userActionTypes.CHECK_USER_EXISTS, userExists } }
}

function getAdminConfig(specificDoc){
    return dispatch => {
        dispatch(request());
        userService.getAdminConfig(specificDoc)
            .then((data) => { 
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userActionTypes.GET_ADMIN_CONFIG_REQUEST } }
    function success(data) { return { type: userActionTypes.GET_ADMIN_CONFIG_SUCCESS, data } }
    function failure(error) { return { type: userActionTypes.GET_ADMIN_CONFIG_FAILURE, error: extractErrMessage(error) } }
}

function verifyEmail(email){
    return dispatch => {
        dispatch(request());
        userService.sendEmailVerificationLink(email)
            .then(
                result => { 
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userActionTypes.EMAIL_VERIFICATION_REQUEST } }
    function success() { return { type: userActionTypes.EMAIL_VERIFICATION_SUCCESS } }
    function failure(error) { return { type: userActionTypes.EMAIL_VERIFICATION_FAILURE, error: extractErrMessage(error) } }
}

function passwordReset(email){
    return dispatch => {
        dispatch(request());
        userService.sendPasswordResetEmail(email)
            .then(()=>{
                dispatch(success());
            }).catch((error)=>{
                dispatch(failure(`Error the email ${email} does not exist in our records.`));
            });
    };

    function request() { return { type: userActionTypes.PASSWORD_RESET_REQUEST } }
    function success() { return { type: userActionTypes.PASSWORD_RESET_SUCCESS } }
    function failure(error) { return { type: userActionTypes.PASSWORD_RESET_FAILURE, error } }
}

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));
        userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(user) { return { type: userActionTypes.LOGIN_REQUEST, user } }
    function success(user) { return { type: userActionTypes.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userActionTypes.LOGIN_FAILURE, errors: mapErrors(error) } }
}

function changePassword(username, oldPassword, newPassword) {
    return dispatch => {
        dispatch(request());
        userService.changePassword(username, oldPassword, newPassword)
            .then(
                message => { 
                    dispatch(success(message));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userActionTypes.CHANGE_PASSWORD_REQUEST } }
    function success(message) { return { type: userActionTypes.CHANGE_PASSWORD_SUCCESS, message } }
    function failure(error) { return { type: userActionTypes.CHANGE_PASSWORD_FAILURE, error: extractErrMessage(error) } }
}

function logout() {
    userService.logout();
    return { type: userActionTypes.LOGOUT };
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then((result)=>{
                const { authToken } = result;
                firebase.auth.signInWithCustomToken(authToken).then((user)=>{
                    dispatch(success(user.user));
                })
            }).catch((error)=>{
                dispatch(failure(error.message));
            });
    };

    function request(user) { return { type: userActionTypes.REGISTER_REQUEST, user } }
    function success(user) { return { type: userActionTypes.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userActionTypes.REGISTER_FAILURE, error } }
}

function submitProfile(uid, userDetails) {
    return dispatch => {
        dispatch(request());
        userService.updateUserDetailsSPP(uid, userDetails)
            .then(
                result => { 
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userActionTypes.SUBMIT_PROFILE_REQUEST } }
    function success() { return { type: userActionTypes.SUBMIT_PROFILE_SUCCESS } }
    function failure(error) { return { type: userActionTypes.SUBMIT_PROFILE_FAILURE, error } }
}

function updateUserDetails(uid, userDetails) {
    return dispatch => {
        dispatch(request());
        userService.updateUserDetailsSPP(uid, userDetails)
            .then(
                user => { 
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userActionTypes.UPDATE_USER_REQUEST } }
    function success() { return { type: userActionTypes.UPDATE_USER_SUCCESS } }
    function failure(error) { return { type: userActionTypes.UPDATE_USER_FAILURE, error } }
}

function getUserDetails(userId) {
    return dispatch => {
        dispatch(request());
        userService.getUserDetails(userId, (successful, userDetails)=>{
            if(successful){
                dispatch(success(userDetails));
            } else {
                dispatch(failure("Error"));
            }
        });
    };

    function request() { return { type: userActionTypes.GET_USER_DETAILS_REQUEST } }
    function success(userDetails) { return { type: userActionTypes.GET_USER_DETAILS_SUCCESS, userDetails } }
    function failure(error) { return { type: userActionTypes.GET_USER_DETAILS_FAILURE, error } }
}

// function getPaymentPin(id) {
//     return dispatch => {
//         dispatch(request());
//         userService.getUserDetails(id, (successful, paymentPinDetails)=>{
//             if(successful){
//                 dispatch(success(paymentPinDetails));
//             } else {
//                 dispatch(failure("Error"));
//             }
//         });
      
//     };

//     function request() { return { type: userActionTypes.GET_PAYMENT_PIN_REQUEST } }
//     function success(paymentPinDetails) { return { type: userActionTypes.GET_PAYMENT_PIN_SUCCESS, paymentPinDetails } }
//     function failure(error) { return { type: userActionTypes.GET_PAYMENT_PIN_FAILURE, error } }
// }
// prefixed function name with underscore because delete is a reserved word in javascript 
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userActionTypes.DELETE_REQUEST, id } }
    function success(id) { return { type: userActionTypes.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userActionTypes.DELETE_FAILURE, id, error } }
}