import React from "react";
import Button from "../../Common/Button/Button";
import Input from "../../Common/Input/Input";
import "./ViewAppDetails.css";
import { connect } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { appActions } from "../../../redux/actions/appActions";
import { utils } from "../../../helpers/utils";
import LeftArrow from "../../../images/leftArrow.svg";
import { AuthContext } from "../../../firebaseAuthContext";
import InternalLayout from "../../Common/InternalLayout";
import TooltipInfo from "../../Common/TooltipInfo/TooltipInfo";
import Loader from "../../Common/Loader/Loader";
import ResponsiveTable from "../../Common/ResponsiveTable/ResponsiveTable";
import { ReactComponent as ViewIcon } from "../../../images/ViewIcon.svg";
import { userService } from "../../../services/userService";
import { applicationService } from "../../../services/applicationService";
import firebase from "../../../helpers/Firebase";
import Modal from "../../Common/Modal/Modal";
import ChangeReqLogs from "../../Common/ChangeReqLogs/ChangeReqLogs";
import ErrorNotFound from "../ErrorNotFound/ErrorNotFound";
import { Route, Redirect } from "react-router-dom";
import LegaleseContainer from "../../Common/LegaleseContainer/LegaleseContainer";

class ViewAppDetails extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);

    this.state = {
      applicationId: props.match.params.id,
      submitAppModalOpen: false,
      agreementText: "",
      appReviewData: {},
      userInfo: null,
      editing: false,
      cancelModalOpen: false,
      deleteMeasureModalOpen: false,
      deleteDocumentModalOpen: false,
      submitForReviewModalOpen: false,
      unauthorisedAccess: false,
    
      addNoteModalOpen: false,
      slideIndex: 0,
      slideTotal: 6,
      dataLoaded: false,
      errors: {},
      documentsList: [],
      selectedFacility: {},
      selectedMeasure: {},
      openDrawer: false,
      opeModal: false,
      confirmingAppNote: false,
      appNoteSubmitting: false,
      appNoteSuccessfullySubmitted: false,
      payeeDetails: undefined,
      appNote: "",
      applicationObj: {
        stage: "Pre-Project",
        status: "Application Draft",
        facilityId: "",
        contractorId: "",
        contractor: "",
        contractorCanEdit: false,
        projectName: "",
        projectStartDate: "",
        projectCompletionDate: "",
        isReceivingOtherFunding: "",
        sourcesOfAllOtherFunding: "",
        totalAmountOfAllOtherFunding: "",
        amountOfEligibleExpenses: "",
        agreeToApplicationTerms: false,
      },
      participantAcceptanceCheckList: {
        participantAcceptance1: false,
        participantAcceptance2: false,
        participantAcceptance3: false,
        participantAcceptance4: false,
        participantAcceptance5: false,
        participantAcceptance6: false,
        participantAcceptance7: false,
        participantAcceptance8: false,
      },
    };
  }

  editApplication = () => {
    this.props.history.push(`/application/${this.props.match.params.id}`);
  };

  addNoteToApp = async () => {
    let { applicationId, appNote } = this.state;
    let data = {
      applicationId,
      customerNote: appNote,
    };
    this.setState(
      {
        appNoteSubmitting: true,
      },
      async () => {
        await applicationService.addCustomerNoteToApp(data);
        this.setState({
          appNoteSubmitting: false,
          appNoteSuccessfullySubmitted: true,
        });
      }
    );
  };

  verifyParticipantAcceptance = () => {
    const { participantAcceptanceCheckList, agreementText } = this.state;
    let errorCount = 0;
    let errors = {};
    for (let key in participantAcceptanceCheckList) {
      if (!participantAcceptanceCheckList[key]) {
        errors[key] = true;
        errorCount++;
      }
    }
    if (utils.isNullOrEmpty(agreementText)) {
      errors["agreementText"] = "You are required to type the above sentence to proceed";
      errorCount++;
    } else {
      if (
        agreementText.toLowerCase() !==
        "i represent and warrant to emissions reduction alberta that i have authority to bind the participant"
      ) {
        errors["agreementText"] = "You are required to type the exact sentence mentioned above to proceed";
        errorCount++;
      }
    }
    if (errorCount > 0) {
      this.setState({ errors }, () => {
        let allErrorRefs = document.querySelectorAll(".input-box-error,.error-box");
        if (allErrorRefs.length > 0) {
          let firstErrorRef = allErrorRefs[0];
          firstErrorRef.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      });
    }
    return errorCount === 0;
  };

  submitApplication = () => {
    if (this.verifyParticipantAcceptance()) {
      this.setState({
        submitAppModalOpen: true,
      });
    }
  };

  finallySubmitApplication = () => {
    const { userInfo, applicationId, participantAcceptanceCheckList } = this.state;
    this.props.history.push(`/applicationsubmitted/${applicationId}`);
    let updateObj = {
      status: "Application Submitted",
      participantAcceptanceCheckList,
      actionTaker: `${userInfo.firstName} ${userInfo.lastName}`,
      actionNote: "",
    };
    this.props.updateApplication(applicationId, updateObj);
  };

  componentDidMount() {
    const appIdToLoad = this.props.match.params.id;
    userService.getUserDetails(firebase.auth.currentUser.uid, (success, userInfo) => {
      this.props.loadContractors();
      this.props.loadFacilityConfig();
      this.props.loadUserDetails();
      if (!utils.isNullOrEmpty(appIdToLoad)) {
        applicationService.getApplication(appIdToLoad).then(async (data) => {
          if(userInfo.uid === data.customerId || userInfo.uid === data.contractorId){
          let appReviewData = null;
          let postProjectAppReviewData = null;
          try {
            appReviewData = await applicationService.getApplicationReview(appIdToLoad);
          } catch (err) {}
          if (data.stage === "Post-Project" || data.stage === "Payment") {
            try {
              postProjectAppReviewData = await applicationService.getPostProjectApplicationReview(appIdToLoad);
            } catch (err) {}
          }
          userService.getUserDetails(data.customerId, (success, customerData) => {
            this.props.loadFacilities(data.customerId);
            this.props.loadAppMeasures(appIdToLoad);
            this.props.getAppDocuments(appIdToLoad);
            this.props.getChangeReqLogs(appIdToLoad);
            let applicationObj = { ...data };
            // console.log("app object for payee", applicationObj);
            if (applicationObj.payeeAssigned === true) {
              userService.getUserDetails(applicationObj.payeeInfo.uid, (success, payeeDetails) => {
                //  console.log("Payee data pulled===", payeeDetails);
                this.setState({
                  payeeDetails: payeeDetails,
                });
              });
            }
            let slideIndex = 0;
            if (userInfo.accountCategory === "contractor") {
              slideIndex = 2;
            }
            this.setState(
              {
                appReviewData: appReviewData,
                postProjectAppReviewData,
                customerId: data.customerId,
                customerData,
                userInfo,
                editing: true,
                applicationObj,
                slideIndex,
                dataLoaded: true,
              },
              () => {
                window.scrollTo(0, 0);
              }
            );
          }); }else{ 
            this.setState({unauthorisedAccess: true});
          }
        });
      }
    });
  }

  handleChangeSlide = (slideIndex) => {
    if (slideIndex === 2) {
      this.props.loadFacilities(this.state.customerId);
    }
    this.setState({ slideIndex: slideIndex++, errors: {} });
  };

  onContractorChange = (contractor) => {
    const { applicationObj, errors } = this.state;
    delete errors["contractor"];
    this.setState({
      applicationObj: {
        ...applicationObj,
        contractor,
        contractorId: contractor.value,
      },
      errors,
    });
  };

  onContractorAccessChange = (val) => {
    const { applicationObj, errors } = this.state;
    delete errors["contractorCanEdit"];
    this.setState({
      applicationObj: {
        ...applicationObj,
        contractorCanEdit: val.value,
      },
      errors,
    });
  };

  onChangeHandler = (name, value) => {
    const { applicationObj, errors } = this.state;
    delete errors[name];
    this.setState({
      applicationObj: {
        ...applicationObj,
        [name]: value,
      },
      errors,
    });
  };

  viewDocument = (document) => {
    window.open(document.url);
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  onChecklistChangeHandler = (name, value) => {
    let { participantAcceptanceCheckList, errors } = this.state;
    participantAcceptanceCheckList[name] = value;
    delete errors[name];
    this.setState({
      participantAcceptanceCheckList: {
        ...participantAcceptanceCheckList,
      },
      errors,
    });
  };

  onAgreementChangeText = (name, value) => {
    let { errors } = this.state;
    delete errors["agreementText"];
    this.setState({
      agreementText: value,
      errors,
    });
  };

  confirmAppNote = () => {
    this.setState({
      confirmingAppNote: !this.state.confirmingAppNote,
    });
  };

  applicationSummary = () => {
    const { applicationObj, appReviewData, postProjectAppReviewData, payeeDetails, userInfo } = this.state;
    const { measureList, documentList, facilityList } = this.props;
    let showSummaries = false;
    let chosenFacility = null;
    let draftApproved = applicationObj.status === "Application Draft Approved" && applicationObj.stage === "Pre-Approval";
    let appSubmitted = applicationObj.status === "Application Submitted" && applicationObj.stage === "Pre-Approval";
    if (userInfo) {
      showSummaries = userInfo.accountCategory === "customer" || userInfo.accountCategory === "contractor";
    }

    facilityList.forEach((f) => {
      if (f.id === applicationObj.facilityId) {
        chosenFacility = f;
      }
    });
    let approvalNote = "";
    if (appReviewData && appReviewData.informationRequest) {
      approvalNote = appReviewData.informationRequest;
    }
    let maxVal = 250000.0;
    if (applicationObj && applicationObj["Reviewer Estimated Incentive"]) {
      maxVal = parseFloat(applicationObj["Reviewer Estimated Incentive"]);
      maxVal = parseFloat((maxVal * 1.05).toFixed(2)); // Set to 105% of original
    }

    //SET UP FACILITY TABLE
    let facilityObj = {
      headings: [
        { title: "Facility Name", value: "Facility Name" },
        { title: "Region", value: "Region" },
        { title: "Facility Address", value: "Facility Address" },
        { title: "Facility Ownership", value: "Facility Ownership" },
        { title: "Facility Type", value: "Facility Type" },
        { title: "Facility Owner Approval Obtained", value: "Facility Owner Approval Obtained" },
      ],
      rows: [
        {
          "Facility Name": chosenFacility ? chosenFacility.facilityName : "",
          Region: chosenFacility ? chosenFacility.facilityApplicableRegion.value : "",
          "Facility Address": chosenFacility
            ? `${chosenFacility.facilityAddressLine1.label}, ${
                chosenFacility.facilityAddressLine2 ? ", " + chosenFacility.facilityAddressLine2 : ""
              } ${chosenFacility.facilityCity} - ${chosenFacility.facilityPostalCode}`
            : "",
          "Facility Ownership": chosenFacility ? chosenFacility.facilityOwnership : "",
          "Facility Type": chosenFacility ? chosenFacility.facilityType.label : "",
          "Facility Owner Approval Obtained": chosenFacility ? chosenFacility.hasLandlordApprovedProject.label : "",
        },
      ],
    };

    //SET UP MEASURES TABLE
    let measureHeadings = [
      { title: "Measure", value: "measure" },
      { title: "Quantity", value: "quantity" },
      {
        title:
          applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment"
            ? "Pre-Approved Incentive ($)"
            : "Estimated Incentive",
        value:
          applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment" ? "preApprovedIncentive" : "estimatedIncentive",
      },
    ];
    if (draftApproved || appSubmitted) {
      measureHeadings.push({ title: "Pre-Approved Incentive", value: "preApprovedIncentive" });
    }
    if (applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment") {
      measureHeadings.push({ title: "Post-Project Applicable Incentive ($)", value: "postProjectApplicableIncentive" });
    }
    if (applicationObj.stage === "Payment") {
      measureHeadings.push({ title: "Recommended Incentive ($)", value: "postProjectApprovedIncentive" });
    }

    let measureRows = measureList.map((measure) => {
      return {
        measure: measure.measure.label,
        quantity: measure.Quantity,
        estimatedIncentive: utils.formatAsCAD(utils.getTEMI(measure)),
        preApprovedIncentive:
          appReviewData && appReviewData.measuresReviewerEstimatedIncentive
            ? utils.formatAsCAD(appReviewData.measuresReviewerEstimatedIncentive[measure.id])
            : "",
        postProjectApplicableIncentive: utils.formatAsCAD(utils.getApplicableTEMI(measure)),
        postProjectApprovedIncentive:
          postProjectAppReviewData && postProjectAppReviewData.measuresPostProjectReviewerApprovedIncentive
            ? utils.formatAsCAD(postProjectAppReviewData.measuresPostProjectReviewerApprovedIncentive[measure.id])
            : "0.00",
        view: <ViewIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewMeasureDetails(measure)} />,
      };
    });

    let measureFooter = (
      <div className="responsive-table-row">
        <div className="responsive-table-details">Total</div>
        <div className="responsive-table-details"></div>
        {applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment" ? (
          <React.Fragment>
            <div className="responsive-table-details">
              <label>Pre-Approved Incentive ($)</label>
              <span>{utils.formatAsCAD(applicationObj["Reviewer Estimated Incentive"] || 0.0)}</span>
            </div>
            <div className="responsive-table-details">
              <label>Post-Project Applicable Incentive ($)</label>
              <span>{utils.formatAsCAD(utils.sumOfApplicableTEMIs(measureList, applicationObj))}</span>
              {parseFloat(utils.sumOfApplicableTEMIs(measureList, applicationObj)) >= maxVal ? (
                <div className="info-icon">
                  <TooltipInfo helpText={"The maximum applicable incentive is capped at 105% of the pre-approved incentive"} />
                </div>
              ) : (
                ""
              )}
            </div>
          </React.Fragment>
        ) : (
          <div className="responsive-table-details">
            <label>Estimated Incentive ($)</label>
            <span>{utils.formatAsCAD(utils.sumOfTEMIs(measureList))}</span>
            {parseFloat(utils.sumOfTEMIs(measureList)) >= 1000000 ? (
              <div className="info-icon">
                <TooltipInfo helpText={"Please note that the project incentive is capped at $1,000,000"} />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        {draftApproved || appSubmitted ? (
          <div className="responsive-table-details">
            <label>Estimated Incentive ($)</label>
            <span>{utils.formatAsCAD(applicationObj["Reviewer Estimated Incentive"] || 0.0)}</span>
          </div>
        ) : (
          ""
        )}
        {applicationObj.stage === "Payment" ? (
          <React.Fragment>
            <div className="responsive-table-details">
              <label>Recommended Incentive ($)</label>
              <span>{utils.formatAsCAD(applicationObj["PostProjectReviewerApprovedIncentive"] || 0.0)}</span>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    );

    //SET UP DOCUMENTS TABLE
    let documentHeadings = [
      { title: "Document Name", value: "docName" },
      { title: "Date of Upload", value: "dateOfUpload" },
      { title: "View", value: "view" },
    ];

    let documentRows = documentList.map((document) => {
      return {
        docName: document.name,
        dateOfUpload: utils.formatAmericanDate(document.createdAt),
        view: <ViewIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewDocument(document)} />,
      };
    });

    return (
      <div className="form-sections application-summary">
        <div className="step-header-container">
          <div className="step-header-container-left">
            <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Application Summary" }}></h2>
          </div>
        </div>
        <div className="form-inner-section">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Facility Details" }}></h2>
          {chosenFacility ? <ResponsiveTable headings={facilityObj.headings} rows={facilityObj.rows} /> : ""}
        </div>
        <div className="horizontal-bar"></div>
        <div className="form-inner-section">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Measures Details" }}></h2>
          {measureList.length > 0 ? (
            <ResponsiveTable headings={measureHeadings} rows={measureRows} customFooter={measureFooter} />
          ) : (
            <div className="empty-summary-container">There are no measures added to this application</div>
          )}
        </div>
        <div className="horizontal-bar"></div>
        <div className="form-inner-section">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Documents Summary" }}></h2>
          {documentList.length ? (
            <ResponsiveTable headings={documentHeadings} rows={documentRows} />
          ) : (
            <div className="empty-summary-container">There are no documents uploaded currently.</div>
          )}
        </div>
        <div className="horizontal-bar"></div>
        {applicationObj && showSummaries ? (
          <div className="form-inner-section">
            <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Project Summary" }}></h2>
            <div className="form-inner-elements">
              <div className="application-form-label">Contractor Name</div>
              <div className="application-form-element">{applicationObj.contractor ? applicationObj.contractor.label : ""}</div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Application Name</div>
              <div className="application-form-element">{applicationObj.projectName || `SPEED-${applicationObj.appNo}`}</div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Estimated Project Start Date</div>
              <div className="application-form-element">{utils.formatAmericanDate(applicationObj.projectStartDate)}</div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Estimated Date of Project Completion</div>
              <div className="application-form-element">{utils.formatAmericanDate(applicationObj.projectCompletionDate)}</div>
            </div>
            {applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment" ? (
              <div className="form-inner-elements">
                <div className="application-form-label">Actual Project Completion Date</div>
                <div className="application-form-element">{utils.formatAmericanDate(applicationObj.actualProjectEndDate)}</div>
              </div>
            ) : (
              ""
            )}
            {draftApproved || appSubmitted ? (
              <div className="form-inner-elements">
                <div className="application-form-label">Project Completion Deadline</div>
                <div className="application-form-element">{applicationObj["Project Completion Deadline"]}</div>
              </div>
            ) : (
              ""
            )}
            <div className="form-inner-elements">
              <div className="application-form-label">
                Is the project receiving other funding, financial incentives, grants or subsidies?
              </div>
              <div className="application-form-element">{applicationObj.isReceivingOtherFunding}</div>
            </div>
            {applicationObj.isReceivingOtherFunding.toLowerCase() === "yes" ? (
              <React.Fragment>
                <div className="form-inner-elements">
                  <div className="application-form-label">Sources of all other funding, financial incentives, grants and subsidies</div>
                  <div className="application-form-element">{applicationObj.sourcesOfAllOtherFunding}</div>
                </div>
                <div className="form-inner-elements">
                  <div className="application-form-label">
                    Total amount of all other funding, financial incentives, grants and subsidies
                  </div>
                  <div className="application-form-element">
                    {utils.formatAsCAD(applicationObj.totalAmountOfAllOtherFunding ? applicationObj.totalAmountOfAllOtherFunding : "0.0")}
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
            <div className="form-inner-elements">
              <div className="application-form-label">Amount of eligible expenses incurred Prior to Application Submission</div>
              <div className="application-form-element">
                {utils.formatAsCAD(applicationObj.amountOfEligibleExpenses ? applicationObj.amountOfEligibleExpenses : "0.0")}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {payeeDetails && applicationObj.payeeAssigned === true && showSummaries ? (
          <React.Fragment>
            <div className="horizontal-bar"></div>
            <div className="form-inner-section">
              <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Payee Summary" }}></h2>
              <div className="summary-list-container">
                <div className="company-details payee-block">
                  <div className="form-inner-elements">
                    <div className="application-form-label">Payee Full Legal Name</div>
                    <div className="application-form-element">{payeeDetails.companyDetails.name}</div>
                  </div>
                  <div className="form-inner-elements">
                    <div className="application-form-label">Payee Address</div>
                    <div className="application-form-element">{`${payeeDetails.companyDetails.address1}, ${
                      payeeDetails.companyDetails.address2 ? payeeDetails.companyDetails.address2 + ", " : ""
                    }${payeeDetails.companyDetails.city}, ${payeeDetails.companyDetails.province} - ${
                      payeeDetails.companyDetails.postalCode
                    }`}</div>
                  </div>
                  <div className="form-inner-elements">
                    <div className="application-form-label">Payee Contact Name</div>
                    <div className="application-form-element">
                      {payeeDetails.firstName} {payeeDetails.lastName}
                    </div>
                  </div>
                  <div className="form-inner-elements">
                    <div className="application-form-label">Phone</div>
                    <div className="application-form-element">{utils.formatPhoneNumber(payeeDetails.phoneNum)}</div>
                  </div>
                </div>
              </div>
              <div className="horizontal-bar"></div>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
        {this.props.activeChangeReqLogs.length ? (
            <div className="form-inner-section">
            <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Change Requests" }}></h2>
            <ChangeReqLogs activeChangeReqLogs={this.props.activeChangeReqLogs} />
            </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  sumIt = (list, key) => {
    let ans = 0.0;
    for (let item of list) {
      ans += parseFloat(item[key]);
    }
    return ans;
  };

  goToAccount = () => {
    this.props.history.push("/account");
  };

  saveAndExit = () => {
    const { slideIndex, slideTotal, applicationObj, userInfo, editing, applicationId } = this.state;
    const newSlideIndex = slideIndex + 1;
    if (!editing && slideIndex === 0) {
      applicationObj.companyName = userInfo.companyDetails.name;
      if (userInfo.accountCategory === "customer") {
        applicationObj.customerId = userInfo.uid;
      }
      applicationService.createApplication(applicationObj).then((app) => {
        this.setState({
          applicationId: app.id,
        });
        this.goToAccount();
      });
    } else {
      if (this.verifyForm(applicationObj)) {
        if (newSlideIndex === slideTotal) {
          this.props.history.push("/applicationforreview");
        } else {
          delete applicationObj.createdAt;
          this.props.updateApplication(applicationId, applicationObj);
          this.goToAccount();
        }
      }
    }
  };

  nextStep = () => {
    const { slideIndex, slideTotal, applicationObj, userInfo, editing, applicationId } = this.state;
    const isContractor = userInfo ? userInfo.accountCategory.toLowerCase() === "contractor" : false;
    const finalStageForContractor = slideIndex > 3 && isContractor;
    if (finalStageForContractor) {
      //TODO: Save the data and trigger email to Customer from Contractor
      //TODO: Trigger Email and take to shared view
      this.props.history.push(`/applicationshared/${applicationId}`);
      return;
    }
    const newSlideIndex = slideIndex + 1;
    if (!editing && slideIndex === 0) {
      applicationObj.companyName = userInfo.companyDetails.name;
      if (userInfo.accountCategory === "customer") {
        applicationObj.customerId = userInfo.uid;
      }
      applicationService.createApplication(applicationObj).then((app) => {
        this.setState({
          applicationId: app.id,
          slideIndex: newSlideIndex,
        });
      });
    } else {
      if (this.verifyForm(applicationObj)) {
        if (newSlideIndex === slideTotal) {
          this.setState({
            submitForReviewModalOpen: true,
          });
        } else {
          delete applicationObj.createdAt;
          this.props.updateApplication(applicationId, applicationObj);
          this.setState({ slideIndex: newSlideIndex });
        }
      }
    }
  };

  toggleSubmitAppModal = (modalOpen) => {
    this.setState({ submitAppModalOpen: modalOpen });
  };

  toggleAddNoteModal = (modalOpen) => {
    let updateObj = {
      addNoteModalOpen: modalOpen,
    };
    if (modalOpen) {
      updateObj.appNoteSuccessfullySubmitted = false;
      updateObj.appNoteSubmitting = false;
      updateObj.appNote = "";
      updateObj.confirmAppNote = false;
    }
    this.setState(updateObj);
  };

  verifyForm = (applicationObj) => {
    let errors = {};
    let errorCount = 0;
    let { slideIndex } = this.state;
    if (slideIndex === 0) {
      if (utils.isNullOrEmpty(applicationObj.contractor.value)) {
        errors.contractor = "Contractor is required.";
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.contractorCanEdit)) {
        errors.contractorCanEdit = "Contractor Access Option is required.";
        errorCount++;
      }
    } else if (slideIndex === 1) {
      if (utils.isNullOrEmpty(applicationObj.projectName)) {
        errors.projectName = "Application (or) Project Name is required.";
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.projectStartDate)) {
        errors.projectStartDate = "Estimated Project Start Date is required.";
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.projectCompletionDate)) {
        errors.projectCompletionDate = "Estimated Project Completion Date is required.";
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.isReceivingOtherFunding)) {
        errors.isReceivingOtherFunding = "Please select an option.";
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.amountOfEligibleExpenses)) {
        errors.amountOfEligibleExpenses = "Please enter amount.";
        errorCount++;
      }
    }
    if (errorCount > 0) {
      this.setState({ errors }, () => {
        let allErrorRefs = document.querySelectorAll(".input-box-error");
        if (allErrorRefs.length > 0) {
          let firstErrorRef = allErrorRefs[0];
          firstErrorRef.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      });
    }
    return errorCount === 0;
  };

  handleAppNoteChange = (name, value) => {
    this.setState({
      appNote: value,
    });
  };
  showAppPaymentPin=()=>{
     
  }
  render() {
    const {
      applicationObj,
      editing,
      dataLoaded,
      submitAppModalOpen,
      addNoteModalOpen,
      confirmingAppNote,
      appNote,
      appNoteSuccessfullySubmitted,
      appNoteSubmitting,
      unauthorisedAccess
    } = this.state;
    if(unauthorisedAccess){
      return <Redirect to="/error404" />;
    }
    const { applicationProcessing} = this.props;
    let draftApproved = applicationObj.status === "Application Draft Approved" && applicationObj.stage === "Pre-Approval";
    let title =
      applicationObj.status === "Application Information Requested"
        ? "Information Requested"
        : applicationObj.status === "Application Draft Approved"
        ? "Pre-Approval Notice"
        : "Application Rejected";
    let message = "";
    if (applicationObj.status === "Application Information Requested") {
      message =
        "Your pre-project application has been reviewed by the SPEED Program team and the following additional clarifications/information regarding your application has been requested:";
    } else if (applicationObj.status === "Application Rejected") {
      message =
        "Your pre-project application has been reviewed by the SPEED Program team and it has been rejected. The reason your application has been rejected is as follows:";
    } else if (applicationObj.status === "Application Draft Approved") {
      message =
        "Your pre-project application has been approved by the Small Producer Energy Efficiency Deployment program. Please review the Application Summary and complete the Participant Acceptance below.";
    }
    let maxVal = 250000.0;
    if (applicationObj && applicationObj["Reviewer Estimated Incentive"]) {
      maxVal = parseFloat(applicationObj["Reviewer Estimated Incentive"]);
      maxVal = parseFloat((maxVal * 1.05).toFixed(2)); // Set to 105% of original
    }
    return (
      <InternalLayout history={this.props.history}>
        <div className="view-application-container">
          <button
            onClick={() => {
              this.props.history.length > 2 ? this.props.history.goBack() : this.props.history.push("/");
            }}
            className="back-button"
          >
            <img src={LeftArrow} alt="arrow" />
            Back
          </button>
          {dataLoaded ? (
            <div className="section-header">
              <h2 className="section-title">{`Application ID: SPEED-${applicationObj.appNo}`}</h2>
            </div>
          ) : (
            ""
          )}
          <Modal HeaderText={"Confirmation"} IsOpen={submitAppModalOpen} handleCloseModal={() => this.toggleSubmitAppModal(false)}>
            <p>You are about to submit the application. Are you sure?</p>
            <div className="button-set">
              <Button
                onClick={() => {
                  this.finallySubmitApplication();
                }}
                className="inversed-btn"
                title={"Yes"}
                uppercase
              />
              <Button title={"No"} uppercase onClick={() => this.toggleSubmitAppModal(false)} />
            </div>
          </Modal>
            <Modal
            HeaderText={"Add Note"}
            IsOpen={addNoteModalOpen}
            loading={appNoteSubmitting}
            handleCloseModal={() => this.toggleAddNoteModal(false)}
          >
            {appNoteSuccessfullySubmitted ? (
              <p>Note submitted successfully!</p>
            ) : (
              <React.Fragment>
                <p>Add a note in response to the information requested.</p>
                <Input disabled={confirmingAppNote} type={"richtext"} value={appNote} onChange={this.handleAppNoteChange} name="appNote" />
                {confirmingAppNote ? (
                  <Button
                    className="signup-btn"
                    title={"Submit Note"}
                    uppercase
                    onClick={this.addNoteToApp}
                    disabled={!utils.cleanHTMLText(appNote)}
                  />
                ) : (
                  <Button
                    className="signup-btn"
                    title={"Confirm Note"}
                    uppercase
                    onClick={this.confirmAppNote}
                    disabled={!utils.cleanHTMLText(appNote)}
                  />
                )}
                {confirmingAppNote ? (
                  <Button className="inversed-btn" title={"EDIT"} uppercase onClick={this.confirmAppNote} />
                ) : (
                  <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.toggleAddNoteModal(false)} />
                )}
              </React.Fragment>
            )}
          </Modal>
          {dataLoaded && !applicationProcessing ? (
            <React.Fragment>
           
              <div className="container-box">{this.applicationSummary()}</div>
            </React.Fragment>
          ) : (
            <Loader />
          )}
        </div>
      </InternalLayout>
    );
  }
}

function mapState(state) {
  const {
    facilityConfig,
    facilityList,
    loadingFacilityConfig,
    loadingActiveContractors,
    activeContractors,
    loadingActiveContractorsError,
    applicationProcessing,
    applicationCreated,
    applicationId,
    measureCategories,
    measureCategoriesLoading,
    measureTypesLoading,
    measureTypes,
    measures,
    measuresLoading,
    measureConfig,
    measureConfigLoading,
    measureList,
    measureCategory,
    measureType,
    measureCode,
    measureDrawerConfig,
    documentList,
    editingMeasureId,
    activeChangeReqLogs
  } = state.application;
  return {
    facilityConfig,
    facilityList,
    loadingFacilityConfig,
    loadingActiveContractors,
    activeContractors,
    loadingActiveContractorsError,
    applicationProcessing,
    applicationCreated,
    applicationId,
    measureCategories,
    measureCategoriesLoading,
    measureTypesLoading,
    measureTypes,
    measures,
    measuresLoading,
    measureConfig,
    measureConfigLoading,
    measureList,
    measureCategory,
    measureType,
    measureCode,
    measureDrawerConfig,
    documentList,
    editingMeasureId,
    activeChangeReqLogs
  };
}

const actionCreators = {
  loadFacilityConfig: appActions.getFacilitiesConfig,
  loadContractors: appActions.getActiveContractors,
  loadUserDetails: userActions.getUserDetails,
  loadConfig: userActions.getAdminConfig,
  loadFacilities: appActions.getFacilitiesForCustomer,
  updateUserDetails: userActions.updateUserDetails,
  createFacility: appActions.createFacility,
  createApplication: appActions.createApplication,
  updateApplication: appActions.updateApplication,
  setMeasureConfig: appActions.setMeasureConfig,
  createAppMeasure: appActions.createAppMeasure,
  updateAppMeasure: appActions.updateAppMeasure,
  loadAppMeasures: appActions.getAppMeasures,
  deleteAppMeasure: appActions.deleteAppMeasure,
  logout: userActions.logout,
  setMeasureCategory: appActions.setMeasureCategory,
  setMeasureType: appActions.setMeasureType,
  setMeasure: appActions.setMeasure,
  clearMeasureDrawer: appActions.clearMeasureDrawer,
  createDocument: appActions.createAppDocument,
  updateDocument: appActions.updateAppDocument,
  deleteDocument: appActions.deleteAppDocument,
  getAppDocuments: appActions.getAppDocuments,
  getChangeReqLogs: appActions.getApplicationsChangeRequestLogs
};

export default connect(mapState, actionCreators)(ViewAppDetails);


{/* <div className="container-box payment-pin">
<div className="form-sections application-summary">
  <div className="step-header-container">
    <div className="step-header-container-left">
      <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Payment PIN" }}></h2>
    </div>
  </div>
  <div className="form-inner-section">
    <div className="info">
      <p> Your application has been Successfully Processed by the SPEED Program team </p>
      <p>Please note this Payment Pin and follow the instructions sent to your mail to complete the payment procedure.</p>
    </div>
    <div className="view-pin">
      <div className="label">View Payment Pin</div>
      <div className="action">
      
        <ViewIcon className="view-eye-icon" width="24" height="15" title="View"  onClick={() => this.setShowAppPaymentPinModalOpen(true)} />

      </div>
    </div>
  </div>
</div>
</div> */}