import React from "react";
import Button from "../../Common/Button/Button";
import Input from "../../Common/Input/Input";
import Modal from "../../Common/Modal/Modal";
import "./Application.css";
import { connect, ReactReduxContext } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { appActions } from "../../../redux/actions/appActions";
import { utils } from "../../../helpers/utils";
import LeftArrow from "../../../images/leftArrow.svg";
import { AuthContext } from "../../../firebaseAuthContext";
import InternalLayout from "../../Common/InternalLayout";
import FormStep from "../../Common/FormStep/FormStep";
import DrawerWithDynamicForm from "../../Common/DrawerWithDynamicForm/DrawerWithDynamicForm";
import TooltipInfo from "../../Common/TooltipInfo/TooltipInfo";
import AlertInfo from "../../Common/AlertInfo/AlertInfo";
import Loader from "../../Common/Loader/Loader";
import CheckBox from "../../Common/CheckBox/CheckBox";
import Accordion from "../../Common/Accordion/Accordion";
import ResponsiveTable from "../../Common/ResponsiveTable/ResponsiveTable";
import { ReactComponent as TrashIcon } from "../../../images/TrashIcon.svg";
import { ReactComponent as EditDocIcon } from "../../../images/EditDocIcon.svg";
import { ReactComponent as UpdateMeasureIcon } from "../../../images/UpdateMeasure.svg";
import { ReactComponent as ViewIcon } from "../../../images/ViewIcon.svg";
import { ReactComponent as SelectedRadio } from "../../../images/SelectedRadio.svg";
import { ReactComponent as UnSelectedRadio } from "../../../images/UnSelectedRadio.svg";
import { Link, Redirect } from "react-router-dom";
import { userService } from "../../../services/userService";
import { applicationService } from "../../../services/applicationService";
import firebase from "../../../helpers/Firebase";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import AlertIcon from "../../../images/AlertIcon.svg";
import SuccessIcon from "../../../images/VerifiedTick.svg";
const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#048041",
    color: "#FFF",
    maxWidth: 200,
  },
  arrow: {
    color: "#048041",
  },
}))(Tooltip);

let postProjectDocumentationInstructions = [
  {
    title: <p>Invoices</p>,
    extraInfo: (
      <div>
        <p>The invoice should include the following:</p>
        <ul>
          <li>Participant name as declared in the application system during pre-project application</li>
          <li>Participant address</li>
          <li>Contractor name</li>
          <li>Invoice date</li>
          <li>Project work timelines</li>
          <li>Model numbers of installed equipment</li>
          <li>For each measure, the costs should be broken down by:</li>
          <ul>
            <li>Equipment and materials</li>
            <li>Labour</li>
            <li>Design and other costs.</li>
          </ul>
          <li>The actual project costs for each measure in the application must match the invoice</li>
          <li>The model number(s) in the invoice should match the specification sheet</li>
        </ul>
      </div>
    ),
  },
  {
    title: <p>Proof of Payment</p>,
    extraInfo: (
      <div>
        <p>
          There needs to be a document provided that confirms payment from participant. The document can be a screenshot as needed. It
          should include:
        </p>
        <ul>
          <li>Signature or “Paid” stamp from the contractor</li>
          <li>Copy of processed cheque(s)</li>
          <li>Online banking account screenshot</li>
          <li>Debit or credit card receipt</li>
          <li>Copy of credit card statement</li>
          <li>Accounting system screenshot</li>
        </ul>
        <p>Please ensure any sensitive information such as card numbers or account numbers are redacted.</p>
      </div>
    ),
  },
  // {
  //   title: <p>Micro-generation Agreement (for Solar PV, CHP or WER)</p>,
  //   extraInfo: (
  //     <div>
  //       <p>
  //         For Solar PV, CHP and WER projects, a signed micro-generation agreement between the utility and the participant with the date of
  //         the agreement and details of the generation system needs to be uploaded.
  //       </p>
  //     </div>
  //   ),
  // },

  //for Solar PV, CHP, WER or GSHP(removed text for Electrical safety permits)
  {
    title: <p>Electrical Safety Permits (As Applicable)</p>,
    extraInfo: (
      <div>
        <p>
          For Solar PV, CHP, WER or GSHP projects, electrical safety permits for the project with the date and project description are
          needed.
        </p>
      </div>
    ),
  },
  {
    title: <p>Post-Project Photo</p>,
    extraInfo: (
      <div>
        <p>
        Please upload a photo of the equipment after the installation of the project
        </p>
      </div>
    ),
  },
  {
    title: <p>On-Site Project Measurement Data</p>,
    extraInfo: (
      <div>
        <p>
        Please upload a document that outlines on-site project measurement data that supports the estimate of energy savings and emissions reduction in the application.
        </p>
      </div>
    ),
  },
  {
    title: <p>Other Documents</p>,
    extraInfo: (
      <div>
        <p>
          There may be other documents that you are required to upload, either specific to the measure or from the pre-approval notice. For
          example, if you are doing a GSHP project, you will need to show confirmation that the system was installed by an Accredited
          Installer by the International Ground Source Heat Pump Association or equivalent.
        </p>
      </div>
    ),
  },
];

let preProjectDocumentationInstructions = [
  {
    title: <p>Cost Quote</p>,
    extraInfo: (
      <div>
        <p>The cost quote should include the following:</p>
        <ul>
          <li>Participant name</li>
          <li>Participant address</li>
          <li>Contractor name</li>
          <li>Date</li>
          <li>Project work timelines</li>
          <li>Model numbers of equipment planned to be installed</li>
          <li>For each measure, the costs should be broken down by:</li>
          <ul>
            <li>Equipment and materials</li>
            <li>Labour</li>
            <li>Design and other costs.</li>
          </ul>
          <li>The project costs for each measure in the application must match the cost quote</li>
          <li>The model number(s) in the cost quote should match the specification sheet</li>
        </ul>
      </div>
    ),
  },
  {
    title: <p>Workplan</p>,
    extraInfo: (
      <div>
        <p>Please upload the completed Workplan template</p>
      </div>
    ),
  },
  {
    title: <p>Pre-Project Photo</p>,
    extraInfo: (
      <div>
        <p>Please upload a photo of the equipment before the installation of the project</p>
      </div>
    ),
  },
  {
    title: <p>Simulation of Performance/ Estimate of Energy Savings and Emissions Reduction</p>,
    extraInfo: (
      <div>
        <p>Please upload a document from simulation software or other sources of information that supports the estimate of energy savings and emissions reduction in the application</p>
      </div>
    ),
  },
  {
    title: <p>Other Documents</p>,
    extraInfo: (
      <div>
        <p>There may be other documents that you are required to upload, that are specific to the measure or otherwise. For example, you might want to upload certain invoices of the costs incurred prior to starting the application or additional specification sheets that haven’t been uploaded while attaching a measure to the application.</p>
      </div>
    ),
  }
];

class Application extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);

    this.state = {
      render404: false,
      currentProgram : 'spp',
      applicationId: props.match.params.id,
      userInfo: null,
      editing: false,
      cancelModalOpen: false,
      removeMeasureModalOpen: false,
      deleteDocumentModalOpen: false,
      submitForReviewModalOpen: false,
      submitPostProjectModalOpen: false,
      slideIndex: 0,
      slideTotal: 6,
      dataLoaded: false,
      formSteps: [],
      errors: {},
      documentsList: [],
      selectedFacility: {},
      selectedMeasure: {},
      openDrawer: false,
      showRequestedInfoModal: false,
      showPreApprovalInfoModal: false,
      contractorMissingMessage: "",
      confirmPayeSubmissionModal: false,
      agreementText: "",
      payeeSubmissionConfirmed: false,
      payee: undefined,
      payeeAssigned : false,
      payeeDetails: undefined,
      applicationObj: {
        program : 'speed',
        stage: "Pre-Project",
        status: "Application Draft",
        facilityId: "",
        contractorId: "",
        contractor: { value: "", label: "Please select a contractor" },
        contractorCanEdit: false,
        projectName: "",
        projectStartDate: "",
        projectCompletionDate: "",
        actualProjectEndDate: "",
        isReceivingOtherFunding: "",
        sourcesOfAllOtherFunding: "",
        totalAmountOfAllOtherFunding: "",
        amountOfEligibleExpenses: "",
        agreeToApplicationTerms: false,
        agreeToPostProjectApplicationTerms: false,
      },
    };
  }
  onAgreementChangeText = (name, value) => {
    let { errors } = this.state;
    delete errors["agreementText"];
    this.setState({
      agreementText: value,
      errors,
    });
  };
  componentDidMount() {
    let formSteps = [
      "Contractor Details",
      "Project Details",
      "Project Facility Summary",
      "Measure and Project Summary",
      "Documents",
      "Application Summary",
    ];
    const appIdToLoad = this.props.match.params.id;
    userService.getUserDetails(firebase.auth.currentUser.uid, (success, userInfo) => {
      this.props.loadConfig();
      this.props.loadContractors();
      this.props.loadFacilityConfig();
      if (appIdToLoad.toLowerCase() !== "new") {
        applicationService.getApplication(appIdToLoad).then(async (data) => {
          let appReviewData = null;
          let postProjectAppReviewData = null;
          try {
            appReviewData = await applicationService.getApplicationReview(appIdToLoad);
          } catch (err) {}
          if(data.stage === 'Post-Project' || data.stage === 'Payment'){
            try {
              postProjectAppReviewData = await applicationService.getPostProjectApplicationReview(appIdToLoad);
            } catch (err) {}
          }
          userService.getUserDetails(data.customerId, (success, customerData) => {
            this.props.loadFacilities(data.customerId);
            this.props.loadAppMeasures(appIdToLoad);
            this.props.getAppDocuments(appIdToLoad);
            for (let key in data) {
              if (key.toLowerCase().includes("time")) {
                delete data[key];
              }
            }
            let applicationObj = { ...data };
            if (applicationObj.stage === "Post-Project") {
              formSteps = [
                "Contractor Details",
                "Project Details",
                "Project Facility Summary",
                "Measure and Project Summary",
                "Documents",
                "Payee Summary",
                "Application Summary",
              ];
            }
            let slideIndex = 0;
            if (userInfo && userInfo.accountCategory === "contractor") {
              slideIndex = 2;
            }
            let applicationValid = ["Application Draft", "Application Information Requested"].includes(applicationObj.status);
            applicationValid = applicationValid  && (firebase.auth.currentUser.uid === data.customerId || firebase.auth.currentUser.uid === data.contractorId);
            let updateObj = {
              render404: !applicationValid,
              customerId: data.customerId,
              customerData,
              userInfo,
              formSteps,
              editing: true,
              applicationObj,
              appReviewData,
              postProjectAppReviewData,
              slideIndex,
              slideTotal: applicationObj.stage === "Post-Project" ? 7 : 6,
              dataLoaded: true
            }
            if (
              applicationObj.payeeAssigned &&
              applicationObj.payeeAssigned === true &&
              applicationObj.payeeInfo.payeeAccountCategory === "contractor"
            ) {
              updateObj.agreementText = "I represent and warrant to Emissions Reduction Alberta that I have authority to bind the Participant";
              updateObj.payeeAssigned = true;
              updateObj.payee = {
                label: applicationObj.payeeInfo.payeeName,
                payeeAccountCategory: applicationObj.payeeInfo.payeeAccountCategory,
                value: applicationObj.payeeInfo.uid,
              };
              userService.getUserDetails(applicationObj.payeeInfo.uid, (success, payeeDetails) => {
                updateObj.payeeDetails = payeeDetails;
                this.setState(updateObj);
              });
            } else if(  applicationObj.payeeAssigned &&
              applicationObj.payeeAssigned === true){
              updateObj.payee = {
                label: applicationObj.payeeInfo.payeeName,
                payeeAccountCategory: applicationObj.payeeInfo.payeeAccountCategory,
                value: applicationObj.payeeInfo.uid,
              };
              updateObj.payeeAssigned = true;
              userService.getUserDetails(applicationObj.payeeInfo.uid, (success, payeeDetails) => {
                updateObj.payeeDetails = payeeDetails;
                this.setState(updateObj);
              });
            } else {
              this.setState(updateObj);
            }
          });
        });
      } else {
        this.props.loadFacilities(userInfo.uid);
        this.props.loadAppMeasures("");
        this.props.getAppDocuments("");
        this.setState({ formSteps, editing: false, userInfo, dataLoaded: true, customerId: firebase.auth.currentUser.uid });
      }
    });
  }

  toggleRequestedInfoModal = (modalOpen) => {
    this.setState({ showRequestedInfoModal: modalOpen });
  };
  togglePreApprovalInfoModal = (modalOpen) => {
    this.setState({ showPreApprovalInfoModal: modalOpen });
  };

  toggleCancelModal = (modalOpen) => {
    this.setState({ cancelModalOpen: modalOpen });
  };
  toggleConfirmPayeeSubmissionModal = (modalOpen,callback) => {
    this.setState({ confirmPayeSubmissionModal: modalOpen },()=>{
      if(callback){
        callback();
      }
    });
  };
  toggleSubmitForReviewModal = (modalOpen) => {
    this.setState({ submitForReviewModalOpen: modalOpen });
  };
  toggleSubmitPostProjectModalOpen = (modalOpen) => {
    this.setState({ submitPostProjectModalOpen: modalOpen });
  };

  toggleRemoveMeasureModal = (modalOpen) => {
    this.setState({ removeMeasureModalOpen: modalOpen });
  };

  toggleDeleteDocumentModal = (modalOpen) => {
    this.setState({ deleteDocumentModalOpen: modalOpen });
  };

  removeMeasureFinally = () => {
    let removalmeasuredata = {};
    const {program} = this.state.applicationObj;
    removalmeasuredata = this.state.measureDataTORemove;
    removalmeasuredata.applicationId = this.state.applicationId;
    this.setState({
      removeMeasureModalOpen: false,
      measureDataTORemove: "",
    });
    this.props.deleteAppMeasure(removalmeasuredata.id, this.state.applicationId, program);
  };

  deleteDocumentFinally = () => {
    this.setState({
      deleteDocumentModalOpen: false,
      documentIdToDelete: "",
    });
    this.props.deleteDocument(this.state.documentIdToDelete, this.state.applicationId);
  };

  deleteDocumentFinally;

  handleChangeSlide = (slideIndex) => {
    if (slideIndex === 2) {
      this.props.loadFacilities(this.state.customerId);
    }
    this.setState({ slideIndex: slideIndex++, errors: {} });
  };

  onContractorChange = (name, contractor) => {
    const { applicationObj, errors } = this.state;
    delete errors["contractor"];
    this.setState({
      applicationObj: {
        ...applicationObj,
        contractor,
        contractorId: contractor.value,
      },
      errors,
    });
  };

  onContractorAccessChange = (name, val) => {
    const { applicationObj, errors } = this.state;
    delete errors["contractorCanEdit"];
    this.setState({
      applicationObj: {
        ...applicationObj,
        contractorCanEdit: val.value,
      },
      errors,
    });
  };
  onPayeeChange = (name,val) => {
    const { applicationObj, errors } = this.state;
    delete errors["payee"];
    delete errors["agreementText"];
    let updateObj = {
      payee: { 
        label: val.label, 
        payeeAccountCategory: val.payeeAccountCategory, 
        value: val.value 
      },
      errors
    };

    if (val.payeeAccountCategory === "contractor") {
      updateObj.agreementText = "";
    }
    userService.getUserDetails(val.value, (success, payeeDetails) => {
      updateObj.payeeDetails = payeeDetails;
      this.setState(updateObj);
    });
  };

  onChangeHandler = (name, value) => {
    const { applicationObj, errors } = this.state;
    delete errors[name];
    if (name === "isReceivingOtherFunding") {
      delete errors.sourcesOfAllOtherFunding;
      delete errors.totalAmountOfAllOtherFunding;
    }
    this.setState(
      {
        applicationObj: {
          ...applicationObj,
          [name]: value,
        },
        errors,
      },
      () => {
        //console.log("app object after payee selec===",applicationObj);
      }
    );
  };

  contractorDetails = () => {
    const { applicationObj, errors } = this.state;
    const { loadingActiveContractors, activeContractors, loadingActiveContractorsError } = this.props;
    let contractors = activeContractors || [];
    return (
      <div className="container-box">
        <div className="form-sections">
          <h2 className="step-header-title">Contractor Details</h2>
          <div className="form-inner-section">
            <div className="form-inner-elements">
              <div className="application-form-label">Who is your contractor?</div>
              <div className="application-form-element">
                <Input
                  type="dropdown"
                  name="contractor"
                  options={contractors
                    .map((contractor) => {
                      return { value: contractor.id, label: contractor["companyDetails.name"] };
                    })
                    .concat([{ value: "", label: "Contractor not on the list" }])}
                  onChange={this.onContractorChange}
                  error={errors["contractor"]}
                  value={applicationObj.contractor}
                />
                <span className="info-icon">
                  <TooltipInfo dynamicPosition={true} helpText={"Your application will be viewable by the contractor."} />
                </span>
              </div>
            </div>
            {applicationObj.contractor && applicationObj.contractor.value ? (
              <div className="form-inner-elements">
                <div className="application-form-label">
                  Do you want the contractor to be given access to help you complete the application?
                </div>
                <div className="application-form-element">
                  <Input
                    type="dropdown"
                    name="contractorCanEdit"
                    onChange={this.onContractorAccessChange}
                    options={[
                      { value: true, label: "Yes, I want to give access" },
                      { value: false, label: "No, I will complete the application myself" },
                    ]}
                    value={
                      applicationObj.contractorCanEdit
                        ? { value: true, label: "Yes, I want to give access" }
                        : { value: false, label: "No, I will complete the application myself" }
                    }
                    error={errors["contractorCanEdit"]}
                  />
                  <span className="info-icon">
                    <TooltipInfo
                      dynamicPosition={true}
                      helpText={
                        'This allows your contractor to edit your application. If you do not want them to edit, please select "No, I will complete the application myself'
                      }
                    />
                  </span>
                </div>
              </div>
            ) : null}

            <div className="form-note-container">
              {applicationObj.contractor && applicationObj.contractor.value ? (
                <React.Fragment>
                  <b>Note:</b> If you choose to give your contractor access to add/edit details of this application, we will inform the
                  contractor on your behalf and provide the contractor access to add/edit certain sections of the application form.
                  <br />
                  You will have to:
                  <br />
                  <ol>
                    <li>Coordinate and fill the application with the help of the contractor.</li>
                    <li>Ensure that the data provided is accurate.</li>
                    <li>Review and submit the application yourself. Your contractor can't submit your application on your behalf.</li>
                  </ol>
                </React.Fragment>
              ) : (
                "If the contractor you wish to work with is not on the list, please contact Application Support to have them registered. Registration is usually completed within 2 business days."
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  projectDetails = () => {
    const { applicationObj, errors } = this.state;
    let programEndDate = applicationObj.stream && applicationObj.stream === "ces" ? new Date(2023, 3, 31, 23, 59, 0, 0) : new Date(2023, 9, 30, 23, 59, 0, 0);
    let maxActualEndDate = new Date() < programEndDate ? new Date() : programEndDate;
    return (
      <div className="container-box">
        <div className="form-sections">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Project Details" }}></h2>
          <div className="form-inner-section">
            <div className="form-inner-elements">
              <div className="application-form-label">Application (or) Project Name</div>
              <div className="application-form-element">
                <Input
                  disabled={applicationObj.stage === "Post-Project"}
                  name="projectName"
                  placeholder={"Type Application Name"}
                  value={applicationObj.projectName}
                  error={errors["projectName"]}
                  onChange={this.onChangeHandler}
                />
                <span className="info-icon">
                  <TooltipInfo dynamicPosition={true} helpText={"What should the application or project be called?"} />
                </span>
              </div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Estimated Project Start Date</div>
              <div className="application-form-element">
                <Input
                  disabled={applicationObj.stage === "Post-Project"}
                  type={"date"}
                  name={"projectStartDate"}
                  minDate={new Date("2022-02-01")}
                  max={maxActualEndDate}
                  value={applicationObj.projectStartDate}
                  error={errors["projectStartDate"]}
                  onChange={this.onChangeHandler}
                />
                <span className="info-icon">
                  <TooltipInfo dynamicPosition={true} helpText={"This can be any date from Nov 2, 2020 to Jan 31, 2022"} />
                </span>
              </div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Estimated Date of Project Completion</div>
              <div className="application-form-element">
                <Input
                  disabled={applicationObj.stage === "Post-Project"}
                  type={"date"}
                  name={"projectCompletionDate"}
                  max={new Date("2023-09-30")}
                  value={applicationObj.projectCompletionDate}
                  error={errors["projectCompletionDate"]}
                  onChange={this.onChangeHandler}
                />
                <span className="info-icon">
                  <TooltipInfo
                    dynamicPosition={true}
                    helpText={
                      "When do you think the project will be completed? This should be within 6 months of the project start date unless it is a CHP, Solar PV or geothermal project"
                    }
                  />
                </span>
              </div>
            </div>

            {applicationObj.stage === "Post-Project" ? (
              <div className="form-inner-elements">
                <div className="application-form-label">Actual Project Completion Date</div>
                <div className="application-form-element">
                  <Input
                    type={"date"}
                    name={"actualProjectEndDate"}
                    value={applicationObj.actualProjectEndDate}
                    error={errors["actualProjectEndDate"]}
                    max={maxActualEndDate}
                    onChange={this.onChangeHandler}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="form-inner-elements">
              <div className="application-form-label">
                Has the Project received or is the Project receiving other funding, financial incentives, grants or subsidies?
              </div>
              <div className="application-form-element">
                <Input
                  type="buttonselect"
                  name="isReceivingOtherFunding"
                  options={["Yes", "No"]}
                  value={applicationObj.isReceivingOtherFunding}
                  error={errors["isReceivingOtherFunding"]}
                  onChange={this.onChangeHandler}
                />
              </div>
            </div>
            {applicationObj.isReceivingOtherFunding.toLowerCase() === "yes" ? (
              <React.Fragment>
                <div className="form-inner-elements">
                  <div className="application-form-label">Sources of all other funding, financial incentives, grants and subsidies</div>
                  <div className="application-form-element">
                    <Input
                      name="sourcesOfAllOtherFunding"
                      placeholder={"Share names of your sources separated by a comma"}
                      type="textarea"
                      value={applicationObj.sourcesOfAllOtherFunding}
                      error={errors["sourcesOfAllOtherFunding"]}
                      onChange={this.onChangeHandler}
                    />
                    <span className="info-icon">
                      <TooltipInfo
                        dynamicPosition={true}
                        helpText={"If the project is receiving other sources of funding, where is the funding coming from?"}
                      />
                    </span>
                  </div>
                </div>
                <div className="form-inner-elements">
                  <div className="application-form-label">
                    Total amount of all other funding, financial incentives, grants and subsidies
                  </div>
                  <div className="application-form-element">
                    <Input
                      name="totalAmountOfAllOtherFunding"
                      placeholder={"XXX,XXX,XXX"}
                      value={applicationObj.totalAmountOfAllOtherFunding}
                      error={errors["totalAmountOfAllOtherFunding"]}
                      onChange={this.onChangeHandler}
                      type={"dollar"}
                    />
                    <span className="info-icon">
                      <TooltipInfo
                        dynamicPosition={true}
                        helpText={"If the project is receiving other sources of funding, what is the amount of funding?"}
                      />
                    </span>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
            <div className="form-inner-elements">
              <div className="application-form-label">Amount of eligible expenses incurred Prior to Application Submission</div>
              <div className="application-form-element">
                <Input
                  name="amountOfEligibleExpenses"
                  disabled={applicationObj.stage === "Post-Project"}
                  placeholder={"XXX,XXX,XXX"}
                  value={applicationObj.amountOfEligibleExpenses}
                  error={errors["amountOfEligibleExpenses"]}
                  onChange={this.onChangeHandler}
                  type={"dollar"}
                />
                <span className="info-icon">
                  <TooltipInfo
                    dynamicPosition={true}
                    helpText={
                      "If the project started between November 2, 2020 and January 31, 2021, please enter Eligible Expenses here. Otherwise, please enter $0"
                    }
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  payeeSummary = () => {
    const { applicationObj, errors, payee, payeeDetails } = this.state;
    let programEndDate = new Date(2022, 5, 30, 23, 59, 0, 0);
    let maxActualEndDate = new Date() < programEndDate ? new Date() : programEndDate;
  
    //console.log("application Object==",);
    return (
      <React.Fragment>
        <div className="container-box">
          <div className="form-sections">
            <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Payee Summary - Consent to Pay" }}></h2>
            <div className="form-inner-section">
              <div className="form-inner-elements">
                <div className="application-form-label">If your application is approved, who will be receiving the incentive?</div>
                <div className="application-form-element">
                  <Input
                    name="payee"
                    type="dropdown"
                    options={[
                      { value: applicationObj.customerId, label: applicationObj.companyName, payeeAccountCategory: "customer" },
                      { value: applicationObj.contractorId , label : applicationObj.contractor.label, payeeAccountCategory: "contractor"}
                    ]}
                    value={payee}
                    error={errors["payee"]}
                    onChange={this.onPayeeChange}
                  />
                </div>
              </div>
              <div className="form-note-container">
                <React.Fragment>
                  <u>
                    <b>Note:</b>
                  </u>
                  <br />
                  <ol>
                    <li>
                      You will not be able to change the Payee assignment once the Post-Project application is approved and an incentive is
                      recommended for the project.
                    </li>
                    <li>
                      Please ensure that Full Legal Name of the Payee as you see in the above dropdown is the exact same as the one listed
                      on the bank account of the Payee. If there is a mismatch, the post-project application might not be approved and/or
                      incentive amount transfer might not be completed.
                    </li>
                    <li>Ensure that the data provided is accurate before proceeding to the next step to submit the application.</li>
                  </ol>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>

        <br />
        {payee && payee.payeeAccountCategory === "contractor" ? (
          <div className="container-box payeblock-aproval">
            <div className="form-sections">
              <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Assign Incentive to Contractor" }}></h2>
              <div className="form-inner-section">
                <p>
                  You have chosen to assign your Contractor <span class="green-text">{applicationObj.contractor.label}</span> as the Payee to receive the incentive amount, if your application is approved by the SPEED Program Team. Please complete the
                  attestation below to proceed to the next step.
                </p>
                <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Attestation" }}></h2>
                <p>
                  By my attestation below, the Participant directs and authorizes ERA to pay the Project Incentive directly to the Eligible
                  Contractor named below. The Participant confirms that the Eligible Contractor will receive the Project Incentive payable
                  to the Participant under the Participant Acceptance, and that the Participant will not receive the Project Incentive.
                </p>
                <div className="summary-list-container">
                  {payeeDetails ? (
                    <div className="company-details">
                      <div className="form-inner-elements">
                        <div className="application-form-label">Contractor Full Legal Name</div>
                        <div className="application-form-element">{payeeDetails.companyDetails.name}</div>
                      </div>
                      <div className="form-inner-elements">
                        <div className="application-form-label">Contractor Address</div>
                        <div className="application-form-element">{`${payeeDetails.companyDetails.address1}, ${
                          payeeDetails.companyDetails.address2 ? payeeDetails.companyDetails.address2 + ", " : ""
                        }${payeeDetails.companyDetails.city}, ${payeeDetails.companyDetails.province} - ${
                          payeeDetails.companyDetails.postalCode
                        }`}</div>
                      </div>
                      <div className="form-inner-elements">
                        <div className="application-form-label">Contractor Contact Name</div>
                        <div className="application-form-element">
                          {payeeDetails.firstName} {payeeDetails.lastName}
                        </div>
                      </div>
                      <div className="form-inner-elements">
                        <div className="application-form-label">Phone</div>
                        <div className="application-form-element">{utils.formatPhoneNumber(payeeDetails.phoneNum)}</div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="agreement-text-container">
                    <p>
                      Type the following sentence in the text box below '<span>I represent and warrant to Emissions Reduction Alberta that I have
                      authority to bind the Participant</span>' to mark the attestation.
                    </p>
                    <Input
                      className="agreement-text-area"
                      name="termsOfAgree"
                      disablePaste={true}
                      type="text"
                      placeholder="I represent and warrant to Emissions Reduction Alberta that I have authority to bind the Participant"
                      rows="1"
                      cols="50"
                      error={this.state.errors["agreementText"]}
                      onChange={this.onAgreementChangeText}
                      value={this.state.agreementText}
                    />
                  </div>
                  <div className="form-declaration-container">
                    <div className="form-declaration-left">
                      <span>{`${applicationObj.customerInfo.firstName} ${applicationObj.customerInfo.lastName}`}</span>
                    </div>
                    <div className="form-declaration-right">
                      <span>{utils.getDate()}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  };

  facilitySummary = () => {
    const { openDrawer, selectedFacility, applicationObj } = this.state;
    const { loadingFacilityConfig, facilityConfig, facilityList } = this.props;
    let chosenFacility = null;
    facilityList.forEach((f) => {
      if (f.id === applicationObj.facilityId) {
        chosenFacility = f;
      }
    });
    if (loadingFacilityConfig) {
      return <Loader />;
    }

    function extractAppIds(linkedApplications) {
      return Object.keys(linkedApplications).map((key) => {
        return `SPEED-${linkedApplications[key].appNo}`;
      });
    }

    const dynamicForm = {
      title: "ADD A FACILITY",
      actionButton: {
        title: "ADD A FACILITY",
      },
      fields: facilityConfig.fields,
    };

    let facilityHeadings = [
      { title : 'Attach to Application', value : 'attachToApp'},
      { title : 'Facility Name', value : 'facilityName'},
      { title : 'Applications Currently Attached to Facility', value : 'currentlyAttached'},
      { title : 'Address of Facility', value : 'facilityAddress'}
    ];

    let facilityRows = facilityList.map((facility)=>{
      return {
        attachToApp : <span onClick={() => this.selectFacility(facility.id)}>
        {applicationObj.facilityId === facility.id ? (
          <SelectedRadio width="20" height="20" />
        ) : (
          <UnSelectedRadio width="20" height="20" />
        )}
      </span>,
      facilityName : facility.facilityName,
      currentlyAttached : utils.isNullOrEmpty(facility.linkedApplications) ? [] : extractAppIds(facility.linkedApplications).join(","),
      facilityAddress : (`${facility.facilityAddressLine1 && facility.facilityAddressLine1.label} ${facility.facilityCity}`)
      }
    })

    return (
      <div className="container-box">
        <div className="form-sections facility-summary">
          <DrawerWithDynamicForm
            toggleDrawer={this.toggleDrawer}
            openDrawer={openDrawer}
            className={"admin-panel-drawer"}
            formJSON={dynamicForm}
            disableClose={true}
            submitForm={this.handleFacilityData}
          />
          <div className="step-header-container">
            <div className="step-header-container-left">
              <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Project Facility Summary" }}></h2>
            </div>
            {applicationObj.stage !== "Post-Project" ? (
              <div className="step-header-container-right">
                <Button title={"ADD FACILITY"} className="facility-btn" uppercase onClick={this.addFacility} />
                <span className="info-icon">
                  <TooltipInfo dynamicPosition={true} helpText={"This allows you to add facility to your application."} />
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="form-inner-section">
            <React.Fragment>
              {applicationObj.stage !== "Post-Project" ? (
                <React.Fragment>
                  {facilityList.length ? (
                    <ResponsiveTable rows={facilityRows} headings={facilityHeadings}/>
                  ) : (
                    <div className="empty-summary-container">
                      There are no facilities added currently. Add a facility above to attach it to this application and save it for future
                      applications.
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="summary-container">
                    <div className="summary-items-row summary-items-header">
                      <div className="summary-item-details">Facility Name</div>
                      <div className="summary-item-details">Region</div>
                      <div className="summary-item-details">Facility Address</div>
                      <div className="summary-item-details">Facility Ownership</div>
                      <div className="summary-item-details">Facility Type</div>
                      <div className="summary-item-details">Facility Owner Approval Obtained</div>
                    </div>
                    {chosenFacility ? (
                      <div className="summary-items-row">
                        <div className="summary-item-details"><label>Facility Name</label><span>{chosenFacility ? chosenFacility.facilityName : ""}</span></div>
                        <div className="summary-item-details"><label>Region</label><span>{chosenFacility ? chosenFacility.facilityApplicableRegion.value : ""}</span></div>
                        <div className="summary-item-details">
                        <label>Facility Address</label>
                        <span>
                          {chosenFacility
                            ? `${chosenFacility.facilityAddressLine1.label}, ${
                                chosenFacility.facilityAddressLine2 ? ", " + chosenFacility.facilityAddressLine2 : ""
                              } ${chosenFacility.facilityCity} - ${chosenFacility.facilityPostalCode}`
                            : ""}
                        </span>
                        </div>
                        <div className="summary-item-details"><label>Facility Ownership</label><span>{chosenFacility ? chosenFacility.facilityOwnership : ""}</span></div>
                        <div className="summary-item-details"><label>Facility Type</label><span>{chosenFacility ? chosenFacility.facilityType.label : ""}</span></div>
                        <div className="summary-item-details"><label>Facility Owner Approval Obtained</label><span>{chosenFacility ? chosenFacility.hasLandlordApprovedProject.value : ""}</span></div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </div>
        </div>
      </div>
    );
  };

  addFacility = () => {
    this.toggleDrawer(true);
  };

  selectFacility = (facilityId) => {
    let facilityList = this.props.facilityList;
    let fIndex = facilityList.findIndex((f) => f.facilityId === facilityId);
    let selectedFacility = fIndex > -1 ? facilityList[fIndex] : {};
    this.setState({
      selectedFacility,
      applicationObj: {
        ...this.state.applicationObj,
        facilityId,
      },
    });
  };

  // deleteFacility = (facilityName) => {
  //   let facilityList = this.state.facilityList;
  //   let fIndex = facilityList.findIndex(f => f.facilityName === facilityName)
  //   this.setState({
  //     facilityList: facilityList.splice(fIndex, 1)
  //   })
  // }

  handleFacilityData = (facilityData) => {
    facilityData.customerId = this.state.customerId;
    this.props.createFacility(facilityData);
  };

  toggleDrawer = (status) => {
    this.setState({
      openDrawer: status,
    });
  };

  handleDocumentUpload = (name, fileObj) => {
    fileObj.applicationId = this.state.applicationId;
    fileObj.appStage = this.state.applicationObj.stage;
    let newObj = { ...fileObj };
    this.props.createDocument(newObj);
  };

  documentDetails = () => {
    let { documentList } = this.props;
    let preProjectDocumentList = [];
    let postProjectDocumentList = [];
    let { editingDocId, editedDocument, applicationObj } = this.state;
    // console.log("existing documents lisrr==",documentList);
    preProjectDocumentList = documentList.filter((obj) => {
      return !obj.appStage || (obj.appStage && obj.appStage === "Pre-Project") && !obj.isInfected;
    });
    let newDocumentList = documentList && documentList.length && documentList.filter((obj)=> obj.isInfected === false);
    if (!applicationObj.stage || (applicationObj.stage && applicationObj.stage === "Post-Project")) {
      preProjectDocumentList = documentList.filter((obj) => {
        return !obj.appStage || (obj.appStage && obj.appStage === "Pre-Project");
      });
      postProjectDocumentList = documentList.filter((obj) => {
        return obj.appStage === "Post-Project";
      });
      //  console.log("existing preprojects lisrr==",preProjectDocumentList);
      // console.log("existing preprojects lisrr==",postProjectDocumentList);
    }

    return (
      <div className="container-box">
        <div className="form-inner-section">
          <div className="form-sections documents-section">
            <h2 className="step-header-title">Documents</h2>
            <div className="form-inner-section documents-section">
              <Input
                type={"file"}
                name={"documents"}
                maxFiles={5}
                placeholder={
                  <p className="file-upload-prompt">
                    Select from computer (or) Drag and Drop{" "}
                    {applicationObj.stage === "Post-Project" ? (
                      "Files "
                    ) : (
                      <React.Fragment>
                        <span>Invoices</span>, <span>Quotes</span> and <span>Specification Sheets</span> as required{" "}
                      </React.Fragment>
                    )}
                    to upload. Acceptable file types: .JPEG, .PNG, .PDF, .docx, .xlsx
                  </p>
                }
                onChange={this.handleDocumentUpload}
              />
              <h2 className="step-header-title">Documentation Instructions</h2>
              <p>
                Please review the instructions below before uploading files to ensure all the required documents are attached to the
                application:
              </p>
              <Accordion items={applicationObj.stage === "Post-Project" ? postProjectDocumentationInstructions : preProjectDocumentationInstructions} />
              {applicationObj.stage === "Post-Project" ? (
                <React.Fragment>
                  <div className="horizontal-bar"></div>
                  {postProjectDocumentList.length ? (
                    <React.Fragment>
                    <h2 className="step-header-title">Upload Summary</h2>
                    <div className="summary-container">
                      <div className="summary-items-row summary-items-header">
                        <div className="summary-item-details">Document Name</div>
                        <div className="summary-item-details">Date of Upload</div>
                        <div className="summary-item-details"></div>
                      </div>
                      {postProjectDocumentList.map((document) => (
                        <div className="summary-items-row">
                          <div className="summary-item-details">
                          <label>Name</label>
                            {editingDocId === document.id ? (
                              <React.Fragment>
                                <input
                                  name={editingDocId}
                                  value={
                                    editedDocument && editedDocument.id
                                      ? editedDocument.name
                                      : document.name
                                      ? document.name.split(".")[0]
                                      : null
                                  }
                                  className={`document-name-input`}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    this.onDocumentNameChange(name, value);
                                  }}
                                />
                                <img alt="success icon" src={SuccessIcon} className="save-icon" onClick={this.saveDocument} />
                              </React.Fragment>
                            ) : (
                              <span>{document.name}</span>
                            )}
                          </div>
                          <div className="summary-item-details"><label>Date of Upload</label><span>{utils.formatAmericanDate(document.createdAt)}</span></div>
                          <div className="summary-item-details">
                            <label>Actions</label>
                            <div className="summary-button-set">
                            <div className="summary-action-btn" onClick={() => this.viewDocument(document)}>
                              <ViewIcon width="24" height="15" />
                              <span>View</span>
                            </div>
                            <div className="summary-action-btn" onClick={() => this.editDocument(document.id)}>
                              <EditDocIcon width="15" height="15" />
                              <span>Edit Name</span>
                            </div>
                            <div
                              className="summary-action-btn"
                              onClick={() => {
                                this.deleteDocument(document.id);
                              }}
                            >
                              <TrashIcon width="15" height="15" />
                              <span>Delete</span>
                            </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div></React.Fragment>
                  ) : ''}
                  {preProjectDocumentList.length ? (
                    <React.Fragment>
                    <h2 className="step-header-title">Pre-Project Documents</h2>
                    <div className="summary-container">
                      <div className="summary-items-row summary-items-header">
                        <div className="summary-item-details">Document Name</div>
                        <div className="summary-item-details">Date of Upload</div>
                        <div className="summary-item-details"></div>
                      </div>
                      {preProjectDocumentList.map((document) => (
                        <div className="summary-items-row">
                          <div className="summary-item-details">
                            <label>Name</label>
                            {editingDocId === document.id ? (
                              <React.Fragment>
                                <input
                                  name={editingDocId}
                                  value={
                                    editedDocument && editedDocument.name
                                      ? editedDocument.name
                                      : document.name
                                      ? document.name.split(".")[0]
                                      : null
                                  }
                                  className={`document-name-input`}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    this.onDocumentNameChange(name, value);
                                  }}
                                />
                                <img alt="success icon" src={SuccessIcon} className="save-icon" onClick={this.saveDocument} />
                              </React.Fragment>
                            ) : (
                              <span>{document.name}</span>
                            )}
                          </div>
                          <div className="summary-item-details"><label>Date of Upload</label><span>{utils.formatAmericanDate(document.createdAt)}</span></div>
                          <div className="summary-item-details">
                          <label>Actions</label>
                          <div className="summary-button-set">
                            <div className="summary-action-btn" onClick={() => this.viewDocument(document)}>
                              <ViewIcon width="24" height="15" />
                              <span>View</span>
                            </div>
                            <div className="summary-action-btn" onClick={() => this.editDocument(document.id)}>
                              <EditDocIcon width="15" height="15" />
                              <span>Edit Name</span>
                            </div>
                            {document.appStage && applicationObj.stage === document.appStage ? (
                              <div
                                className="summary-action-btn"
                                onClick={() => {
                                  this.deleteDocument(document.id);
                                }}
                              >
                                <TrashIcon width="15" height="15" />
                                <span>Delete</span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        </div>
                      ))}
                    </div></React.Fragment>
                  ) : ''}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {newDocumentList.length ? <React.Fragment>
                    <h2 className="step-header-title">Upload Summary</h2>
                    <div className="summary-container">
                      <div className="summary-items-row summary-items-header">
                        <div className="summary-item-details">Document Name</div>
                        <div className="summary-item-details">Date of Upload</div>
                        <div className="summary-item-details"></div>
                      </div>
                      {newDocumentList.map((document) => (
                        <div className="summary-items-row">
                          <div className="summary-item-details">
                          <label>Name</label>
                            {editingDocId === document.id ? (
                              <React.Fragment>
                                <input
                                  name={editingDocId}
                                  value={
                                    editedDocument && editedDocument.id
                                      ? editedDocument.name
                                      : document.name
                                      ? document.name.split(".")[0]
                                      : null
                                  }
                                  className={`document-name-input`}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    this.onDocumentNameChange(name, value);
                                  }}
                                />
                                <img alt="success icon" src={SuccessIcon} className="save-icon" onClick={this.saveDocument} />
                              </React.Fragment>
                            ) : (
                              <span>{document.name}</span>
                            )}
                          </div>
                          <div className="summary-item-details"><label>Date of Upload</label><span>{utils.formatAmericanDate(document.createdAt)}</span></div>
                          <div className="summary-item-details">
                          <label>Actions</label>
                          <div className="summary-button-set">
                            <div className="summary-action-btn" onClick={() => this.viewDocument(document)}>
                              <ViewIcon width="24" height="15" />
                              <span>View</span>
                            </div>
                            <div className="summary-action-btn" onClick={() => this.editDocument(document.id)}>
                              <EditDocIcon width="15" height="15" />
                              <span>Edit Name</span>
                            </div>

                            <div
                              className="summary-action-btn"
                              onClick={() => {
                                this.deleteDocument(document.id);
                              }}
                            >
                              <TrashIcon width="15" height="15" />
                              <span>Delete</span>
                            </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div></React.Fragment>
                   : ''}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // getDocuments = (name, documents) => {
  //   let { documentsList } = this.state;
  //   documentsList = documentsList.concat(documents)
  //   this.setState({
  //     documentsList
  //   })
  // }

  editDocument = (documentId) => {
    this.setState({
      editingDocId: documentId,
    });
  };

  saveDocument = () => {
    let { editedDocument } = this.state;
    this.props.updateDocument(editedDocument.id, { name: editedDocument.name, applicationId: this.state.applicationId });
    this.setState({ editedDocument: {}, editingDocId: null });
  };

  onDocumentNameChange = (id, newName) => {
    this.setState({
      editedDocument: {
        id,
        name: newName,
      },
    });
  };

  viewDocument = (document) => {
    window.open(document.url);
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  handleAgreeToTerms = (name, value) => {
    const { applicationObj } = this.state;
    this.setState({
      applicationObj: {
        ...applicationObj,
        agreeToApplicationTerms: value,
      },
    });
  };
  handleAgreeToPostProjectTerms = (name, value) => {
    const { applicationObj } = this.state;
    this.setState({
      applicationObj: {
        ...applicationObj,
        agreeToPostProjectApplicationTerms: value,
      },
    });
  };
  applicationSummary = () => {
    const { applicationObj, appReviewData, payeeDetails,payeeAssigned,postProjectAppReviewData } = this.state;
    const { measureList, documentList, facilityList } = this.props;
    let chosenFacility = null;
    let draftApproved = applicationObj.status === "Application Draft Approved" && applicationObj.stage === "Pre-Approval";
    let appSubmitted = applicationObj.status === "Application Submitted" && applicationObj.stage === "Pre-Approval";
    facilityList.forEach((f) => {
      if (f.id === applicationObj.facilityId) {
        chosenFacility = f;
      }
    });
    let maxVal = 1000000.0;
    if (applicationObj && applicationObj["Reviewer Estimated Incentive"]) {
      maxVal = parseFloat(applicationObj["Reviewer Estimated Incentive"]);
      maxVal = parseFloat((maxVal * 1.05).toFixed(2)); // Set to 105% of original
    }

        //SET UP FACILITY TABLE
        let facilityObj = {
          headings : [
            { title : 'Facility Name', value : 'Facility Name'},
            { title : 'Region', value : 'Region'},
            { title : 'Facility Address', value : 'Facility Address'},
            { title : 'Facility Ownership', value : 'Facility Ownership'},
            { title : 'Facility Type', value : 'Facility Type'},
            { title : 'Facility Owner Approval Obtained', value : 'Facility Owner Approval Obtained'}
          ],
          rows : [
            {
              'Facility Name': (chosenFacility ? chosenFacility.facilityName : ""),
              'Region':(chosenFacility ? chosenFacility.facilityApplicableRegion.value : ""),
              'Facility Address':(chosenFacility
                ? `${chosenFacility.facilityAddressLine1.label}, ${
                    chosenFacility.facilityAddressLine2 ? ", " + chosenFacility.facilityAddressLine2 : ""
                  } ${chosenFacility.facilityCity} - ${chosenFacility.facilityPostalCode}`
                : ""),
              'Facility Ownership':(chosenFacility ? chosenFacility.facilityOwnership : ""),
              'Facility Type':(chosenFacility ? chosenFacility.facilityType.label : ""),
              'Facility Owner Approval Obtained':(chosenFacility ? chosenFacility.hasLandlordApprovedProject.label : "")
            }
          ]
        }
    
        //SET UP MEASURES TABLE
        let measureHeadings = [
          { title : 'Measure', value : 'measure'},
          { title : 'Quantity', value : 'quantity'},
          // { title : 'Estimated Emissions Reduction (Lifetime)', value : 'estimatedEmissionsReduction'},
          { title : ((applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment") ? "Pre-Approved Incentive ($)" : "Estimated Incentive" ), value : (applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment") ? "preApprovedIncentive" : "estimatedIncentive"}
        ];
        if(draftApproved || appSubmitted){
          measureHeadings.push({ title : 'Pre-Approved Incentive', value :'preApprovedIncentive'});
        }
        if(applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment" ){
          measureHeadings.push({ title : 'Post-Project Applicable Incentive ($)', value :'postProjectApplicableIncentive'});
        }
        if(applicationObj.stage === "Payment" ){
          measureHeadings.push({ title : 'Post-Project Approved Incentive ($)', value :'postProjectApprovedIncentive'});
        }
  
        let measureRows = measureList.map((measure) => {
          return { 
            measure : measure.measure.label,
            quantity : measure.Quantity,
            // estimatedEmissionsReduction : `${utils.formatNumberWithCommas(utils.getTEM(measure))} tonnes`,
            estimatedIncentive : utils.formatAsCAD(utils.getTEMI(measure)),
            preApprovedIncentive : ((appReviewData && appReviewData.measuresReviewerEstimatedIncentive) ? utils.formatAsCAD(appReviewData.measuresReviewerEstimatedIncentive[measure.id]):""),
            postProjectApplicableIncentive : utils.formatAsCAD(utils.getApplicableTEMI(measure)),
            postProjectApprovedIncentive : ((postProjectAppReviewData && postProjectAppReviewData.measuresPostProjectReviewerApprovedIncentive)?utils.formatAsCAD(postProjectAppReviewData.measuresPostProjectReviewerApprovedIncentive[measure.id]):'0.00'),
            view : <ViewIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewMeasureDetails(measure)}/>
          }
        });
    
      let measureFooter =  <div className="responsive-table-row">
      <div className="responsive-table-details">Total</div>
      <div className="responsive-table-details"></div>
      {/* <div className="responsive-table-details"><label>Estimated Emissions Reduction (Lifetime)</label><span>{utils.formatNumberWithCommas(utils.sumOfTEMs(measureList))} tonnes</span></div> */}
      {(applicationObj.stage === "Post-Project" || applicationObj.stage === 'Payment') ? (
        <React.Fragment>
          <div className="responsive-table-details"><label>Pre-Approved Incentive ($)</label><span>{utils.formatAsCAD(applicationObj["Reviewer Estimated Incentive"] || 0.0)}</span></div>
          <div className="responsive-table-details">
          <label>Post-Project Applicable Incentive ($)</label>
            <span>{utils.formatAsCAD(utils.sumOfApplicableTEMIs(measureList, applicationObj))}</span>
            {parseFloat(utils.sumOfApplicableTEMIs(measureList, applicationObj)) >= maxVal ? (
              <div className="info-icon">
                <TooltipInfo dynamicPosition={true} helpText={"The maximum applicable incentive is capped at 105% of the pre-approved incentive"} />
              </div>
            ) : (
              ""
            )}
          </div>
        </React.Fragment>
      ) : (
        <div className="responsive-table-details">
          <label>Estimated Incentive ($)</label>
          <span>{utils.formatAsCAD(utils.sumOfTEMIs(measureList))}</span>
          {parseFloat(utils.sumOfTEMIs(measureList)) >= 1000000 ? (
            <div className="info-icon">
              <TooltipInfo dynamicPosition={true} helpText={"Please note that the project incentive is capped at $1,000,000"} />
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      {draftApproved || appSubmitted ? (<div className="responsive-table-details">
        <label>Estimated Incentive ($)</label><span>{utils.formatAsCAD(applicationObj["Reviewer Estimated Incentive"] || 0.0)}</span>
        </div>
      ) : (
        ""
      )}
    </div>;
    
        //SET UP DOCUMENTS TABLE
        let documentHeadings = [
          { title : 'Document Name', value : 'docName'},
          { title : 'Date of Upload', value : 'dateOfUpload'},
          { title : 'View', value : 'view'}
        ];
    
        let documentRows = documentList.map((document) => {
          return {
            docName : document.name,
            dateOfUpload : utils.formatAmericanDate(document.createdAt),
            view : <ViewIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewDocument(document)}/>
          }
        });

    return (
      <div className="container-box">
        <div className="form-inner-section">
          <div className="form-sections application-summary">
            <div className="step-header-container">
              <div className="step-header-container-left">
                <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Application Summary" }}></h2>
              </div>
            </div>
            <div className="form-inner-section">
              <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Facility Details" }}></h2>
              {chosenFacility ? <ResponsiveTable headings={facilityObj.headings} rows={facilityObj.rows} /> : ""}
            </div>
            <div className="horizontal-bar"></div>
            <div className="form-inner-section">
              <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Measures Details" }}></h2>
              {measureList.length > 0 ? (
                <ResponsiveTable headings={measureHeadings} rows={measureRows} customFooter={measureFooter} />
              ) : (
                <div className="empty-summary-container">There are no measures added to this application</div>
              )}
            </div>
            <div className="horizontal-bar"></div>
            <div className="form-inner-section">
              <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Documents Summary" }}></h2>
              {documentList.length ? (
                <ResponsiveTable headings={documentHeadings} rows={documentRows} />
              ) : (
                <div className="empty-summary-container">There are no documents uploaded currently.</div>
              )}
            </div>
            <div className="horizontal-bar"></div>
            {applicationObj ? (
              <div className="form-inner-section">
                <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Project Summary" }}></h2>
                <div className="form-inner-elements">
                  <div className="application-form-label">Contractor Name</div>
                  <div className="application-form-element">{applicationObj.contractor ? applicationObj.contractor.label : ""}</div>
                </div>
                <div className="form-inner-elements">
                  <div className="application-form-label">Application Name</div>
                  <div className="application-form-element">{applicationObj.projectName || `SPEED-${applicationObj.appNo}`}</div>
                </div>
                <div className="form-inner-elements">
                  <div className="application-form-label">Estimated Project Start Date</div>
                  <div className="application-form-element">{utils.formatAmericanDate(applicationObj.projectStartDate)}</div>
                </div>
                <div className="form-inner-elements">
                  <div className="application-form-label">Estimated Date of Project Completion</div>
                  <div className="application-form-element">{utils.formatAmericanDate(applicationObj.projectCompletionDate)}</div>
                </div>
                {applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment" ? (
                  <div className="form-inner-elements">
                    <div className="application-form-label">Actual Project Completion Date</div>
                    <div className="application-form-element">{utils.formatAmericanDate(applicationObj.actualProjectEndDate)}</div>
                  </div>
                ) : (
                  ""
                )}
                <div className="form-inner-elements">
                  <div className="application-form-label">
                    Is the project receiving other funding, financial incentives, grants or subsidies?
                  </div>
                  <div className="application-form-element">{applicationObj.isReceivingOtherFunding}</div>
                </div>
                {applicationObj.isReceivingOtherFunding.toLowerCase() === "yes" ? (
                  <React.Fragment>
                    <div className="form-inner-elements">
                      <div className="application-form-label">Sources of all other funding, financial incentives, grants and subsidies</div>
                      <div className="application-form-element">{applicationObj.sourcesOfAllOtherFunding}</div>
                    </div>
                    <div className="form-inner-elements">
                      <div className="application-form-label">
                        Total amount of all other funding, financial incentives, grants and subsidies
                      </div>
                      <div className="application-form-element">
                        {utils.formatAsCAD(
                          applicationObj.totalAmountOfAllOtherFunding ? applicationObj.totalAmountOfAllOtherFunding : "0.0"
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}
                <div className="form-inner-elements">
                  <div className="application-form-label">Amount of eligible expenses incurred Prior to Application Submission</div>
                  <div className="application-form-element">
                    {utils.formatAsCAD(applicationObj.amountOfEligibleExpenses ? applicationObj.amountOfEligibleExpenses : "0.0")}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {payeeDetails && payeeAssigned ? (
              <React.Fragment>
                <div className="horizontal-bar"></div>
                <div className="form-inner-section">
                  <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Payee Summary" }}></h2>
                  <div className="form-inner-elements">
                    <div className="application-form-label">Payee Full Legal Name</div>
                    <div className="application-form-element">{payeeDetails.companyDetails.name}</div>
                  </div>
                  <div className="form-inner-elements">
                    <div className="application-form-label">Payee Address</div>
                    <div className="application-form-element">{`${payeeDetails.companyDetails.address1}, ${
                      payeeDetails.companyDetails.address2 ? payeeDetails.companyDetails.address2 + ", " : ""
                    }${payeeDetails.companyDetails.city}, ${payeeDetails.companyDetails.province} - ${
                      payeeDetails.companyDetails.postalCode
                    }`}</div>
                  </div>
                  <div className="form-inner-elements">
                    <div className="application-form-label">Payee Contact Name</div>
                    <div className="application-form-element">
                      {payeeDetails.firstName} {payeeDetails.lastName}
                    </div>
                  </div>
                  <div className="form-inner-elements">
                    <div className="application-form-label">Phone</div>
                    <div className="application-form-element">{utils.formatPhoneNumber(payeeDetails.phoneNum)}</div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
            <div className="horizontal-bar"></div>
            {applicationObj.stage !== "Post-Project" ? (
              <div className="form-inner-section">
                <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Requirements for Submission" }}></h2>

                <div className="summary-list-container">
                  <div className="list-heading">The Applicant represents and warrants as follows:</div>
                  <ol>
                    <li>All information set out in this Application is complete, true and accurate. </li>
                    <li>
                      The Applicant meets the eligibility requirements set out in the{" "}
                      <span>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://firebasestorage.googleapis.com/v0/b/dolphin-live-a906a.appspot.com/o/SPEED%20TermsAndConditions.pdf?alt=media&token=bf02d654-d692-4b34-936f-d64334a86aea"
                        >
                          Terms and Conditions
                        </a>
                      </span>{" "}
                      to be a Participant.
                    </li>
                    <li>
                      The facility meets the requirements set out in the{" "}
                      <span>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://firebasestorage.googleapis.com/v0/b/dolphin-live-a906a.appspot.com/o/SPEED%20TermsAndConditions.pdf?alt=media&token=bf02d654-d692-4b34-936f-d64334a86aea"
                        >
                          Terms and Conditions
                        </a>
                      </span>{" "}
                      to be an Eligible Facility.{" "}
                    </li>
                    <li>
                      The Measures meet the requirements set out in the{" "}
                      <span>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://firebasestorage.googleapis.com/v0/b/dolphin-live-a906a.appspot.com/o/SPEED%20TermsAndConditions.pdf?alt=media&token=bf02d654-d692-4b34-936f-d64334a86aea"
                        >
                          Terms and Conditions
                        </a>
                      </span>{" "}
                      to be Eligible Measures.{" "}
                    </li>
                    <li>
                      Subject to Section 11 of the Terms and Conditions, the Participant may receive financial incentives, funding,
                      subsidies, or grants or other monies other than as a result (directly or indirectly) of the TIER Regulation, the
                      repealed Climate Leadership Act, or the Pan-Canadian Framework programs, provided the amounts and sources are set out
                      in the Application Summary.
                    </li>
                  </ol>
                </div>
                <div className="summary-list-container">
                  <div className="list-heading">The Applicant agrees and acknowledges as follows:</div>
                  <ol>
                    <li>
                      This Application creates no legally binding obligations whatsoever on the part of ERA. Without limiting the foregoing,
                      the Applicant agrees (i) that no “Contract ‘A’” is created, and (ii) that ERA is in no way obligated to provide any
                      funding or other benefit whatsoever to the Applicant as a result of submitting this Application. Project Incentives
                      may be payable only pursuant to a legally binding Participant Acceptance and subject to acceptance of this
                      Application.{" "}
                    </li>
                    <li>All costs to prepare and submit this Application are the sole responsibility of the Applicant. </li>
                    <li>
                      ERA may verify with any Applicant or with any third party any information set out in this Application, and may share
                      information with such third party in order to do so.{" "}
                    </li>
                    <li>
                      ERA may accept or refuse this Application for any reason and at its sole discretion, even where the Applicant meets
                      all Program requirements.
                    </li>
                    <li>
                      In order to participate in the Program, the Applicant understands that it must enter into a Participant Acceptance and
                      comply with all terms and conditions thereof.
                    </li>
                    <li>
                      Prior to entering into a Participant Acceptance, any amounts spent by the Applicant on the purchase and installation
                      of Eligible Measures may not be eligible for and may not receive a Project Incentive. Any such amounts are at the sole
                      risk of the Applicant.{" "}
                    </li>
                    <li>
                      ERA may waive any informality or irregularity at its discretion or to otherwise exercise administrative discretion
                      with respect to an Applicant or its compliance with the Program requirements.{" "}
                    </li>
                    <li>
                      Payment of any Project Incentive or other amount by ERA is subject to the availability of Program funding. Program
                      funding may cease without notice.{" "}
                    </li>
                    <li>
                      ERA may make changes, including substantial changes, to this Program or its documents (including the Eligible Measures
                      List, Application and Participant Acceptance) without any liability whatsoever to the Applicant.
                    </li>
                    <li>
                      The Program or any part of it, including the incentives for the project or any Eligible Measure, may be changed,
                      reduced, increased, amended, suspended, cancelled or terminated at any time and for any reason whatsoever without
                      prior notice to the Participant, in each case without any obligation, liability or any reimbursement to the Applicant.
                    </li>
                  </ol>
                </div>
                <div className="terms-agree-check">
                  <CheckBox onChange={this.handleAgreeToTerms} checked={applicationObj.agreeToApplicationTerms} />
                  The applicant agrees to the above representations, warranties, acknowledgments, terms and conditions
                </div>
              </div>
            ) : (
              <div className="form-inner-section">
                <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Requirements for Submission" }}></h2>

                <div className="summary-list-container">
                  <div className="list-heading">The Applicant represents and warrants as follows:</div>
                  <ol>
                    <li>
                      This Post-Project Application is being submitted pursuant to the Participant Acceptance and subject to the{" "}
                      <span>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://firebasestorage.googleapis.com/v0/b/dolphin-live-a906a.appspot.com/o/SPEED%20TermsAndConditions.pdf?alt=media&token=bf02d654-d692-4b34-936f-d64334a86aea"
                        >
                          Terms and Conditions
                        </a>
                      </span>{" "}
                      thereof.
                    </li>
                    <li>
                      The Participant represents and warrants that all information contained in this Post-Project Application is complete,
                      true and accurate.
                    </li>
                    <li>
                      The Participant confirms that it meets the eligibility requirements to be a Participant and that the Facility meets
                      the requirements to be an Eligible Facility.
                    </li>
                    <li>
                      The Participant confirms that the Measures set out in this Post-Project Application meet the requirements to be
                      Eligible Measures, and that the project meets the requirement to be an Eligible Project.
                    </li>
                    <li>The Participant confirms that the Eligible Project was installed at the Eligible Facility .</li>
                    <li>The Participant confirms that the Eligible Project only contains Eligible Expenses.</li>
                  </ol>
                </div>
                <div className="terms-agree-check">
                  <CheckBox onChange={this.handleAgreeToPostProjectTerms} checked={applicationObj.agreeToPostProjectApplicationTerms} />
                  The applicant agrees to the above representations, warranties, acknowledgments, terms and conditions
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  handleMeasureChange = (name, value) => {
    const { applicationObj } = this.state;
    if (name === "measureCategory") {
      this.props.setMeasureCategory(value);
      this.props.loadMeasureTypes(value.value);
      this.props.setMeasureType('');
      this.props.loadMeasures();
      this.props.setMeasure('');
    } else if (name === "measureType") {
      this.props.setMeasureType(value);
      this.props.loadMeasures(value.value);
      this.props.setMeasure('');
    } else if (name === "measure") {
      this.props.setMeasure(value);
      this.props.loadMeasureConfig(applicationObj.stage, value.value);
    }
  };

  doDynamicCalculations = async (formData) => {
    const { measureConfig } = this.props;
    const doc = await utils.getAdminConfigDoc("measureIncentiveCategory");
    let merged = { ...doc, ...formData };
    return applicationService.calculateMeasureValues(measureConfig, merged);
  };

  sumIt = (list, key) => {
    let ans = 0.0;
    for (let item of list) {
      ans += parseFloat(item[key]);
    }
    return ans;
  };

  sumOfTEMs = (measures) => {
    let ans = 0.0;
    for (let item of measures) {
      ans += parseFloat(utils.getTEM(item));
    }
    return ans.toFixed(2);
  };

  getTEM = (measure) => {
    let ans = "";
    let options = [
      "Total Emissions Reduction",
      "Total Emissions Reduction - VFD for Fan/Pump Motor",
      "Total Emissions Reduction - Strip Curtain",
      "Total Emissions Reduction - Water Heater",
      "Total Emissions Reduction - Insulation",
      "Total Emissions Reduction - Windows",
      "Total Emissions Reduction - Economizer",
      "Total Emissions Reduction - Steam Fittings and Pipe Insulation",
      "Total Emissions Reduction - Boiler or Heater",
      "Total Emissions Reduction - ASHP or VRF",
      "Total Emissions Reduction - Make-Up Air Unit",
      "Total Emissions Reduction - HRV/ERV",
      "Total Emissions Reduction - Pipe Insulation",
    ];
    for (let item of options) {
      if (measure[item]) {
        return parseFloat(measure[item]).toFixed(2);
      }
    }
    return "";
  };

  measureProjectSummary = () => {
    const { applicationObj, appReviewData, postProjectAppReviewData, payeeSubmissionConfirmed } = this.state;
    const {
      measureCategoriesLoading,
      measureCategories,
      measureTypesLoading,
      measureTypes,
      measuresLoading,
      measures,
      measureConfigLoading,
      measureConfig,
      measureList,
      measureCategory,
      measureType,
      measureCode,
      measureDrawerConfig,
    } = this.props;

    let draftApproved = applicationObj.status === "Application Draft Approved" && applicationObj.stage === "Pre-Approval";
    let appSubmitted = applicationObj.status === "Application Submitted" && applicationObj.stage === "Pre-Approval";

    let isBeingUpdated = false;
    if (measureDrawerConfig.finishButtonTitle && measureDrawerConfig.finishButtonTitle.toLowerCase() === "update measure") {
      isBeingUpdated = true;
    }
    let fields = [
      {
        label: "Measure Category",
        type: "dropdown",
        mandatory: true,
        disabled: isBeingUpdated,
        name: "measureCategory",
        value: measureCategory,
        placeholder: "Select a Measure Category",
        options: measureCategoriesLoading
          ? []
          : measureCategories
              .filter((item) => {
                return item.status;
              })
              .map((item) => {
                return { value: item.category, label: item.category };
              }),
      },
      {
        label: "Measure Type",
        type: "dropdown",
        mandatory: true,
        name: "measureType",
        value: measureType,
        disabled: utils.isNullOrEmpty(measureTypes) || isBeingUpdated,
        placeholder: "Select a Measure Type",
        options: measureTypesLoading
          ? []
          : measureTypes.map((item) => {
              return { value: item.measureType, label: item.measureType };
            }),
      },
      {
        label: "Measure",
        type: "dropdown",
        mandatory: true,
        name: "measure",
        value: measureCode,
        disabled: utils.isNullOrEmpty(measures) || isBeingUpdated,
        placeholder: "Select a Measure",
        options: measuresLoading
          ? []
          : measures.map((item) => {
              return { value: item.measureCode, label: item.measureName };
            }),
      },
    ];
    if (measureConfig.length > 0) {
      fields = fields.concat([{ heading: "Measure Specific Information", type: "heading" }]).concat(measureConfig);
    }

    let dynamicForm = {
      title: measureDrawerConfig.title,
      actionButton: {
        title: measureDrawerConfig.buttonTitle,
      },
      finishButton: {
        title: measureDrawerConfig.finishButtonTitle,
      },
      fields,
    };
    let measureCategoriesArr = this.props.configData
      ? this.props.configData.measureCategories
        ? this.props.configData.measureCategories.categories
        : []
      : [];
    let measureCategoriesHash = {};
    measureCategoriesArr.forEach((item) => {
      measureCategoriesHash[item.category] = item.status;
    });

    let maxVal = 1000000.0;
    if (applicationObj && applicationObj["Reviewer Estimated Incentive"]) {
      maxVal = parseFloat(applicationObj["Reviewer Estimated Incentive"]);
      maxVal = parseFloat((maxVal * 1.05).toFixed(2)); // Set to 105% of original
    }

    let inDraft = applicationObj.stage === "Pre-Project" && applicationObj.status === "Application Draft";
    let postProjectMeasuresPending = false;
    if (applicationObj.stage === "Post-Project") {
      for (let m of measureList) {
        let applicableIncentive = utils.getApplicableTEMI(m);
        if (applicableIncentive <= 0.0) {
          postProjectMeasuresPending = true;
          break;
        }
      }
    }

    return (
      <div className="container-box">
        <div className="form-inner-section">
          <div className="form-sections measure-summary">
            <DrawerWithDynamicForm
              toggleDrawer={this.toggleDrawer}
              disableClose={true}
              loading={measureDrawerConfig.loading}
              onClose={this.props.clearMeasureDrawer}
              onChange={this.handleMeasureChange}
              openDrawer={measureDrawerConfig.open}
              className={"admin-panel-drawer"}
              formJSON={dynamicForm}
              doDynamicCalculations={this.doDynamicCalculations}
              submitForm={this.handleMeasureData}
            />
            <div className="step-header-container">
              <div className="step-header-container-left">
                <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Measure and Project Summary" }}></h2>
              </div>
              {applicationObj.stage !== "Post-Project" ? (
                <div className="step-header-container-right">
                  <Button title={"ADD MEASURE"} className="measure-btn" uppercase onClick={this.addMeasure} />
                  <div className="info-icon">
                    <TooltipInfo dynamicPosition={true} helpText={"This allows you to add a measure to your application."} />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="form-inner-section">
              {measureList.length > 0 ? (
                <div className="summary-container">
                  <div className="summary-items-row summary-items-header">
                    <div className="summary-item-details">Measure</div>
                    <div className="summary-item-details">Quantity</div>
                    {/* <div className="summary-item-details">Estimated Emissions Reduction (Lifetime)</div> */}
                    {applicationObj.stage === "Post-Project" ? (
                      <React.Fragment>
                        <div className="summary-item-details">Pre-Approved Incentive ($)</div>
                        <div className="summary-item-details">Post-Project Applicable Incentive ($)</div>
                      </React.Fragment>
                    ) : (
                      <div className="summary-item-details">Estimated Incentive ($)</div>
                    )}
                    <div className="summary-item-details"></div>
                  </div>
                  {measureList.map((measure) => (
                    <div
                      className={`summary-items-row ${
                        utils.getApplicableTEMI(measure) === 0 && applicationObj.stage === "Post-Project" ? "update-details" : ""
                      }`}
                    >
                      <div className="summary-item-details"><label>Measure</label><span>{measure.measure.label}</span></div>
                      <div className="summary-item-details"><label>Quantity</label><span>{utils.formatNumberWithCommas(measure.Quantity)}</span></div>
                      {/* <div className="summary-item-details"><label>Estimated Emissions Reduction (Lifetime)</label><span>{utils.formatNumberWithCommas(utils.getTEM(measure))} tonnes</span></div> */}
                      {applicationObj.stage === "Post-Project" ? (
                        <React.Fragment>
                          <div className="summary-item-details">
                          <label>Pre-Approved Incentive ($)</label>
                          <span>{utils.formatAsCAD(appReviewData.measuresReviewerEstimatedIncentive[measure.id])}</span>
                          </div>
                          <div className="summary-item-details">
                          <label>Post-Project Applicable Incentive ($)</label>
                          <span>{utils.formatAsCAD(utils.getApplicableTEMI(measure))}</span>
                          </div>
                        </React.Fragment>
                      ) : (
                        <div className="summary-item-details"><label>Estimated Incentive ($)</label><span>{utils.formatAsCAD(utils.getTEMI(measure))}</span></div>
                      )}
                      <div className="summary-item-details">
                      <label>Actions</label>
                      <div className="summary-button-set">
                        {measureCategoriesHash[measure.measureCategory.value] || !inDraft ? (
                          <div className="summary-action-btn">
                            {utils.getApplicableTEMI(measure) === 0 && applicationObj.stage === "Post-Project" ? (
                              <React.Fragment>
                                <UpdateMeasureIcon
                                  width="15"
                                  height="15"
                                  onClick={() => {
                                    this.editMeasure(measure.id);
                                  }}
                                />{" "}
                                <span>Update</span>{" "}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <EditDocIcon
                                  width="15"
                                  height="15"
                                  onClick={() => {
                                    this.editMeasure(measure.id);
                                  }}
                                />{" "}
                                <span>Edit</span>
                              </React.Fragment>
                            )}
                          </div>
                        ) : (
                          <div className="summary-action-btn">
                            <div className="info-icon">
                              <AlertInfo
                                customIcon={AlertIcon}
                                helpText={`An application cannot proceed with ${measure.measureCategory.label} as a measure. Please delete it, and you will be able to save and proceed`}
                              />
                            </div>
                          </div>
                        )}
                        <div
                          className="summary-action-btn"
                          onClick={() => {
                            this.removeMeasure(measure);
                          }}
                        >
                          <TrashIcon width="15" height="15" />
                          <span>Remove</span>
                        </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="summary-items-row summary-items-footer">
                    <div className="summary-item-details">Total</div>
                    <div className="summary-item-details"></div>
                    {/* <div className="summary-item-details"><label>Estimated Emissions Reduction (Lifetime)</label><span>{utils.formatNumberWithCommas(this.sumOfTEMs(measureList))} tonnes</span></div> */}
                    {applicationObj.stage === "Post-Project" ? (
                      <React.Fragment>
                        <div className="summary-item-details">
                          <label>Pre-Approved Incentive ($)</label>
                          <span>{utils.formatAsCAD(applicationObj["Reviewer Estimated Incentive"] || 0.0)}</span>
                        </div>
                        <div className="summary-item-details">
                          <label>Post-Project Applicable Incentive ($)</label>
                          <span>{utils.formatAsCAD(utils.sumOfApplicableTEMIs(measureList, applicationObj))}</span>
                          {parseFloat(utils.sumOfApplicableTEMIs(measureList, applicationObj)) >= maxVal ? (
                            <div className="info-icon">
                              <TooltipInfo dynamicPosition={true} helpText={"The maximum applicable incentive is capped at 105% of the pre-approved incentive"} />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </React.Fragment>
                    ) : (
                      <div className="summary-item-details">
                        <label>Estimated Incentive ($)</label>
                        <span>{utils.formatAsCAD(utils.sumOfTEMIs(measureList))}</span>
                        {parseFloat(utils.sumOfTEMIs(measureList)) >= 1000000 ? (
                          <div className="info-icon">
                            <TooltipInfo dynamicPosition={true} helpText={"Please note that the project incentive is capped at $1,000,000"} />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    <div className="summary-item-details"></div>
                  </div>
                </div>
              ) : (
                <div className="empty-summary-container">
                  {applicationObj.stage !== "Post-Project" ? (
                    "There are no measures added currently. Add a Measure above to view measure specific details here."
                  ) : (
                    <React.Fragment>
                      <span>
                        You have removed all the pre-approved measures attached to this application. This application will be considered
                        closed by the ESB Program team. If this is by mistake, please contact{" "}
                        <a href="mailto:speed@esbprogram.ca">speed@esbprogram.ca.</a>
                      </span>
                    </React.Fragment>
                  )}
                </div>
              )}
              {postProjectMeasuresPending ? (
                <p className="measures-notice">
                  <span>Note:</span> There are measures attached to the application that have certain details pending. Please update the
                  highlighted measures in the table to proceed to the next step.
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  addMeasure = () => {
    this.props.loadMeasureCategories();
    this.props.clearMeasureDrawer(true);
  };

  editMeasure = (measureId) => {
    this.props.reloadMeasure(this.state.applicationObj.stage, measureId);
  };

  selectMeasure = (measureName) => {
    let measureList = this.state.measureList;
    let fIndex = measureList.findIndex((f) => f.measureName === measureName);
    if (fIndex > -1) {
      this.setState({
        selectedMeasure: measureList[fIndex],
        measureList,
      });
    } else {
      this.setState({
        selectedMeasure: {},
      });
    }
  };

  deleteDocument = (documentId) => {
    this.setState({
      deleteDocumentModalOpen: true,
      documentIdToDelete: documentId,
    });
  };

  removeMeasure = (data) => {
    this.setState({
      removeMeasureModalOpen: true,
      measureDataTORemove: data,
    });
  };

  handleMeasureData = (measureData) => {
    measureData.applicationId = this.state.applicationId;
    const { editingMeasureId } = this.props;
    const { userInfo } = this.state;
    measureData["actionTaker"] = `${userInfo.firstName} ${userInfo.lastName}`;
    if (!utils.isNullOrEmpty(editingMeasureId)) {
      this.props.updateAppMeasure(editingMeasureId, measureData);
    } else {
      this.props.createAppMeasure(measureData);
    }
  };

  goToAccount = () => {
    this.props.history.push("/account");
  };

  saveAndExit = () => {
    const { slideIndex, slideTotal, applicationObj, userInfo, editing, applicationId } = this.state;
    const newSlideIndex = slideIndex + 1;
    if (!editing && slideIndex === 0) {
      applicationObj.companyName = userInfo.companyDetails.name;
      if (userInfo.accountCategory === "customer") {
        applicationObj.customerId = userInfo.uid;
      }
      applicationService.createApplication(applicationObj).then((app) => {
        this.setState({
          applicationId: app.id,
        });
        this.goToAccount();
      });
    } else {
      if (this.verifyForm(applicationObj)) {
        delete applicationObj.createdAt;
        this.props.updateApplication(applicationId, applicationObj);
        this.goToAccount();
      }
    }
  };

  submitForReview = () => {
    const { applicationId, applicationObj } = this.state;
    const { userInfo } = this.state;
    let newStatus = "Application Draft Submitted";
    let updateObj = {
      status: newStatus,
      agreeToApplicationTerms: true,
      actionTaker : `${userInfo.firstName} ${userInfo.lastName}`,
      actionNote : ''
    };
    
    if (applicationObj.status === "Application Information Requested") {
      newStatus = "Application Draft Under Review";
      updateObj.status = newStatus;
      updateObj["IRResponseReceived"] = true;
    }
    this.props.history.push(`/applicationforreview/${applicationId}`);
    this.props.updateApplication(applicationId, updateObj);
  };
  submitPostProject = () => {
    const { applicationId, applicationObj } = this.state;
    const { userInfo } = this.state;
    let newStatus = "Application Submitted";
    let updateObj = {
      status: newStatus,
      agreeToPostProjectApplicationTerms: true,
      actionTaker : `${userInfo.firstName} ${userInfo.lastName}`,
      actionNote : ''
    };
    if (applicationObj.status === "Application Information Requested") {
      newStatus =  "Application Under Review";
      if(applicationObj.qaQCInProgress){
        newStatus = 'QA/QC In Progress';
      }
      updateObj.status = newStatus;
      updateObj["IRResponseReceived"] = true;
    }
    this.props.updateApplication(applicationId, updateObj);
    this.props.history.push(`/applicationforreview/${applicationId}`);
  };
  addPayee = () => {
    const { payee } = this.state;
    let updateObj = {};
    const { applicationId, slideIndex } = this.state;
    updateObj = {
      payeeAssigned: true,
      payeeInfo: { payeeName: payee.label, payeeAccountCategory: payee.payeeAccountCategory ,uid:payee.value},
    };
    if (payee.payeeAccountCategory === "contractor") {
      updateObj["Incentive Payment to Eligible Contractor Authorization"] = true;
    }
    userService.getUserDetails(payee.value, (success, payeeDetails) => {
      this.setState({payeeDetails, payeeAssigned : true},()=>{
        this.toggleConfirmPayeeSubmissionModal(false,()=>{
          const newSlideIndex = slideIndex + 1;
          this.props.updateApplication(applicationId, updateObj);
          this.setState({ slideIndex: newSlideIndex }, () => {
            window.scrollTo(0, 0);
          });
        });
      });
    });
  };
  nextStep = () => {
    const { slideIndex, slideTotal, applicationObj, userInfo, editing, applicationId, payeeSubmissionConfirmed , currentProgram } = this.state;
    const isContractor = userInfo ? userInfo.accountCategory.toLowerCase() === "contractor" : false;
    const finalStageForContractor = slideIndex > 3 && isContractor;
    if (finalStageForContractor) {
      this.props.updateApplication(applicationId, applicationObj);
      userService.sendApplicationSharedEmail(userInfo.email, applicationObj);
      this.props.history.push(`/applicationshared/${applicationId}`);
      return;
    }
    const newSlideIndex = slideIndex + 1;
    if (!editing && slideIndex === 0) {
      applicationObj.companyName = userInfo.companyDetails.name;
      if (userInfo.accountCategory === "customer") {
        applicationObj.customerId = userInfo.uid;
      }
      applicationService.createApplication(applicationObj).then((app) => {
        this.props.history.push(`/application/${app.id}`)
        this.setState({
          applicationId: app.id,
          slideIndex: newSlideIndex,
        });
      });
    } else {
      if (this.verifyForm(applicationObj)) {
        let contractorMissingMessage = "";
        if (utils.isNullOrEmpty(applicationObj.contractor.value)) {
          contractorMissingMessage = "Please first go to the Contractor Details Step above and add a contractor";
        } else {
          contractorMissingMessage = "";
        }
        if (newSlideIndex === slideTotal) {
          if (applicationObj.stage !== "Post-Project") {
            this.setState({
              contractorMissingMessage,
              submitForReviewModalOpen: true,
            });
          } else {
            this.setState({
              contractorMissingMessage,
              submitPostProjectModalOpen: true,
            });
          }
        } else {
          if (applicationObj.stage === "Post-Project" && slideIndex === 5) {
            this.toggleConfirmPayeeSubmissionModal(true);
          } else {
            delete applicationObj.createdAt;
            this.props.updateApplication(applicationId, applicationObj);
            this.setState({ slideIndex: newSlideIndex }, () => {
              window.scrollTo(0, 0);
            });
          }
        }
      }
    }
  };

  verifyForm = (applicationObj) => {
    let errors = {};
    let errorCount = 0;
    let { slideIndex, agreementText, payee } = this.state;
    if (slideIndex === 0) {
      if (utils.isNullOrEmpty(applicationObj.contractor.value)) {
        errors.contractor = "Contractor is required.";
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.contractorCanEdit)) {
        errors.contractorCanEdit = "Contractor Access Option is required.";
        errorCount++;
      }
    } else if (slideIndex === 1) {
      if (utils.isNullOrEmpty(applicationObj.projectName)) {
        errors.projectName = "Application (or) Project Name is required.";
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.projectStartDate)) {
        errors.projectStartDate = "Estimated Project Start Date is required.";
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.isReceivingOtherFunding)) {
        errors.isReceivingOtherFunding = "Please select an option.";
        errorCount++;
      }
      if (applicationObj.isReceivingOtherFunding && applicationObj.isReceivingOtherFunding.toLowerCase() === "yes") {
        if (utils.isNullOrEmpty(applicationObj.sourcesOfAllOtherFunding)) {
          errors.sourcesOfAllOtherFunding = "The funding sources are required.";
          errorCount++;
        }
        if (utils.isNullOrEmpty(applicationObj.totalAmountOfAllOtherFunding)) {
          errors.totalAmountOfAllOtherFunding = "The funding amount is required.";
          errorCount++;
        }
      }

      if (applicationObj.stage === "Post-Project") {
        if (utils.isNullOrEmpty(applicationObj.actualProjectEndDate)) {
          errors.actualProjectEndDate = "Actual Project End Completion is required.";
          errorCount++;
        }
      }

      //  else {
      //   if(!utils.isNullOrEmpty(applicationObj.projectCompletionDate)){
      //     if(new Date(applicationObj.projectStartDate) > new Date(applicationObj.projectCompletionDate)){
      //       errors.projectStartDate = 'Estimated Project Start Date cannot be a date later than the Estimated Project Completion Date.';
      //       errorCount++;
      //     }
      //   }
      // }
      if (utils.isNullOrEmpty(applicationObj.projectCompletionDate)) {
        errors.projectCompletionDate = "Estimated Project Completion Date is required.";
        errorCount++;
      } else {
        if (!utils.isNullOrEmpty(applicationObj.projectStartDate)) {
          if (new Date(applicationObj.projectStartDate) > new Date(applicationObj.projectCompletionDate)) {
            errors.projectCompletionDate =
              "Estimated Project Completion Date has to be a date later than the Estimated Project Start Date.";
            errorCount++;
          }
        }
      }

      if (utils.isNullOrEmpty(applicationObj.amountOfEligibleExpenses)) {
        errors.amountOfEligibleExpenses = "Please enter amount.";
        errorCount++;
      }
    } else if (applicationObj.stage === "Post-Project" && slideIndex === 5) {
    //  console.log("selected payee===", payee);
      if (!payee || (payee && utils.isNullOrEmpty(payee.value))) {
        errors["payee"] = "Payee selection is required";
        errorCount++;
      } else if (payee.payeeAccountCategory === "contractor" && utils.isNullOrEmpty(agreementText)) {
        errors["agreementText"] = "You are required to type the above sentence to proceed";
        errorCount++;
      } else {
        if (
          payee.payeeAccountCategory === "contractor" &&
          agreementText.toLowerCase() !==
            "i represent and warrant to emissions reduction alberta that i have authority to bind the participant"
        ) {
          errors["agreementText"] = "You are required to type the exact sentence mentioned above to proceed";
          errorCount++;
        }
      }
    }
    if (errorCount > 0) {
      this.setState({ errors }, () => {
        let allErrorRefs = document.querySelectorAll(".input-box-error");
        if (allErrorRefs.length > 0) {
          let firstErrorRef = allErrorRefs[0];
          firstErrorRef.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      });
    }
    return errorCount === 0;
  };

  viewRequestedInformation = () => {
    this.setState({
      showRequestedInfoModal: true,
    });
    //SHOW MODAL WITH REQUESTED INFORMATION
  };

  renderAppDetails = () => {
    const { applicationObj, appReviewData } = this.state;
    return !utils.isNullOrEmpty(applicationObj) ? (
      <React.Fragment>
        <div className="application-details-inner-container">
          <div className="application-details">
            <div className="application-detail">Pre-Project Application Creation Date</div>
            <div className="application-detail">{utils.formatAmericanDate(applicationObj["createdAt"])}</div>
          </div>
          <div className="application-details">
            <div className="application-detail">Pre-Project Application Approval Date</div>
            <div className="application-detail">
              {utils.formatAmericanDate(applicationObj["Pre-Approval - Application Draft Approved Time"])}
            </div>
          </div>
          <div className="application-details">
            <div className="application-detail">Participant Acceptance Date</div>
            <div className="application-detail">
              {utils.formatAmericanDate(applicationObj["Pre-Approval - Application Submitted Time"])}
            </div>
          </div>
          <div className="application-details">
            <div className="application-detail">Project Completion Deadline</div>
            <div className="application-detail">{utils.formatAmericanDate(applicationObj["Project Completion Deadline"])}</div>
          </div>
          <div className="application-details">
            <div className="application-detail">Pre-Approved Incentive</div>
            <div className="application-detail">
              {applicationObj["Reviewer Estimated Incentive"] && utils.formatAsCAD(applicationObj["Reviewer Estimated Incentive"])}
            </div>
          </div>
          {appReviewData ? (
            <div className="application-details">
              <div className="application-detail">Pre-Approval Note</div>
              <div className="application-detail" dangerouslySetInnerHTML={{ __html: appReviewData.informationRequest }} />
            </div>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    ) : (
      ""
    );
  };

  render() {
    const {
      errors,
      slideIndex,
      slideTotal,
      applicationObj,
      cancelModalOpen,
      removeMeasureModalOpen,
      submitForReviewModalOpen,
      submitPostProjectModalOpen,
      deleteDocumentModalOpen,
      editing,
      dataLoaded,
      userInfo,
      customerData,
      showRequestedInfoModal,
      showPreApprovalInfoModal,
      contractorMissingMessage,
      render404,
      confirmPayeSubmissionModal,
      appReviewData,
      postProjectAppReviewData
    } = this.state;
    if (render404) {
      return <Redirect to="/error404" />;
    }
    const { applicationProcessing, measureList, configData } = this.props;
    let TooltipTextMeasureDisabled =
      "There is a measure attached to the application that is no longer being accepted for the program. Please delete it, and you will be able to save and proceed";
    const measuresStage = slideIndex === 3;
    const facilitiesStage = slideIndex === 2;
    let thereAreErrors = !utils.isNullOrEmpty(errors);
    let inDraft = applicationObj.stage === "Pre-Project" && applicationObj.status === "Application Draft";
    let canGoToNextStep = true;
    let measureDisabled = false;
    if (measuresStage && utils.isNullOrEmpty(measureList)) {
      canGoToNextStep = false;
    } else if (measuresStage && applicationObj && applicationObj.stage === "Pre-Project") {
      let totalEstimatedIncentive = parseFloat(utils.sumOfTEMIs(measureList));
      if (totalEstimatedIncentive < 500 && measuresStage) {
        canGoToNextStep = false;
        TooltipTextMeasureDisabled = "The total estimated incentive value for the application is less than $500. Please add or update the measures to be eligible for the program.";
      }
      let measureCategoriesHash = {};
      let measureCategoriesArr = this.props.configData
        ? this.props.configData.measureCategories
          ? this.props.configData.measureCategories.categories
          : []
        : [];
      measureCategoriesArr.forEach((item) => {
        measureCategoriesHash[item.category] = item.status;
      });
      for (let m of measureList) {
        if (!measureCategoriesHash[m.measureCategory.value] && measuresStage && inDraft) {
          measureDisabled = true;
          break;
        }
      }
    } else if (measuresStage && applicationObj && applicationObj.stage === "Post-Project") {
      for (let m of measureList) {
        let applicableIncentive = utils.getApplicableTEMI(m);
        if (applicableIncentive <= 0.0) {
          canGoToNextStep = false;
          break;
        }
      }
      let totalApplicableIncentive = parseFloat(utils.sumOfApplicableTEMIs(measureList));
      if (totalApplicableIncentive < 500 && measuresStage) {
        canGoToNextStep = false;
        TooltipTextMeasureDisabled = "The total applicable incentive value for the application is less than $500. Please add or update the measures to be eligible for the program.";
      }
      let measureCategoriesHash = {};
      let measureCategoriesArr = this.props.configData
        ? this.props.configData.measureCategories
          ? this.props.configData.measureCategories.categories
          : []
        : [];
      measureCategoriesArr.forEach((item) => {
        measureCategoriesHash[item.category] = item.status;
      });
      for (let m of measureList) {
        if (!measureCategoriesHash[m.measureCategory.value] && measuresStage && inDraft) {
          measureDisabled = true;
          break;
        }
      }
    } 
    if (facilitiesStage && utils.isNullOrEmpty(applicationObj.facilityId)) {
      canGoToNextStep = false;
    }

    const isContractor = userInfo ? userInfo.accountCategory.toLowerCase() === "contractor" : false;
    const customerFirstName = customerData ? customerData.firstName : "";
    const finalStageForCustomer = slideIndex + 1 === slideTotal;
    let buttonText = "";
    if (finalStageForCustomer) {
      if (applicationObj.stage === "Post-Project") {
        buttonText = `SUBMIT`;
      } else {
        buttonText = "Submit for Review";
      }
    } else {
      buttonText = "Save and Proceed";
    }
    let hideSaveAndExit = false;
    const finalStageForContractor = slideIndex > 3 && isContractor;
    if (finalStageForContractor) {
      hideSaveAndExit = true;
      buttonText = `SAVE & INFORM ${customerFirstName}`;
    }

    let informationRequestNote = '';
    if(appReviewData && appReviewData.informationRequest){
      informationRequestNote = appReviewData.informationRequest;
    }
    if(postProjectAppReviewData && postProjectAppReviewData.informationRequest){
      informationRequestNote = postProjectAppReviewData.informationRequest;
    }
    return (
      <InternalLayout history={this.props.history}>
        <div className="new-application-container">
          <Modal
            HeaderText={"Requested Information"}
            IsOpen={showRequestedInfoModal}
            className={"requested-info-modal"}
            handleCloseModal={() => this.toggleRequestedInfoModal(false)}
          >
            <p className="requested-info-text" dangerouslySetInnerHTML={{ __html: informationRequestNote }} />
          </Modal>
          <Modal
            IsOpen={showPreApprovalInfoModal}
            className={"requested-info-modal"}
            handleCloseModal={() => this.togglePreApprovalInfoModal(false)}
          >
            <h3 className="">
              {" "}
              <button onClick={() => this.togglePreApprovalInfoModal(false)} className="back-button">
                <img src={LeftArrow} alt="arrow" />
                Back
              </button>{" "}
              PRE-APPROVAL DETAILS
            </h3>
            <div className="preapproval-details-container">
              <div className={`application-details-table`}>
                <div className="application-details-table-left">{this.renderAppDetails()}</div>
              </div>
            </div>
          </Modal>
          <Modal HeaderText={"Are you sure?"} IsOpen={cancelModalOpen} handleCloseModal={() => this.toggleCancelModal(false)}>
            <p>Any usaved changes you have made to the data till will be lost.</p>
            <div className="button-set">
              <Link
                onClick={() => {
                  this.props.history.length > 2 ? this.props.history.goBack() : this.props.history.push("/");
                }}
              >
                <Button className="inversed-btn" title={"Yes, take me back"} uppercase />
              </Link>
              <Link to={`/application/${this.props.match.params.id}`}>
                <Button title={"Continue Editing"} uppercase onClick={() => this.toggleCancelModal(false)} />
              </Link>
            </div>
          </Modal>
          <Modal
            HeaderText={"Confirmation"}
            IsOpen={submitForReviewModalOpen}
            handleCloseModal={() => this.toggleSubmitForReviewModal(false)}
          >
            {utils.isNullOrEmpty(contractorMissingMessage) ? (
              <p>You are about to submit the application draft for review. Are you sure?</p>
            ) : (
              <p>{contractorMissingMessage}</p>
            )}

            <div className="button-set">
              <Button title={"No"} className="inversed-btn" uppercase onClick={() => this.toggleSubmitForReviewModal(false)} />
              <Button
                onClick={() => {
                  this.submitForReview();
                }}
                title={"Yes"}
                disabled={!utils.isNullOrEmpty(contractorMissingMessage)}
                uppercase
              />
            </div>
          </Modal>
          <Modal
            HeaderText={"PAYEE ASSIGNMENT"}
            IsOpen={confirmPayeSubmissionModal}
            handleCloseModal={() => this.toggleConfirmPayeeSubmissionModal(false)}
          >
            <p>You are about to assign a Payee to the application.</p>
            <p>
              You will not be able to change the Payee once the Post-Project application is approved and an incentive recommendation is
              given for the project.{" "}
            </p>
            <div className="button-set">
              <Button title={"GO BACK"} className="inversed-btn" uppercase onClick={() => this.toggleConfirmPayeeSubmissionModal(false)} />
              <Button
                onClick={this.addPayee}
                title={"ASSIGN PAYEE"}
                uppercase
              />
            </div>
          </Modal>
          <Modal
            HeaderText={"SUBMIT DETAILS"}
            IsOpen={submitPostProjectModalOpen}
            handleCloseModal={() => this.toggleSubmitPostProjectModalOpen(false)}
          >
            <p>You are about to submit your Post Project Application as a participant of the Small Producer Energy Efficiency Deployment program.</p>
            <p>Please confirm that the details you have submitted are accurate.</p>

            <div className="button-set">
              <Button title={"GO BACK & REVIEW DETAILS"} className="inversed-btn" uppercase onClick={() => this.toggleSubmitPostProjectModalOpen(false)} />
              <Button
                onClick={() => {
                  this.submitPostProject();
                }}
                title={"SUBMIT DETAILS"}
                uppercase
              />
            </div>
          </Modal>
          <Modal
            HeaderText={"Are you sure?"}
            IsOpen={deleteDocumentModalOpen}
            handleCloseModal={() => this.toggleDeleteDocumentModal(false)}
          >
            <p>Are you sure you want to delete this document?</p>
            <div className="button-set">
              <Button className="inversed-btn" title={"No"} uppercase onClick={() => this.toggleDeleteDocumentModal(false)} />
              <Button  title={"Yes"} onClick={this.deleteDocumentFinally} uppercase />
            </div>
          </Modal>
          <Modal
            HeaderText={"Confirmation of Removal"}
            IsOpen={removeMeasureModalOpen}
            handleCloseModal={() => this.toggleRemoveMeasureModal(false)}
          >
            <p>Are you sure you want to remove this measure?</p>
            {applicationObj.stage === "Post-Project" ? (
              <p>
                <span>Note:</span> You will not be able to add a new measure to this application at this stage
              </p>
            ) : (
              ""
            )}
            <div className="button-set">
              <Button className="inversed-btn" title={"No"} uppercase onClick={() => this.toggleRemoveMeasureModal(false)} />
              <Button title={"Yes"} onClick={this.removeMeasureFinally} uppercase />
            </div>
          </Modal>
          <button onClick={() => this.toggleCancelModal(true)} className="back-button">
            <img src={LeftArrow} alt="arrow" />
            Back
          </button>
          {dataLoaded ? (
            <div className="section-header">
              <h2 className="section-title">{editing ? `Application ID: SPEED-${applicationObj.appNo}` : "Start an Application"}</h2>
            </div>
          ) : (
            ""
          )}
          {applicationObj.status === "Application Information Requested" ? (
            <React.Fragment>
              <Button title={"View Requested Information"} className="save-btn" uppercase onClick={this.viewRequestedInformation} />
            </React.Fragment>
          ) : (
            ""
          )}
          {applicationObj.stage === "Post-Project" ? (
            <React.Fragment>
              <Button title={"PRE-APPROVAL DETAILS"} className="save-btn" uppercase onClick={() => this.togglePreApprovalInfoModal(true)} />
            </React.Fragment>
          ) : (
            ""
          )}
          {dataLoaded ? (
            <FormStep
              steps={this.state.formSteps}
              slideIndex={slideIndex}
              accountCategory={userInfo.accountCategory}
              onChange={this.handleChangeSlide}
            />
          ) : (
            ""
          )}
          {dataLoaded ? (
            <React.Fragment>
              {applicationProcessing ? (
                <Loader />
              ) : (
                <React.Fragment>
                  {slideIndex === 0 ? this.contractorDetails() : null}
                  {slideIndex === 1 ? this.projectDetails() : null}
                  {slideIndex === 2 ? this.facilitySummary() : null}
                  {slideIndex === 3 ? this.measureProjectSummary() : null}
                  {slideIndex === 4 ? this.documentDetails() : null}
                  {applicationObj.stage === "Post-Project" && slideIndex === 5 ? this.payeeSummary() : null}
                  {slideIndex === (applicationObj.stage === "Post-Project" ? 6 : 5) ? this.applicationSummary() : null}
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <Loader />
          )}
          <div className="form-bottom">
            {(slideIndex !== 0) & !hideSaveAndExit ? (
              <Button
                title={"Save & Exit"}
                uppercase
                className="inversed-btn"
                onClick={this.saveAndExit}
                // disabled={thereAreErrors || applicationObj.contractor === ""}
              />
            ) : (
              ""
            )}
            {slideIndex > 3 && isContractor ? (
              <div className="document-note-container">
                <div className="document-note-container-left">
                  <b>Almost Done!</b>
                  <br />
                  Thank you for helping <b>{customerFirstName}</b> complete the application. We are almost at the end of the process. From
                  here, <b>{customerFirstName}</b> will have to review the application and submit it. You can notify the customer to begin
                  the review.
                </div>
              </div>
            ) : (
              ""
            )}
            {dataLoaded ? (
              !canGoToNextStep ? (
                <HtmlTooltip title={measureList.length > 0 ? TooltipTextMeasureDisabled : ''} placement="right" arrow>
                  <span>
                    <Button title={"Save and Proceed"} className="save-btn" uppercase disabled={true} />
                  </span>
                </HtmlTooltip>
              ) : (
                <Button
                  title={slideIndex === 0 ? (editing ? "Proceed to Next Step" : "Assign Contractor") : buttonText}
                  className="save-btn"
                  uppercase
                  onClick={this.nextStep}
                  disabled={
                    measureDisabled ||
                    !canGoToNextStep ||
                    thereAreErrors ||
                    (applicationObj.contractor && applicationObj.contractor.value === "") ||
                    (finalStageForCustomer && applicationObj.stage === "Pre-Project" && !applicationObj.agreeToApplicationTerms) ||
                    (finalStageForCustomer && applicationObj.stage === "Post-Project" && !applicationObj.agreeToPostProjectApplicationTerms)
                  }
                />
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </InternalLayout>
    );
  }
}

function mapState(state) {
  const { configData } = state.authentication;
  const {
    facilityConfig,
    facilityList,
    loadingFacilityConfig,
    loadingActiveContractors,
    activeContractors,
    loadingActiveContractorsError,
    applicationProcessing,
    applicationCreated,
    applicationId,
    measureCategories,
    measureCategoriesLoading,
    measureTypesLoading,
    measureTypes,
    measures,
    measuresLoading,
    measureConfig,
    measureConfigLoading,
    measureList,
    measureCategory,
    measureType,
    measureCode,
    measureDrawerConfig,
    documentList,
    editingMeasureId,
  } = state.application;
  return {
    configData,
    facilityConfig,
    facilityList,
    loadingFacilityConfig,
    loadingActiveContractors,
    activeContractors,
    loadingActiveContractorsError,
    applicationProcessing,
    applicationCreated,
    applicationId,
    measureCategories,
    measureCategoriesLoading,
    measureTypesLoading,
    measureTypes,
    measures,
    measuresLoading,
    measureConfig,
    measureConfigLoading,
    measureList,
    measureCategory,
    measureType,
    measureCode,
    measureDrawerConfig,
    documentList,
    editingMeasureId,
  };
}

const actionCreators = {
  loadFacilityConfig: appActions.getFacilitiesConfig,
  loadContractors: appActions.getActiveContractors,
  loadUserDetails: userActions.getUserDetails,
  loadMeasureCategories: appActions.getMeasureCategories,
  loadMeasureTypes: appActions.getMeasureTypes,
  loadMeasures: appActions.getMeasures,
  loadMeasureConfig: appActions.getMeasureConfig,
  loadConfig: userActions.getAdminConfig,
  loadFacilities: appActions.getFacilitiesForCustomer,
  updateUserDetails: userActions.updateUserDetails,
  createFacility: appActions.createFacility,
  createApplication: appActions.createApplication,
  updateApplication: appActions.updateApplication,
  setMeasureConfig: appActions.setMeasureConfig,
  createAppMeasure: appActions.createAppMeasure,
  updateAppMeasure: appActions.updateAppMeasure,
  loadAppMeasures: appActions.getAppMeasures,
  reloadMeasure: appActions.reloadMeasure,
  deleteAppMeasure: appActions.deleteAppMeasure,
  logout: userActions.logout,
  setMeasureCategory: appActions.setMeasureCategory,
  setMeasureType: appActions.setMeasureType,
  setMeasure: appActions.setMeasure,
  clearMeasureDrawer: appActions.clearMeasureDrawer,
  createDocument: appActions.createAppDocument,
  updateDocument: appActions.updateAppDocument,
  deleteDocument: appActions.deleteAppDocument,
  getAppDocuments: appActions.getAppDocuments,
};

export default connect(mapState, actionCreators)(Application);
