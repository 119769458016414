import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../redux/actions/userActions';
import { Link } from 'react-router-dom';
import { utils } from '../../../helpers/utils';
import { AuthContext } from '../../../firebaseAuthContext';
import FrontendLayout from '../../Common/FrontendLayout';
import BrokenLink from '../../../images/404-broken-wire.svg';
import Button from '../../Common/Button/Button';
import './ErrorNotFound.css';

class ErrorNotFound extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      forgotPasswordModalOpen: false
    }
  }



  render() {
    return <FrontendLayout wrapperClass={'notfound-wrapper'} showHeader={true}><div className="notfound-container">
      {/* <div className="logo"/> */}
      <img src={BrokenLink} alt='broken link'/>
      <h2>Looks like there is a broken link somewhere</h2>
      <p>The page you are looking for does not exist or it could have been moved to a different URL.</p>
      <Link to='/'>
            <Button
            title={'Go to home page'}
            uppercase
            />
        </Link>
      <p>In case of emergencies, you can call us at <a href='tel:+18444070025'>+1 844-407-0025</a>  or email us at <a href="mailto:speed@esbprogram.ca">speed@esbprogram.ca</a></p>
    </div></FrontendLayout>
  }
}

function mapState(state) {
    const { loggingIn, errors, email, password, passwordResetting, passwordReset, passwordResetError  } = state.authentication;
    return { loggingIn, errors, email, password, passwordResetting, passwordReset, passwordResetError };
}

const actionCreators = {
  login: userActions.login,
  setError: userActions.setLoginError,
  setField: userActions.setLoginField,
  sendPasswordReset: userActions.passwordReset,
  clearPasswordReset: userActions.clearPasswordReset
}

export default connect(mapState, actionCreators)(ErrorNotFound);
