import React from 'react';
import Button from '../../Common/Button/Button';
import Input from '../../Common/Input/Input';
import Modal from '../../Common/Modal/Modal';
import './SignUp.css';
import { SignUpFormData } from '../../../contentFile';
import { connect } from 'react-redux';
import {userActions} from '../../../redux/actions/userActions';
import {userService} from '../../../services/userService';
import {utils} from '../../../helpers/utils';
import LeftArrow from '../../../images/leftArrow.svg';
import {Link, Redirect} from 'react-router-dom';
import CheckBoxList from '../../Common/CheckboxList/CheckboxList';
import FrontendLayout from '../../Common/FrontendLayout';
import Loader from '../../Common/Loader/Loader';
import { debounce } from "debounce";
import TooltipInfo from '../../Common/TooltipInfo/TooltipInfo';
class SignUp extends React.Component{

  constructor(props){
      super(props);
      this.state = {
          createAccountModalOpen:false,
          cancelModalOpen:false,
          passwordValid : false,
          postalCodeEditable : false,
          user:{
            firstName : '',
            howDidYouHear:'',
            lastName : '',
            phoneNum : '',
            email : '',
            passwordOne : '',
            passwordTwo : '',
            accountCategory : '',
            companyName : '',
            companyWebsite : '',
            locationInfo : null,
            manualPostalCode : null,
            address2 : '',
            // isIndigenousOwned:'',
            isOwnedBySomeoneFromURG: '',
            terms : {
              agreeToCodeOfConduct:false,
              companyLegalNameNotice : false,
              caslConsent:false,
              useOfContractorInfo:false,
              useOfCustomerInfo:false,
              programPortalTermsOfUse:false,
              contractorInformationIsBusinessInfo : false,
              customerInformationIsBusinessInfo : false
            }
          },
          errors : {}
      }
      this.checkEmail = debounce(this.checkEmailInSystem, 300);
  }

  componentDidMount(){
    this.props.loadConfig();
  }

  onChangeHandler = (name, value) => {
    const { user, errors } = this.state;
    delete errors[name];
    this.setState({
        user: {
            ...user,
            [name]: value
        },
        errors
    });
  }

  onChecklistChangeHandler = (name, value) => {
    const { user, errors } = this.state;
    delete errors[name];
    let terms = user.terms;
    terms[name] = value;
    this.setState({
        user: {
            ...user,
            terms
        },
        errors
    });
  }

  checkEmailInSystem = ()=>{
    const { errors, user } = this.state;
    userService.checkIfEmailExists(user.email).then(({userExists})=>{
      if(userExists){
        errors.email = 'Email already exists in our system.';
        this.setState({
          errors
        });
      }
    });
  }

  onEmailChange = (name, value)=>{
    const { user, errors } = this.state;
    delete errors[name];
    this.setState({
      user: {
          ...user,
          [name]: value
      },
      errors
    },()=>{
      if(!utils.isNullOrEmpty(value)){
        if(utils.validateEmail(value)){
          this.checkEmail();
        }
      }
    });
  }

  onPasswordChangeHandler = (name, value) => {
    const { user, errors } = this.state;
    delete errors[name];
    if(utils.isNullOrEmpty(value)){
      let obj = {
        user: {
            ...user,
            [name]: value
        }};
        if(name === 'passwordOne'){
          obj.passwordValid = false;
        }
      this.setState(obj);
    } else {
        if(name === 'passwordOne'){
            let passwordValid = false;
            if(value === user.passwordTwo){
                delete errors.passwordTwo;
            } else {
                if(!utils.isNullOrEmpty(user.passwordTwo)){
                  errors.passwordTwo= 'Password does not match';
                }
            }
            if(utils.validatePassword(value)){
                passwordValid = true;
            } else {
                errors.passwordOne = 'Password does not meet requirements.';
            }
            this.setState({
              user: {
                  ...user,
                  [name]: value
              },
              errors,
              passwordValid
          });
        } else {
            if(value === user.passwordOne){
            } else {
                errors.passwordTwo= 'Password does not match';
            }
            this.setState({
              user: {
                  ...user,
                  [name]: value
              },
              errors
          });
        }
    }
  }

  onPhoneChange = (value) => {
    const { user, errors } = this.state;
    delete errors['phoneNum'];
    this.setState({
        user: {
            ...user,
            phoneNum: value
        },
        errors
    });
  }

  onCatChange = (name,cat) => {
    const { user,errors } = this.state;
    let accountCategory = cat.value;
    delete errors['accountCategory'];
    delete errors['useOfContractorInfo'];
    delete errors['useOfCustomerInfo'];
    delete errors['contractorInformationIsBusinessInfo'];
    delete errors['customerInformationIsBusinessInfo'];
    delete errors['programPortalTermsOfUse'];
    delete errors['caslConsent'];
    delete errors['companyLegalNameNotice'];
    this.setState({
        user: {
            ...user,
            accountCategory,
            howDidYouHear : '',
            terms : {
              agreeToCodeOfConduct:false,
              caslConsent:false,
              useOfContractorInfo:false,
              useOfCustomerInfo:false,
              programPortalTermsOfUse:false,
              contractorInformationIsBusinessInfo : false,
              customerInformationIsBusinessInfo : false
            }
        },
        errors
    });
  }

  // onindeginiousChange = (name, ind) => {
  //   const { user,errors } = this.state;
  //   let isIndigenousOwned = ind.value;
  //   delete errors['isIndigenousOwned'];
  //   this.setState({
  //       user: {
  //           ...user,
  //           isIndigenousOwned,
  //       },
  //       errors
  //   });
  // }
  onBusinessOwnerChange = (name,value) => {
    const { user,errors } = this.state;
    delete errors['isOwnedBySomeoneFromURG'];
    this.setState({
        user: {
            ...user,
            [name]: value,
        },
        errors
    });
  }

  onHowDidYouHearChange = (name, arg) => {
    const { user,errors } = this.state;
    this.setState({
        user: {
            ...user,
            howDidYouHear: arg.value,
        },
        errors
    });
  }


  onLocationChange = (locationInfo) => {
    const { user, errors, postalCodeEditable } = this.state;
    delete errors['companyAddress'];
    if(!postalCodeEditable){
      delete errors['manualPostalCode'];
    }
    let obj = {
      user: {
          ...user,
          locationInfo
      },
      errors
    };
    if((!(locationInfo ? true : false) || (locationInfo.postalCode === "")) && !postalCodeEditable){
      obj.user.manualPostalCode = '';
    }
    this.setState(obj);
  }

  verifyForm = (user)=>{
    let errors = {};
    let errorCount = 0;
    const { postalCodeEditable } = this.state;
    if(utils.isNullOrEmpty(user.firstName)){
        errors.firstName = 'First Name is required.'
        errorCount++;
    }
    if(utils.isNullOrEmpty(user.lastName)){
        errors.lastName = 'Last Name is required.'
        errorCount++;
    }
    if(utils.isNullOrEmpty(user.companyName)){
        errors.companyName = 'Company Name is required.'
        errorCount++;
    }
  //   if(utils.isNullOrEmpty(user.companyWebsite)){
  //     errors.companyWebsite = 'Company website is required.'
  //     errorCount++;
  // }
    if(utils.isNullOrEmpty(user.locationInfo)){
        errors.companyAddress = 'Company Address is required.'
        errorCount++;
    }
    if(utils.isNullOrEmpty(user.accountCategory)){
        errors.accountCategory = 'Please select Account Category.'
        errorCount++;
    }
    if(utils.isNullOrEmpty(user.isOwnedBySomeoneFromURG)){
      errors.isOwnedBySomeoneFromURG = 'Please select an answer.'
      errorCount++;
  }
  //   if(utils.isNullOrEmpty(user.isIndigenousOwned)){
  //     errors.isIndigenousOwned = 'Please select an answer.'
  //     errorCount++;
  // }
    if(!utils.isNullOrEmpty(user.phoneNum) && (user.phoneNum.length > 1 && user.phoneNum.length < 11)){
        errors.phoneNum = 'Business Mobile Number is invalid.'
        errorCount++;
    } else if(utils.isNullOrEmpty(user.phoneNum) || (user.phoneNum.length <= 1)){
        errors.phoneNum = 'Business Mobile Number is required.'
        errorCount++;
    }

    let postalCode = '';
    if(!utils.isNullOrEmpty(user.manualPostalCode)){
      postalCode = user.manualPostalCode;
    } 
    else if(!postalCodeEditable){
      postalCode = user.locationInfo ? user.locationInfo.postalCode : '';
    }

    if(utils.isNullOrEmpty(postalCode)){
      errors['manualPostalCode'] = 'Postal Code required.';
      errorCount++;
    }
    else if(!utils.validateCAPostalCode(postalCode)){
      errors['manualPostalCode'] = 'Invalid Postal code.';
      errorCount++;
    }

    if(!utils.isNullOrEmpty(user.email)){
        if(!utils.validateEmail(user.email)){
            errors.email = 'Email is invalid.'
            errorCount++;
        }
    } else {
        errors.email = 'Email is required.'
        errorCount++;
    }
    if(!utils.isNullOrEmpty(user.passwordOne)){
        if(!utils.validatePassword(user.passwordOne)){
            errors.passwordOne = 'Password does not meet requirements.';
            errorCount++;
        }
    } else {
        errors.passwordOne = 'Password is required.'
        errorCount++;
    }
    if(!utils.isNullOrEmpty(user.passwordTwo)){
        if(user.passwordOne !== user.passwordTwo){
            errors.passwordTwo = 'Passwords do not match.'
            errorCount++;
        }
    } else {
        errors.passwordTwo = 'Please confirm password.'
        errorCount++;
    }

    if(user.accountCategory !== ''){
      if(!user.terms.caslConsent){
        errors.caslConsent = true;
        errorCount++;
      }
      if(user.accountCategory.toLowerCase() === 'contractor'){
        if(!user.terms.useOfContractorInfo){
          errors.useOfContractorInfo = true;
          errorCount++;
        }
        if(!user.terms.contractorInformationIsBusinessInfo){
          errors.contractorInformationIsBusinessInfo = true;
          errorCount++;
        }
      }

      if(user.accountCategory.toLowerCase() === 'customer'){
        if(!user.terms.companyLegalNameNotice){
          errors.companyLegalNameNotice = true;
          errorCount++;
        }
        if(!user.terms.useOfCustomerInfo){
          errors.useOfCustomerInfo = true;
          errorCount++;
        }
        if(!user.terms.customerInformationIsBusinessInfo){
          errors.customerInformationIsBusinessInfo = true;
          errorCount++;
        }
      
      }
      if(!user.terms.programPortalTermsOfUse){
        errors.programPortalTermsOfUse = true;
        errorCount++;
      }
    }
    
    if(errorCount > 0){
        this.setState({errors},()=>{
          let allErrorRefs = document.querySelectorAll('.input-box-error,.error-box');
          if(allErrorRefs.length > 0){
            let firstErrorRef = allErrorRefs[0];
            firstErrorRef.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
          }
        });
    }
    return errorCount === 0;
  }

  nextStep = ()=>{
    const { user } = this.state;
    if(this.verifyForm(user)){
        userService.checkIfEmailExists(user.email).then(({userExists})=>{
          if(userExists){
            let errors = {email : 'Email already exists in our system.'};
            this.setState({errors});
          } else {
            this.setState({createAccountModalOpen:true});
          }
        });
      }
  }

  submitForm = ()=>{
    const { user } = this.state;
    if(this.verifyForm(user)){
        this.props.register({...user});
    }
  }

  toggleSignupModal = (modalOpen)=>{
    this.setState({createAccountModalOpen : modalOpen });
  }

  toggleCancelModal = (modalOpen)=>{
    this.props.history.push('/register');
    this.setState({cancelModalOpen : modalOpen });
  }
  
  checkTerm = (term)=>{
      this.setState({[`term${term}`]: !this.state[`term${term}`]});
  }

  handlePostalCodeEdit = (editable)=>{
    const { user, errors } = this.state;
    let postalCode = user.manualPostalCode;
    if(editable && utils.isNullOrEmpty(user.manualPostalCode)){
      postalCode = user.locationInfo ? user.locationInfo.postalCode : '';
    }
    if(!editable){
      delete errors['manualPostalCode'];
      postalCode = user.locationInfo ? user.locationInfo.postalCode : '';
    }
    this.setState({
      postalCodeEditable : editable,
      user : {
        ...user,
        manualPostalCode : postalCode
      },
      errors
    })
  }

  render(){
    const { registering, registrationError, registeredSuccessfully,configData } = this.props;
  if(registeredSuccessfully){
    return <Redirect to="/account" />;
  } else {
  const { user, errors, passwordValid, createAccountModalOpen, cancelModalOpen, postalCodeEditable } = this.state;
  const thereAreErrors = !utils.isNullOrEmpty(errors);
  const isContractor = user.accountCategory === "contractor";
  const howDidYouHearOptions = configData ? (isContractor ? configData.howDidYouHearContractor.options : configData.howDidYouHearCustomer.options) : [];
  // const isIndigenousOwnedOptions = configData?.isIndigenousOwned ?  configData.isIndigenousOwned.options  : [];
  const isIndigenousOwnedOptions =  [
    "Yes","No","Prefer not to Answer"
  ];
  
  
  let postalCode = '';
  if(!utils.isNullOrEmpty(user.manualPostalCode)){
    postalCode = user.manualPostalCode;
  } 
  else if(!postalCodeEditable){
    postalCode = user.locationInfo ? user.locationInfo.postalCode : '';
  }

  let userTermsArray =                   [
    {checked:user.terms['programPortalTermsOfUse'], name:'programPortalTermsOfUse', title : <p>I agree to the Program Portal("Dolphin") <a target="_blank" href='/termsofuse'>Terms of Use</a></p>},
    {checked:user.terms[`${isContractor ? 'contractorInformationIsBusinessInfo' : 'customerInformationIsBusinessInfo'}`], name: `${isContractor ? 'contractorInformationIsBusinessInfo' : 'customerInformationIsBusinessInfo'}`, title : isContractor ? <p>I agree that the information (“Eligible Contractor Information”) submitted in this sign-up form and in other communications and forms as part of the Program consists solely of business or commercial information and is not personal information of any individual.</p> : <p>I agree that the information (“Participant Information”) submitted in this sign-up form and in other communications and forms as part of the Program consists solely of business or commercial information and is not personal information of any individual.</p>},
    {
      checked:user.terms[`${isContractor ? 'useOfContractorInfo' : 'useOfCustomerInfo'}`], 
      name : `${isContractor ? 'useOfContractorInfo' : 'useOfCustomerInfo'}`,
      title : isContractor ? <p>I agree to the Uses of Eligible Contractor Information</p> : <p>I agree to the Uses of Participant Information</p>,
      extraInfo : isContractor ? <div>
        Eligible Contractor Information will be collected, used and disclosed by ERA to assess the applicant’s eligibility as an Eligible Contractor in the Program, and if accepted, to administer the Eligible Contractor’s participation in the Program and to assess their compliance with the Program terms.   Eligible Contractor Information may also be used to assess Program effectiveness and GHG reductions (“Program Purposes”).  ERA will handle such information in accordance with the ERA Privacy Policy.  
        <br/><br/>
        ERA may share Eligible Contractor Information with third-party service providers, including Enerva, who may collect, use and disclose Eligible Contractor Information for the Program Purposes.  Enerva may also share Eligible Contractor Information with its third party service providers for similar Program Purposes and in accordance with ERA’s Privacy Policy. 
        <br/><br/>
        ERA may disclose Eligible Contractor Information to the Alberta Ministry of Environment and Parks as part of ERA reports on the status, administration and outcome of the Program. In addition, the Eligible Contractor consents to ERA publishing Eligible Contractor Information to highlight the benefits derived to Albertans from the Program.
        <br/><br/>
        ERA is subject to FOIP and Eligible Contractor Information may be subject to disclosure under FOIP. 
        If the Eligible Contractor wishes to access, update or correct its information, the Eligible Contractor may contact ERA at the following address:   
        <br/><br/>
        Emissions Reduction Alberta<br/>
        Attention: Chief Privacy Officer<br/>
        #746, 10104 103 Ave NW<br/>
        Edmonton, AB T5J 0H8<br/>
        info@eralberta.ca
      </div> : <div>
        Participant Information will be collected, used and disclosed by Emissions Reduction Alberta (“ERA”) to assess the applicant’s eligibility for the Program, and if accepted into the Program, to administer the Participant’s participation in the Program and to assess their compliance with Program terms.   Participant Information may also be used to assess Program effectiveness and GHG reductions (“Program Purposes”).   ERA will handle such information in accordance with their privacy policy available at: https://eralberta.ca/privacy (the “ERA Privacy Policy”).   
        <br/><br/>
        ERA may share the Participant information with third-party service providers, including Enerva Energy Solutions Inc. (“Enerva”), who may collect, use and disclose Participant Information for the Program Purposes.  Enerva may also share Participant Information with its third-party service providers for similar Program Purposes and in accordance with the ERA Privacy Policy. 
        <br/><br/>
        ERA may disclose Participant Information to the Alberta Ministry of Environment and Parks as part of ERA reports on the status, administration and outcome of the Program. In addition, The Participant consents to ERA publishing Participant Information to highlight the benefits derived to Albertans from the Program. 
        <br/><br/>
        ERA is subject to the Freedom of Information and Protection of Privacy Act (Alberta) (“FOIPP”).  Participant Information may be subject to disclosure under FOIPP.   
        If the Participant wishes to access, update or correct its information, the Participant may contact ERA at the following address:   
        <br/><br/>
        Emissions Reduction Alberta
        Attention: Chief Privacy Officer
        #746, 10104 103 Ave NW
        Edmonton, AB T5J 0H8
        info@eralberta.ca
      </div>
    },
    { 
      checked:user.terms[`caslConsent`], 
      name : `caslConsent`,
      title : <p>I consent to being contacted by ERA or Enerva by e-mail, text or other electronic means for program-related matters or about other energy efficiency and greenhouse gas reducing technologies, products and services that ERA or Enerva offers.</p>,
      extraInfo : <div>
        {isContractor ? 
        <p>Contractor consents to ERA contacting Contractor by e-mail, text or other electronic means for the purposes of:<br/>
        <br/>
        (i) assessing this Application to be an Eligible Contractor<br/> 
        (ii) if this Application is accepted, administering the Program<br/>
        (iii) to conduct reporting, follow-up surveys, studies and audits.
        <br/><br/>
        Contractor also understands and acknowledges that ERA delivers this Program through third-party contractors and agents, including Enerva and that these entities may also contact the Contractor for similar purposes. The Contractor may withdraw consent at any time by e-mailing info@eralberta.ca.  However, if consent is withdrawn, the ability to participate in the Program may be compromised.</p> : 
        <p>
        Participant consents to ERA contacting Participant by e-mail, text or other electronic means for the purposes of<br/>
        <br/>
        (i) assessing the Application;<br/>
        (ii) if accepted into the Program, administering the Participant’s participation in the Program, including contact about entering into a Participant Acceptance;<br/>
        (iii) to conduct reporting, follow-up surveys, studies and audits. 
        <br/><br/>
        Participant also understands and acknowledges that ERA delivers this Program through third-party contractors and agents, including Enerva, and that these entities may also contact the Participant for similar purposes.   The Participant may withdraw consent at any time by e-mailing info@eralberta.ca.  However, if consent is withdrawn, the ability to participate in the Program may be compromised.  
        </p>
        }
      </div>
    }
  ];

  if(!isContractor){
    userTermsArray.splice(1,0,
      {checked:user.terms['companyLegalNameNotice'], name:'companyLegalNameNotice', title : <p>The <span>Company Name</span>  is the full legal name of the Applicant that will be a party to the Participant Acceptance, when executed.  Unless otherwise assigned to a third party, the Company Name is the name on the bank account to which incentive payments may be made when so approved. The Company Name will also be used to assess compliance with 6.1 (e) i.e. the total of all Project Incentives payable and paid to all direct and indirect <span>affiliates</span> (as defined in the Alberta Business Corporations Act) of the Participant as at the time of calculating the Project Incentive.</p>},
    );
  }

  return (
    <FrontendLayout><div className="signup-container">
      <Modal
            HeaderText={'CANCEL REGISTRATION?'}
            IsOpen={cancelModalOpen}
            handleCloseModal={()=>this.toggleCancelModal(false)}
        >
        <p>You are going to cancel the registration process. All the data that you have shared till now will not be saved.</p>
        <div className='button-set'>
        {/* <Link > */}
            <Button
            className='inversed-btn'
            title={'Cancel Registration'}
            uppercase
            onClick={()=>{this.props.history.length > 2 ? this.props.history.goBack() : this.props.history.push('/')}}
            />
        {/* </Link> */}
        {/* <Link to='/register'> */}
            <Button
            title={'Go Back'}
            uppercase
            onClick={()=>this.toggleCancelModal(false)}
            />
        {/* </Link> */}
        </div>
      </Modal>
      <Modal
            headerClass={registrationError ? 'error-header' : ''}
            HeaderText={registrationError ? 'NETWORK FAILURE' : 'CREATE ACCOUNT?'}
            IsOpen={createAccountModalOpen}
            handleCloseModal={()=>this.toggleSignupModal(false)}
            loading={registering}
        >
        {registrationError ? 
        <p className="error-text">There seems to have been a change in your network connection.<br/>Do not refresh or close this tab.<br/>Make sure you have a strong internet connection and then try clicking <span>Create Account</span> again.</p> 
        : <p className='subtitle'>You are about to create an account for <span>{user.companyName}</span> as a {user.accountCategory}.</p>}
        
        <div className='button-set'>
          <Link to='/register'>
            <Button
              className='inversed-btn'
              title={'Cancel Account Creation'}
              uppercase
              onClick={()=>this.toggleSignupModal(false)}
            />
          </Link>
            <Button
              title={'Create Account'}
              uppercase
              onClick={this.submitForm}
            />
            
        </div>
      </Modal>
      <button onClick={()=>this.toggleCancelModal(true)} className="cancel-button"><img src={LeftArrow} alt='arrow'/>Cancel Registration</button>
      <div className="section-header">
        <h2 className="section-title" dangerouslySetInnerHTML={{__html: SignUpFormData.title}}></h2>
      </div>
      <div className="container-box">
          {configData ? <React.Fragment>
          <div className="form-sections">
          <div className="form-section">
          <Input
            label={'First Name'}
            name='firstName'
            value={user.firstName}
            error={errors['firstName']}
            onChange={this.onChangeHandler}
            placeholder={'Enter Contact First Name'}
          />
          <Input
            label={'Last Name'}
            name='lastName'
            value={user.lastName}
            error={errors['lastName']}
            onChange={this.onChangeHandler}
            placeholder={'Enter Contact Last Name'}
          />
          <Input
            type='phone'
            label={'Business Mobile #'}
            name='phoneNum'
            value={user.phoneNum}
            error={errors['phoneNum']}
            onChange={this.onPhoneChange}
            placeholder={'Business Mobile #'}
          />
          <Input
            label={'Email'}
            type='email'
            name='email'
            value={user.email}
            onChange={this.onEmailChange}
            error={errors['email']}
            placeholder={'Business Email ID'}
          />
          <Input
            label={'Enter Password'}
            value={user.passwordOne}
            name='passwordOne'
            type='password'
            successMsg={passwordValid ? 'This password passes the requirements' : null}
            error={errors['passwordOne']}
            onChange={this.onPasswordChangeHandler}
            placeholder={'Set Password'}
            showExtra
          />
          <Input
            label={'Confirm Password'}
            value={user.passwordTwo}
            type='password'
            name='passwordTwo'
            error={errors['passwordTwo']}
            onChange={this.onPasswordChangeHandler}
            placeholder={'Re-enter Password'}
          />
          <Input 
                label={'Account Category'}
                type='dropdown'
                name='accountCategory'
                error={errors['accountCategory']}
                onChange={this.onCatChange}
                value={user.accountCategory ? {value:user.accountCategory.toLowerCase(), label :`I am a ${user.accountCategory.toLowerCase()}`} : null}
                options={[{value:'contractor', label :'I am a Contractor'},{value:'customer', label : 'I am a Customer'}]}
            />
            <Input
              label={'How did you hear about the program?'}
              type='dropdown'
              name='howDidYouHear'
              error={errors['howDidYouHear']}
              onChange={this.onHowDidYouHearChange}
              value={user.howDidYouHear ? {value:user.howDidYouHear, label :user.howDidYouHear} : null}
              options={howDidYouHearOptions.map((item)=>{
                return {label:item, value:item};
              })}
              disabled={user.accountCategory === ''}
              />
        </div>
        <div className='vertical-separator-box'>
            <span className='separator'></span>
        </div>
        <div className="form-section"> 
          <Input
            label={'Company Name'}
            name='companyName'
            onChangeText={<span>This is the legal name of the entity that will be bound to the <a rel="noreferrer" target="_blank" href="https://firebasestorage.googleapis.com/v0/b/dolphin-live-a906a.appspot.com/o/SPEED%20TermsAndConditions.pdf?alt=media&token=bf02d654-d692-4b34-936f-d64334a86aea">Terms and Conditions</a> of the SPEED program.</span>}
            value={user.companyName}
            error={errors['companyName']}
            onChange={this.onChangeHandler}
            placeholder={"Company's Legal Name"}
          />
              {/* <Input 
                label={'Is the company indigenous owned?'}
                type='dropdown'
                name='isIndigenousOwned'
                error={errors['isIndigenousOwned']}
                onChange={this.onindeginiousChange}
                value={user.isIndigenousOwned ? {value:user.isIndigenousOwned, label :user.isIndigenousOwned} : 'N/A'}
                options={isIndigenousOwnedOptions.map((item)=>{
                  return {label:item, value:item};
                })}
            /> */}
          <div className="q-dropdown">
            <Input
              label={'Is your business owned by someone from an under-represented group?'}
              type='dropdown'
              name='isOwnedBySomeoneFromURG'
              error={errors['isOwnedBySomeoneFromURG']}
              onChange={this.onBusinessOwnerChange}
              value={user.isOwnedBySomeoneFromURG ? {value:user.isOwnedBySomeoneFromURG.value, label :user.isOwnedBySomeoneFromURG.label} : null}
              options={isIndigenousOwnedOptions.map((item) => {
                return { label: item, value: item };
              })}
            />
            <span className="info-icon">
              <TooltipInfo
                position={"right"}
                helpText={
                  'Such as people of color, persons with disabilities, LGBTQ, Indigenous etc.'
                }
              />
            </span>
          </div>
          <Input
            label={'Company Website'}
            name='companyWebsite'
            value={user.companyWebsite}
            error={errors['companyWebsite']}
            onChange={this.onChangeHandler}
            placeholder={"Company's Website"}
          />
          <Input
            type='location'
            name='companyAddress'
            label={'Company Address'}
            error={errors['companyAddress']}
            onChange={this.onLocationChange}
            placeholder={'Company HQ Address Line 1'}
          />
          <Input
            label={'Address Line 2'}
            name='address2'
            value={user.address2}
            onChange={this.onChangeHandler}
            placeholder={'Company HQ Address Line 2'}
          />
           <Input
            type='postalCode'
            label={'Postal Code'}
            name='manualPostalCode'
            value={postalCode}
            onChange={this.onChangeHandler}
            placeholder={'Enter Postal Code'}
            error={errors['manualPostalCode']}
            onEdit={this.handlePostalCodeEdit}
            editable={postalCodeEditable}
            disabled
          />
          <Input
            label={'City'}
            value={user.locationInfo ? user.locationInfo.city : ''}
            onChange={this.onChangeHandler}
            placeholder={''}
            disabled
          />
          <Input
            label={'Province'}
            value={user.locationInfo ? user.locationInfo.province : ''}
            onChange={this.onChangeHandler}
            placeholder={''}
            disabled
          />
        </div>
          </div>
        { user.accountCategory ?
        <div className="checkboxes-section">
              <CheckBoxList
                onChange={this.onChecklistChangeHandler}
                errors={this.state.errors}
                items={
                  userTermsArray
                }
              />
        </div> : ''}
        </React.Fragment> : <Loader/>}
      </div>
      <div className="form-bottom">      
      <Button 
                title={'Complete Registration'}
                className='signup-btn'
                onClick={this.nextStep}
                uppercase
                disabled={thereAreErrors}
          />
      </div>
    </div></FrontendLayout>
  );
    }
  }
}

function mapState(state) {
    const { registering, registeredSuccessfully,registrationError } = state.registration;
    const { configData } = state.authentication;
    return { registering, registeredSuccessfully, registrationError, configData };
}

const actionCreators = {
    register: userActions.register,
    loadConfig : userActions.getAdminConfig
}

export default connect(mapState, actionCreators)(SignUp);
