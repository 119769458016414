import React from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../firebaseAuthContext';
import InternalLayout from '../../Common/InternalLayout';
import Button from '../../Common/Button/Button';
import Loader from '../../Common/Loader/Loader';
import './ApplicationShared.css';
import { applicationService } from '../../../services/applicationService';
import { userService } from '../../../services/userService';
class ApplicationShared extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      applicationId: props.match.params.appid,
      appData : null,
      loaded : false
    }
  }

  componentDidMount(){
    applicationService.getApplication(this.state.applicationId).then((appData)=>{
      this.setState({
        appData,
        loaded : true
      });
    });
  }

  render() {
    const { appData } = this.state;
    return <InternalLayout showHeader={true}>
      <div className="app-confirmation-wrapper-2">
        {this.state.loaded ? <React.Fragment>
          <h3>Application Update Shared!</h3>
          <p>We have notified <span>{appData.customerInfo.firstName}</span> that you have updated the application on his behalf for the Small Producer Energy Efficiency Deployment Program.</p>
          <p>The application ID# for your reference is <span>SPEED-{appData.appNo}</span>.</p>
          <p>You can view the status of your application at any time. You should expect an update from our team within the next two business days.</p>
          <div className="button-set">
          <Button
          title={'LOG OUT'}
          uppercase
          className="inversed-btn"
          onClick={userService.logout}
          />
          {/* <Link to="/account"> */}
          <Button
            title={'Go to Account Page'}
            uppercase
            onClick={()=>{window.location.href = '/account'}}
          />
          {/* </Link> */}
          </div>
        </React.Fragment> : <Loader/>}
      </div>
    </InternalLayout>
  }
}
export default ApplicationShared;
