import React, { useCallback } from "react";
import "./FileUpload.css";
import { utils } from "../../../helpers/utils";
import { ReactComponent as FileUploadVector } from "../../../images/FileUploadVector.svg";
import { ReactComponent as Loader } from "../../../images/Loader.svg";
import { ReactComponent as TrashIcon } from "../../../images/TrashIcon.svg";
import { ReactComponent as ViewIcon } from "../../../images/ViewIcon.svg";
import { applicationService } from "../../../services/applicationService";

import { useDropzone } from "react-dropzone";

const FileUpload = (props) => {
  const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
    if (fileRejections.length === 0) {
      acceptedFiles.length &&
        acceptedFiles.map(async (fileObj) => {
          setUploadState(true);
          const file = fileObj;

          if (!file) {
            return;
          }

          try {
            const scannedFile = await applicationService.virusScanForFileUploads(file, props.folderPath);
            const fileObj = {
              name: scannedFile[0].originalname,
              url: scannedFile[0].url,
              isInfected: scannedFile[0].isInfected,
            };

            setFileScanResult(scannedFile[0].isInfected);
            props.onChange(props.name, fileObj);
            setUploadState(false);
          } catch (e) {
            console.log(e);
            setUploadState(false);
          }
        });
    }
  }, []);

  const viewDocument = (document) => {
    window.open(document.url);
  };

  const [uploadState, setUploadState] = React.useState(false);
  const [isInfected, setFileScanResult] = React.useState(undefined);
  const maxSize = 52428800;
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    maxSize,
    accept: props.acceptedFileTypes ? props.acceptedFileTypes : ".jpg,.png,.doc,.jpeg,.png,.pdf,.docx,.xlsx",
    maxFiles: props.formType === "drawer" || props.folderPath === "verification-document" ? 1 : props.maxFiles || 0,
    disabled: uploadState,
  });
  const fileUploadErrorCode = fileRejections.length > 0 && fileRejections[0].errors ? fileRejections[0].errors[0].code : "";
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));
  return (
    <div className={`file-upload-container${uploadState ? " disabled-file-upload-container" : ""}`}>
      {props.formType === "drawer" || props.folderPath === "verification-document" ? (
        <div {...getRootProps({ className: "file-dropzone" })}>
          {props.value && props.value.name && !props.value.isInfected ? (
            <React.Fragment>
              <div className="document-name">{props.value.name}</div>
              {!props.disabled ? (
                <React.Fragment>
                  <div className="document-view" onClick={() => viewDocument(props.value)}>
                    <ViewIcon width="25" height="25" />
                  </div>
                  <div className="document-delete" onClick={() => props.onChange(props.name, {})}>
                    <TrashIcon width="20" height="20" />
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <input {...getInputProps()} />
              {uploadState ? (
                <div className="file-upload-loader-drawer">
                  <Loader />
                  <p>Scanning for virus...</p>
                </div>
              ) : (
                <FileUploadVector width="62" height="68" />
              )}
              <div className="message-container">
                {isInfected ? (
                  <React.Fragment>
                    <span className="drawer-error-message">
                      A file you are trying to upload seems to be infected. Please try again with a different file. If you feel the file is
                      clean, reach out to us on support@esbprogram.ca
                    </span>
                  </React.Fragment>
                ) : fileUploadErrorCode ? (
                  <React.Fragment>
                    <span className="drawer-error-message">
                      {fileUploadErrorCode === "file-too-large"
                        ? "Maximum allowed file size is 50 MB"
                        : fileUploadErrorCode === "too-many-files"
                        ? `You are trying to uplaod more than the maximum number(${props.maxFiles || 1}) allowed files.`
                        : fileUploadErrorCode === "file-invalid-type"
                        ? "The file you are trying to upload is not of an acceptable file type. Please try again."
                        : ""}
                    </span>
                  </React.Fragment>
                ) : (
                  ""
                )}
                {uploadState
                  ? ""
                  : props.placeholder || (
                      <p>
                        Select from computer (or) Drag and Drop <span>documents</span> to upload. Acceptable file types: .JPEG, .PNG, .PDF,
                        .Docx, .xlsx
                      </p>
                    )}
              </div>
            </React.Fragment>
          )}
        </div>
      ) : (
        <div {...getRootProps({ className: "file-dropzone" })}>
          <input {...getInputProps()} />
          {uploadState ? (
            <div className="file-upload-loader">
              <Loader />
              <p>Scanning for virus...</p>
            </div>
          ) : (
            <FileUploadVector width="62" height="68" />
          )}
          {isInfected ? (
            <React.Fragment>
              <span className="drawer-error-message">
                A file you are trying to upload seems to be infected. Please try again with a different file. If you feel the file is clean,
                reach out to us on support@esbprogram.ca
              </span>
            </React.Fragment>
          ) : fileUploadErrorCode ? (
            <React.Fragment>
              <span className="drawer-error-message">
                {fileUploadErrorCode === "file-too-large"
                  ? "Maximum allowed file size is 50 MB"
                  : fileUploadErrorCode === "too-many-files"
                  ? `You are trying to uplaod more than the maximum number(${props.maxFiles || 1}) allowed files.`
                  : fileUploadErrorCode === "file-invalid-type"
                  ? "The file you are trying to upload is not of an acceptable file type. Please try again."
                  : ""}
              </span>
            </React.Fragment>
          ) : (
            ""
          )}
          {uploadState
            ? ""
            : props.placeholder || (
                <p>
                  Select from computer (or) Drag and Drop <span>documents</span> to upload. Acceptable file types: .JPEG, .PNG, .PDF, .Docx
                </p>
              )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;

// The value must be a comma-separated list of unique content type specifiers:

// A file extension starting with the STOP character (U+002E). (e.g. .jpg, .png, .doc).
// A valid MIME type with no extensions.
// audio/* representing sound files.
// video/* representing video files.
// image/* representing image files.
// For more information see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Input
