import React from "react";
import "./ApplicationOverviewCard.css";
import Input from "../Input/Input";
import Modal from "../Modal/Modal";
import Button from "../../Common/Button/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { utils } from "../../../helpers/utils";
import FilterDropdown from "../FilterDropdown/FilterDropdown";
import SearchInput from "../SearchInput/SearchInput";
import TooltipInfo from '../../Common/TooltipInfo/TooltipInfo';
import { applicationService } from "../../../services/applicationService";
import ChangeRequestIcon from "../../../images/ChangeRequest.svg";
import PostProjectReview from "../PostProjectReview/PostProjectReview";
import MatTabs from "../MatTabs/MatTabs";
import LoaderComponent from "../Loader/Loader";
import PayoutInitiated from "../../../images/PayoutInitiated.svg";
import firebase from "../../../helpers/Firebase";
import ResponsiveTable from "../../Common/ResponsiveTable/ResponsiveTable";
const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#048041",
    color: "#FFF",
    maxWidth: 200,
  },
  text: {
    color: "#000000",
  },
  arrow: {
    color: "#048041",
  },
}))(Tooltip);

const perPage = 4;

class ApplicationOverviewCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPerPage: [],
      paginationCount: 0,
      errors: {},
      page: 1,
      //preapprovedApps: props.preapprovedApps || [],
      applicationList: props.applicationList || [],
      applicationListByStatus: props.applicationListByStatus || [],
      openModal: false,
      startPostProjectStep: 1,
      openPostApplicationModal: false,
      openReactivateApplication: false,
      openPaymentStatusesIndexModal: false,
      selectedAppID: "",
      selectedAppNO: "",
      openRequestModal: false,
      SelectedFilters: [],
      searchInput: "",
      changeRequestModalTitle: "REQUEST A CHANGE IN A SUBMITTED APPLICATION",
      changeRequestModalState: "1",
      changeRequestRefrenceNo: "",
      changeRequestSubmiting: false,
      changeRequestModalSize: "large",
      newApplicationType: "0",
      postProjectAppIDSelection: null,
      userId:'',
      changeRequestObj: {
        changeRequestType: { value: `Extension-Deadline`, label: `Extension to Deadline` },
        description: "",
        program : 'speed',
      },
      startAppTypes: [
        { value: 1, label: `START New Application` },
        { value: 2, label: "START Post-project" },
      ],
      hideInactive: false,
    };
  }

  resetModal = () => {
    this.setState({
      openRequestModal: false,
      changeRequestObj: {
        changeRequestType: { value: `Extension-Deadline`, label: `Extension to Deadline` },
        description: "",
      },
    });
  };

  componentDidMount() {
    this.SearchAndFilter(this.state.searchInput, this.state.SelectedFilters);
    const { uid } = firebase.auth.currentUser;
    this.setState({userId:uid})
    this.props.onRef(this);
    // this.props.loadUserDetails(uid);
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.applicationList !== this.props.applicationList) {
      this.SearchAndFilter(this.state.searchInput, this.state.SelectedFilters);
    }
  }
  handlenewAppChange = (data) => {
    this.setState({
      newApplicationType: data.value,
    });
    if (data.value === 1) {
      this.setOpenModal(true);
    }
    // else if (data.value === 2){
    //   this.setOpenChangeRequestModal(true);
    // }
    else {
      this.setOpenPOSTApplicationModal(true);
    }
  };
  handleChange = (event, value) => {
    let dataPerPage = this.state.applicationList.slice(value * perPage - perPage, perPage * value);
    this.setState({
      dataPerPage,
      page: value,
    });
  };
  handlePostProjectAppIdChange = (data) => {
    //alert(JSON.stringify(data));
    this.setState({
      postProjectAppIDSelection: data,
    });
  };

  setOpenModal = (status) => {
    this.setState({
      openModal: status,
    });
  };

  setOpenPOSTApplicationModal = (status, appid = "", appno = "") => {
    this.setState({
      openPostApplicationModal: status,
      selectedAppID: appid,
      selectedAppNO: appno,
      startPostProjectStep: 1,
    });
  };

  setRequestChangeOpenModal = (status, appid = null) => {
    this.setState({
      openRequestModal: status,
      changeRequestSubmiting: true,
    });
    let { changeRequestObj } = this.state;
    if (status === true) {
      try {
        applicationService.getApplication(appid).then(async (data) => {
          // console.log(data);
          this.setState({
            changeRequestObj: { ...changeRequestObj, ...data, ...{ id: appid } },
            changeRequestSubmiting: false,
          });
        });
      } catch (err) {}
    } else {
      this.toggleChangeRequest("1");
    }
  };

 
  submitChangeRequest = () => {
    let { changeRequestObj } = this.state;
    let { AccountDetails } = this.props;
    let data = {
      applicationId: changeRequestObj.id, // Change it to application id
      appNo: changeRequestObj.appNo,
      companyName: changeRequestObj.companyName,
      requestType: changeRequestObj.changeRequestType,
      description: changeRequestObj.description,
      actionTaker: `${AccountDetails.firstName} ${AccountDetails.lastName}`,
      program : changeRequestObj.program
    };
    this.setState(
      {
        changeRequestSubmiting: true,
      },
      async () => {
        const result = await applicationService.createChangeRequest(data);
        this.setState({
          changeRequestSubmiting: false,
          changeRequestRefrenceNo: `${result.changeReqNo}`,
        });
        this.toggleChangeRequest("3");
      }
    );
  };
  onChangeHandler = (name, value) => {
    //console.log(name+">>>>"+value);
    const { changeRequestObj, errors } = this.state;
    delete errors[name];
    this.setState({
      changeRequestObj: {
        ...changeRequestObj,
        [name]: value,
      },
      errors,
    });
  };
  onRequestChange = (name, stage) => {
    const { changeRequestObj, errors } = this.state;
    this.setState({
      changeRequestObj: {
        ...changeRequestObj,
        changeRequestType: stage,
      },
      errors,
    });
  };

  verifyForm = () => {
    let errors = {};
    let errorCount = 0;
    const { changeRequestObj } = this.state;
    if (utils.isNullOrEmpty(changeRequestObj.description)) {
      errors.description = "A description of the change request is required";
      errorCount++;
    }
    if (errorCount > 0) {
      this.setState({ errors }, () => {
        let allErrorRefs = document.querySelectorAll(".input-box-error,.error-box");
        if (allErrorRefs.length > 0) {
          let firstErrorRef = allErrorRefs[0];
          firstErrorRef.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      });
    }
    return errorCount === 0;
  };

  toggleChangeRequest = (type) => {
    if (type === "1") {
      this.setState({
        changeRequestModalState: type,
        changeRequestModalTitle: "REQUEST A CHANGE IN A SUBMITTED APPLICATION",
        changeRequestModalSize: "large",
        changeRequestRefrenceNo: "",
      });
    } else if (type === "2") {
      if (this.verifyForm()) {
        this.setState({ changeRequestModalState: type, changeRequestModalTitle: "SUBMIT CHANGE REQUEST", changeRequestModalSize: "" });
      }
    } else {
      this.setState({
        changeRequestModalState: type,
        changeRequestModalTitle: "CHANGE REQUEST SUBMITTED",
        changeRequestModalSize: "",
        changeRequestObj: { changeRequestType: { value: `Extension-Deadline`, label: `Extension to Deadline` }, description: "" },
      });
    }
  };
  sortData = (property, columnType) => {
    const { applicationList } = this.state;
    if (applicationList) {
      this.setState(
        {
          applicationList: applicationList.sort(utils.sortData(property, "desc", columnType)),
        },
        () => {
          this.setPagination();
        }
      );
    }
  };

  onFilterChange = (SelectedFilters) => {
    this.SearchAndFilter(this.state.searchInput, SelectedFilters);
  };

  onSearch = (searchInput) => {
    this.SearchAndFilter(searchInput, this.state.SelectedFilters);
  };

  SearchAndFilter = (searchInput, SelectedFilters) => {
    const { applicationList } = this.props;
    const { hideInactive } = this.state;
    let FilteredList = [];
    if (SelectedFilters.length) {
      this.setState(
        {
          SelectedFilters
        },
        () => {
          SelectedFilters.map((filter) => {
            let ArrList = [];
            ArrList = applicationList.filter((application) => {
              if(hideInactive){
                return application.status === filter && application.isActive === true;
              }  else {
                return application.status === filter;
              }
            });
            FilteredList.push(...ArrList);
          });

          if (searchInput) {
            let SearchPattern = new RegExp("(\\w*" + searchInput + "\\w*)", "gi");
            FilteredList = FilteredList.filter((application) => {
                return (application.appNo && application.appNo.toString().match(SearchPattern)) ||
                `SPEED-${application.appNo.toString()}`.match(SearchPattern) ||
                application.companyName.match(SearchPattern) ||
                application.projectName.match(SearchPattern) ||
                application.stage.match(SearchPattern) ||
                application.status.match(SearchPattern) ||
                (application.contractor && application.contractor.label.match(SearchPattern));
            });
          }

          this.setState(
            {
              applicationList: FilteredList,
              searchInput,
            },
            () => {
              this.sortData("createdAt", "date");
            }
          );
        }
      );
    } else {
      if (searchInput) {
        let SearchPattern = new RegExp("(\\w*" + searchInput + "\\w*)", "gi");
        FilteredList = applicationList.filter((application) => {

          let matchesSearch = ((application.appNo && `${application.appNo}`.match(SearchPattern)) ||
            `SPEED-${application.appNo}`.match(SearchPattern) ||
            application.companyName.match(SearchPattern) ||
            application.projectName.match(SearchPattern) ||
            application.stage.match(SearchPattern) ||
            application.status.match(SearchPattern) ||
            (application.contractor && application.contractor.label.match(SearchPattern))
          );
          if(hideInactive){
            return matchesSearch && application.isActive === true;
          }  else {
            return matchesSearch;
          }
        });
      } else {
        FilteredList = (applicationList || []).filter((app)=>{
          if(hideInactive){
            return app.isActive === true;
          }  else {
            return true;
          }
        });
      }
      this.setState(
        {
          applicationList: FilteredList,
          SelectedFilters: [],
          searchInput,
        },
        () => {
          this.sortData("createdAt", "date");
        }
      );
    }
  };

  setPagination = () => {
    let ApplicationList = this.state.applicationList || [];
    const paginationCount = Math.ceil(ApplicationList.length / 4);
    let firstPage = ApplicationList.slice(0, perPage);
    this.setState({
      page: 1,
      dataPerPage: firstPage,
      paginationCount,
    });
  };

  onStateChange = (name, value) => {
    this.setState({hideInactive: !value},
      ()=> {
        this.SearchAndFilter(this.state.searchInput, this.state.SelectedFilters);
      }
      );
  }

  resetFilters = () =>{
    this.setState({
      hideInactive : false,
      SelectedFilters: [],
      searchInput: "",
    })
  }

  renderViewDetailsIcon = (id) => {
    return (
      <HtmlTooltip title={"View Application"} placement="right" arrow>
        <span>
          <a className="view-details-icon" href={`/viewdetails/${id}`}>
            <svg viewBox="0 0 51 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.114 22.454C11.351 26.035 17.918 30.304 25.142 30.304C37.394 30.304 47.845 18.2 50.284 15.15C48.1473 12.4986 45.7654 10.0545 43.17 7.85C38.933 4.268 32.366 0 25.142 0C12.89 0 2.439 12.102 0 15.154C2.13648 17.8056 4.51836 20.2498 7.114 22.454ZM18.1797 4.73368C20.2402 3.35687 22.6628 2.62199 25.141 2.62199C28.4642 2.62199 31.6512 3.94212 34.0011 6.29195C36.3509 8.64178 37.671 11.8288 37.671 15.152C37.671 17.6302 36.9361 20.0527 35.5593 22.1133C34.1825 24.1738 32.2256 25.7798 29.936 26.7282C27.6465 27.6766 25.1271 27.9247 22.6965 27.4412C20.2659 26.9578 18.0333 25.7644 16.281 24.012C14.5286 22.2597 13.3352 20.0271 12.8518 17.5965C12.3683 15.1659 12.6164 12.6465 13.5648 10.357C14.5132 8.06741 16.1192 6.11049 18.1797 4.73368ZM25.141 25.337C23.1264 25.337 21.157 24.7396 19.4819 23.6203C17.8068 22.501 16.5012 20.9101 15.7303 19.0488C14.9593 17.1875 14.7577 15.1394 15.1508 13.1634C15.5439 11.1875 16.5141 9.37255 17.9388 7.94805C19.3634 6.52355 21.1785 5.55351 23.1544 5.16059C25.1304 4.76768 27.1785 4.96953 29.0397 5.74064C30.9009 6.51175 32.4917 7.81747 33.6108 9.49268C34.73 11.1679 35.3272 13.1373 35.327 15.152C35.3271 16.4897 35.0638 17.8143 34.5519 19.0502C34.0401 20.286 33.2898 21.409 32.3439 22.3549C31.398 23.3008 30.275 24.051 29.0392 24.5629C27.8033 25.0747 26.4787 25.3371 25.141 25.337Z"
                fill="currentColor"
              />
            </svg>
          </a>
        </span>
      </HtmlTooltip>
    );
  };


  renderChangeReqIcon = (id) => {
    return (
      <HtmlTooltip title={"Submit Change Request"} placement="right" arrow>
        <span>
          <img
            title={"Submit Change Request"}
            className="change-request-icon"
            alt="change request"
            onClick={() => this.setRequestChangeOpenModal(true, id)}
            src={ChangeRequestIcon}
          />
        </span>
      </HtmlTooltip>
    );
  };

  renderPaymentInitatedIcon= (application) => {
   
    return (
      <div className="payout-icon">
        {/* <img alt="You are assigned as Payee of the application" src={PayoutInitiated}/> */}
      
      <TooltipInfo 
      helpText={"You are assigned as Payee of the application"}
      className={'intitiate-payment-icon'}
      customIcon={PayoutInitiated}
      />
      <div className="application-card-row card-header">#SPEED-{application.appNo}</div>
      </div>
    );
  };




  renderInitialState = (changeRequestObj) => {
    const { errors } = this.state;
    return (
      <React.Fragment>
        <div className="container-box">
          <div className="form-sections">
            <div className="form-inner-section">
              <div className="form-inner-elements">
                <div className="application-form-label">Application ID</div>
                <div className="application-form-element">{changeRequestObj.name || `SPEED-${changeRequestObj.appNo}`}</div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Project Name</div>
                <div className="application-form-element">{changeRequestObj.projectName}</div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Contractor Name</div>
                <div className="application-form-element">{changeRequestObj.contractor ? changeRequestObj.contractor.label : ""}</div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Project Completion Deadline </div>
                <div className="application-form-element">{changeRequestObj["Project Completion Deadline"] || "N/A"}</div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Pre-Project Reviewer Estimated Incentive</div>
                <div className="application-form-element">
                  {utils.formatAsCAD(changeRequestObj["Reviewer Estimated Incentive"] || "0.0")}
                </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Change Request Type</div>
                <div className="application-form-element">
                  <Input
                    type="dropdown"
                    name="changeRequestType"
                    onChange={this.onRequestChange}
                    value={changeRequestObj.changeRequestType}
                    options={[
                      { value: `Extension-Deadline`, label: `Extension to Deadline` },
                      { value: "Inclusion-Additional-Measures", label: "Inclusion of Additional Measures" },
                      { value: "Measure-QTY-Size-Changes", label: "Changes in Measure Quantities or Sizes" },
                      { value: "other", label: "Other" },
                    ]}
                    placeholder={"Select View"}
                  />
                </div>
              </div>
              <div className="form-inner-elements">
              <div className="application-form-label"></div>
              <div className="application-form-element">
              <p className="extension-text">If you are requesting an extension to the project completion deadline, please include the revised project completion date in the Change Request Description to ensure a quick response to your request from the program support team</p>
              </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Change Request Description</div>
                <div className="application-form-element">
                  <Input
                    name="description"
                    placeholder={"Please write your change request description here"}
                    info={""}
                    type="textarea"
                    value={changeRequestObj.description}
                    error={errors["description"]}
                    onChange={this.onChangeHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="button-set">
          <Button title={"SUBMIT CHANGE REQUEST"} uppercase onClick={() => this.toggleChangeRequest("2")} />
        </div>
      </React.Fragment>
    );
  };

  reactivateApplication = (status, appid = '', appno = '') => {
    this.setState ({
      openReactivateApplication : status,
      selectedAppID: appid,
      selectedAppNO: appno,
    })
  }

  setOpenPaymentStatusesIndexModal = () => {
    const {openPaymentStatusesIndexModal} = this.state;
   this. setState({
     openPaymentStatusesIndexModal: !openPaymentStatusesIndexModal,
   })
 }

  render() {
    const { AccountDetails } = this.props;
    const {
      paginationCount,
      dataPerPage,
      page,
      openModal,
      openRequestModal,
      SelectedFilters,
      searchInput,
      changeRequestObj,
      changeRequestModalState,
      changeRequestModalTitle,
      changeRequestRefrenceNo,
      changeRequestSubmiting,
      changeRequestModalSize,
      openPostApplicationModal,
      openReactivateApplication,
      selectedAppID,
      selectedAppNO,
      // preapprovedApps,
      postProjectAppIDSelection,
      startAppTypes,
      hideInactive,
      openPaymentStatusesIndexModal,
    } = this.state;
    const { loadingApplications } = this.props;
    const accountCategory = AccountDetails.accountCategory.toLowerCase();
    const isContractor = AccountDetails.accountCategory.toLowerCase() === "contractor";
    const TooltipTextBeforeParticipating =
      "To be able to start a new application, share all the required information by clicking 'Complete Details' above.";
    const TooltipTextAfterParticipating = "Click here to start a new application.";

    let postProjectNotice = isContractor
      ? "If your customer's pre-project application has been approved and they have completed their Participant Acceptance, please note that the post-project application will be available to them soon. You can start helping them prepare for it by making sure the invoices, cost substantiation and other documents such as permits and approvals are available for upload."
      : "If your pre-project application has been approved and you have completed your Participant Acceptance, please note that your post-project application will be unlocked soon. You can start preparing for it by making sure you have your invoices, cost substantiation and other documents such as permits and approvals.";
    // const applicationStatusColorHash = {
    //   "Pre-ProjectApplication Draft": "draft-card",
    //   "Pre-ProjectApplication Draft Submitted": "draft-submitted-card",
    //   "Pre-ProjectApplication Draft Under Review": "draft-under-review",
    //   "Pre-ProjectQA/QC Required": "qaqc-card",
    //   "Pre-ProjectApplication Information Requested": "application-info-card",
    //   "Pre-ProjectApplication Rejected": "app-rejected-card",
    //   "Pre-ApprovalApplication Draft Approved": "app-draft-approved",
    //   "Pre-ApprovalApplication Submitted": "app-submitted",
    //   "Post-ProjectApplication Draft": "draft-card",
    //   "Post-ProjectApplication Submitted": "app-submitted",
    //   "Post-ProjectApplication Under Review": "draft-under-review",//verify
    //   "Post-ProjectQA/QC In Progress": "qaqc-card",
    //   "Post-ProjectApplication Information Requested": "application-info-card",
    //   "Post-ProjectApplication Rejected": "app-rejected-card",
    //   "PaymentIncentive Recommended": "app-draft-approved",//verify
    // };

    let FilterOptions = this.props.appStatuses;

    let preloadApp = null;
    if (selectedAppID && selectedAppNO) {
      preloadApp = {
        label: `#SPEED-${selectedAppNO}`,
        value: selectedAppID,
      };
    }

    let statusesIndexHeadings = [
      { title : 'Application Stage', value : 'stage'},
      { title : 'Application Status', value : 'status'},
      { title : 'Description', value : 'trigger'},
    ];

    let statusesIndexRows = [
      { stage: "Payment", status: "Incentive Recommended", trigger: 'Application Review Team has approved application and recommended incentive payment.'},
      { stage: "Payment", status: "Banking Details Pending", trigger: 'Application has been sent to Payee to enter the banking details for payment of the incentive.itted verification document.'},
      { stage: "Payment", status: "Banking Details In Review", trigger: 'The banking details submitted by the Payee are reviewed for accuracy against the submitted verification document. This can take 1-2 weeks.'},
      { stage: "Payment", status: "Revise Banking Details", trigger: 'Payee is requested to revise the banking details to align with the submitted verification document.'},
      { stage: "Payment", status: "Incentive Recommendation Approval Pending", trigger: 'Enerva management has approved incentive payment recommendation and has sent it to ERA for further approval. This can take 2-4 weeks.'},
      { stage: "Payment", status: "	Incentive Approved", trigger: 'ERA has approved the incentive payment recommendation. This can take 2 weeks.'},
      { stage: "Payment", status: "Incentive Payment in Progress", trigger: 'Enerva has initiated the incentive payment transfer to the bank account provided by the Payee. This can take 1-5 days.'},
      { stage: "Payment", status: "Incentive Payment Completed", trigger: 'Enerva has received confirmation from the Payee or the Bank that the incentive has been successfully transferred. This can take up to 2-3 months since it is done in batches.'},
    ];

    return (
      <div className={`app-overview-container`}>
        <Modal HeaderText={"START A NEW APPLICATION"} IsOpen={openModal} handleCloseModal={() => this.setOpenModal(false)}>
          <div>
            <p>This will start a new application process.</p>
            <p>Are you sure?</p>
            <div className="button-set">
              <Button title={"GO BACK"} uppercase className="inversed-btn" onClick={() => this.setOpenModal(false)} />
              <Link to="/application/new">
                <Button title={"START NEW APPLICATION"} uppercase />
              </Link>
            </div>
          </div>
        </Modal>
        <Modal
          HeaderText={"START POST-PROJECT APPLICATION"}
          IsOpen={openPostApplicationModal}
          handleCloseModal={() => this.setOpenPOSTApplicationModal(false)}
        >
          <PostProjectReview
            history={this.props.history}
            preloadApp={preloadApp}
            closeModal={() => this.setOpenPOSTApplicationModal(false)}
          />
        </Modal>
        <Modal
          HeaderText={"REACTIVATE APPLICATION"}
          IsOpen={openReactivateApplication}
          handleCloseModal={() => this.reactivateApplication(false)}
        >
          <div className="reactivate-app">
              <p>Your application <span>#SPEED-{selectedAppNO}</span> has been marked as 'Inactive' by the SPEED Program Team.{" "}
                If you wish to reactivate the application, please email your request to <span><a href="mailto:speed@esbprogram.ca">speed@esbprogram.ca</a></span>
              </p>
              <Button title={"Go Back"} uppercase onClick={() => this.reactivateApplication(false)} />
            </div>
        </Modal>
        <Modal
          HeaderText={changeRequestModalTitle}
          loading={changeRequestSubmiting}
          IsOpen={openRequestModal}
          modalSize={changeRequestModalSize}
          handleCloseModal={() => this.resetModal()}
        >
          {changeRequestModalState === "1" ? this.renderInitialState(changeRequestObj) : ""}
          {changeRequestModalState === "2" ? (
            <React.Fragment>
              <p>This will submit your change request. Are you sure?</p>
              <div className="button-set">
                <Button className="inversed-btn" title={"Edit Request"} uppercase onClick={() => this.toggleChangeRequest("1")} />
                <Button
                  onClick={() => {
                    this.submitChangeRequest();
                  }}
                  title={"Confirm"}
                  uppercase
                />
              </div>
            </React.Fragment>
          ) : (
            ""
          )}
          {changeRequestModalState === "3" ? (
            <React.Fragment>
              <p>Your change request with ID #CR-{changeRequestRefrenceNo} has been successfully submitted.</p>
              <p>The SPEED Program Team will be reviewing your request and getting back to you within 5 business days with an update.</p>
              <div className="button-set">
                <Button title={"GO BACK TO ACCOUNT PAGE"} uppercase onClick={() => this.setRequestChangeOpenModal(false)} />
              </div>
            </React.Fragment>
          ) : (
            ""
          )}
        </Modal>
        <Modal
          HeaderText={"Definitions of Payment Statuses"}
          IsOpen={openPaymentStatusesIndexModal}
          handleCloseModal={() => this.setOpenPaymentStatusesIndexModal(false)}
          modalSize="large"
        >
         <div className="payment-statuses-index">
           <p>Once your application reaches 'Incentive Recommended' status, it can take upto 4-6 weeks to receive your payment.</p>
           <ResponsiveTable rows={statusesIndexRows} headings={statusesIndexHeadings}/>
          </div>
        </Modal>
        <div className="app-overview-top">
          <div className="applications-overview-left">
            <h3>Applications Overview</h3>
            <div className="separator seperator-tall"></div>
            <p className="statuses-index" onClick={() => this.setOpenPaymentStatusesIndexModal()}>Payment Status Table*</p>
          </div>
          <div className="tab-section">
          <MatTabs
              onChange={(stage)=>{
                this.resetFilters();
                this.props.onStageChange(stage);
              }}
              className={"app-stage-tabs"}
              value={this.props.currentStage}
              options={["Pre-Project", "Pre-Approval", "Post-Project", "Payment"]}
            />
          </div>
        </div>
        <div className="app-overview-bottom">
          <div className="app-overview-bottom-top-bar">
            <div className="app-overview-bottom-search">
              <SearchInput value={this.state.searchInput} placeholder="Search for Applications" onChange={this.onSearch} />
            </div>
            {loadingApplications ? (
              ""
            ) : (
              <div className="app-overview-bottom-filter">
                <FilterDropdown
                  FilterOptions={FilterOptions}
                  SelectedFilters={SelectedFilters}
                  onChange={this.onFilterChange}
                  className={"program-filter"}
                />
              </div>
            )}
            {this.props.currentStage === "Payment" ? "" : loadingApplications ? 
              "" 
              :
              <div className="app-overview-bottom-appState">
                <div className="appstate-input">
                  <Input
                    type="switch"
                    name="appState"
                    checked={ hideInactive ? false : true }
                    onChange={this.onStateChange}
                  />
                </div>
                <div className="appstate-label">{hideInactive ? "HIDE INACTIVE APPLICATIONS" : "SHOW ALL APPLICATIONS"}</div>
              </div>
            }
          </div>
          {loadingApplications ? (
            <LoaderComponent />
          ) : (
            <React.Fragment>
              {dataPerPage.length ? (
                <React.Fragment>
                  <div className="application-card-container">
                    {dataPerPage.map((application, index) => {
                      let link = `/viewapplication/${application.id}`;
                      let linkText = "View Details";
                      let draftApprovedOrSubmitted = false;
                      let showActionButton = false;
                      let showViewDetails = true;
                      let showChangeRequest = false;
                      let showPostProjectButton = false;
                      let showPaymentPin = false;
                      let projectCompletionDeadline = application["Project Completion Deadline"];
                      let numberOfDaysTillDeadline = utils.getDaysFromToday(projectCompletionDeadline);
                      let cardColor = "";
                      let statusArray = [];
                     
                      if (this.props.statusConfig) {
                        statusArray = this.props.statusConfig[application.stage];
                      }
                      for (let item of statusArray) {
                        if (item.status === application.status) {
                          cardColor = item.colorCode;
                        }
                      }

                      if (application.status === "Application Draft") {
                        link = `/application/${application.id}`;
                        linkText = "Complete Application";
                        showActionButton = application.stage === "Post-Project" ? true : false;
                        showViewDetails = false;
                      } else if (application.status === "Application Information Requested") {
                        linkText = "Information Requested";
                        showActionButton = true;
                      } else if (application.status === "Application Draft Approved") {
                        linkText = "Submit Application";
                        showActionButton = true;
                        draftApprovedOrSubmitted = true;
                      } else if (application.status === "Application Submitted") {
                        linkText = "View Details";
                        draftApprovedOrSubmitted = true;
                      }
                      if (application.stage === "Pre-Approval" && application.status === "Application Submitted") {
                        showChangeRequest = true;
                        showPostProjectButton = true;
                      }
                     
                      // if (numberOfDaysTillDeadline < 30) {
                      //   showChangeRequest = false;
                      // }

                      if (isContractor) {
                        showViewDetails = application.contractorCanEdit ? showViewDetails : false;
                        showChangeRequest = false;
                        showPostProjectButton = false;
                      }

                      if(AccountDetails.status.toLowerCase() === "suspended" || AccountDetails.status.toLowerCase() === "inactive"){
                        showActionButton = false;
                        showChangeRequest = false;
                        showPostProjectButton = false;
                      }

                      return (
                        <div key={`${application.id}-card`} className={`application-card`} style={{ backgroundColor: application.hasOwnProperty("isActive") && application.isActive === false ? "#e3ddda" : cardColor }}>
                          {(application.payeeAssigned  && application.payeeAssigned ===true && application.payeeInfo.uid===this.state.userId) ?   
                            this.renderPaymentInitatedIcon(application):
                          <div className="application-card-row card-header">#SPEED-{application.appNo}</div> }
                          {isContractor ? (
                            <div className="application-card-row">
                              <label>Company Name :</label>
                              <span>{application.companyName ? application.companyName : ""}</span>
                            </div>
                          ) : (
                            <div className="application-card-row">
                              <label>Contractor Name :</label>
                              <span>{application.contractor ? application.contractor.label : ""}</span>
                            </div>
                          )}
                          <div className="application-card-row">
                            <label>Application Name:</label>
                            <span>{application.projectName || `#SPEED-${application.appNo}`}</span>
                          </div>
                          <div className="application-card-row">
                            <label>Stage :</label>
                            <span>{application.stage}</span>
                          </div>
                          <div className="application-card-row">
                            <label>Status :</label>
                            <span>{application.status}</span>
                          </div>
                          {application.submissionDate ? (
                            <div className="application-card-row">
                              <label>Submission Date :</label>
                              <span>{application.submissionDate}</span>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="card-icon-set">
                            {showChangeRequest && AccountDetails.status.toLowerCase() !== "suspended" && AccountDetails.status.toLowerCase() !== "inactive" ? this.renderChangeReqIcon(application.id) : ""}
                            {showViewDetails ? this.renderViewDetailsIcon(application.id) : ""}
                           
                          </div>
                          {showActionButton && (!application.hasOwnProperty("isActive") || application.isActive === true) &&
                          ((isContractor && application.contractorCanEdit && !draftApprovedOrSubmitted) || !isContractor) ? (
                            <div className="application-card-row application-link">
                              <span>
                                <Link to={link ? link : ''}>
                                  {linkText}
                                  <svg
                                    className="right-arrow"
                                    width="7"
                                    height="10"
                                    viewBox="0 0 7 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 0.999993L5.25 4.77799L1 8.55499"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </Link>
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          {(application.stage === "Pre-Project" || application.stage === "Pre-Approval" || application.stage === "Post-Project") && application.isActive === false && AccountDetails.status.toLowerCase() !== "suspended" && AccountDetails.status.toLowerCase() !== "inactive"? (
                            <div className="application-card-row application-link">
                              <span>
                                <Link onClick={() => this.reactivateApplication(true, application.id, application.appNo)}>
                                  Reactivate Application
                                  <svg
                                    className="right-arrow"
                                    width="7"
                                    height="10"
                                    viewBox="0 0 7 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 0.999993L5.25 4.77799L1 8.55499"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </Link>
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          {showPostProjectButton && application.isActive === true ? (
                            <div className="application-card-row application-link">
                              <span>
                                {(AccountDetails.status.toLowerCase() !== "suspended" && AccountDetails.status.toLowerCase() !== "inactive" ? <Link onClick={() => this.setOpenPOSTApplicationModal(true, application.id, application.appNo)}>
                                  Start Post-Project
                                  <svg
                                    className="right-arrow"
                                    width="7"
                                    height="10"
                                    viewBox="0 0 7 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 0.999993L5.25 4.77799L1 8.55499"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </Link> : "")}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                           {(application.stage === "Payment" && application.status === "Banking Details Pending"  && (application.payeeAssigned  && application.payeeAssigned ===true && application.payeeInfo.uid===this.state.userId && AccountDetails.status.toLowerCase() !== "suspended" && AccountDetails.status.toLowerCase() !== "inactive")) ? (
                                <div className="application-card-row application-link">
                                  <span onClick={() => this.props.setBankingDetailsModalOpen(true, application, "Share Banking Details")}>
                                      Share Banking Details 
                                      <svg
                                        className="right-arrow"
                                        width="7"
                                        height="10"
                                        viewBox="0 0 7 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 0.999993L5.25 4.77799L1 8.55499"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                  </span>
                                </div>
                            ):''}
                            {(application.stage === "Payment" && application.status === "Revise Banking Details" && (application.payeeAssigned  && application.payeeAssigned ===true && application.payeeInfo.uid===this.state.userId)) ? (
                                <div className="application-card-row application-link">
                                  <span onClick={() => this.props.setBankingDetailsModalOpen(true, application, "Revise Banking Details")}>
                                      Revise Shared Details 
                                      <svg
                                        className="right-arrow"
                                        width="7"
                                        height="10"
                                        viewBox="0 0 7 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 0.999993L5.25 4.77799L1 8.55499"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                  </span>
                                </div>
                              ):''}
                        </div>
                      );
                    })}
                  </div>
                  <div className="pagination-container">
                    <Pagination count={paginationCount} page={page} onChange={this.handleChange} />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {searchInput || SelectedFilters.length ? (
                    <p>There are no applications with that keyword. Please try searching again.</p>
                  ) : isContractor ? (
                    <p>
                      There are no applications in progress. Once a customer adds you to an application, you can view the progress and make
                      changes to the applications before submission here.
                    </p>
                  ) : (
                    <p>
                      There are no applications in progress. Click on <span>'Start New Application'</span> and view the progress of your
                      applications and incentives here.
                    </p>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
        {/* )} */}
      </div>
    );
  }
}

export default ApplicationOverviewCard;