import React from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../firebaseAuthContext';
import InternalLayout from '../../Common/InternalLayout';
import Button from '../../Common/Button/Button';
import Loader from '../../Common/Loader/Loader';
import './ApplicationSentForReview.css';
import { applicationService } from '../../../services/applicationService';
import { userService } from '../../../services/userService';
class ApplicationSentForReview extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      applicationId: props.match.params.appid,
      appData : null,
      loaded : false    
    }
  }

  componentDidMount(){
    applicationService.getApplication(this.state.applicationId).then((appData)=>{
      this.setState({
        appData,
        loaded : true
      });
    });
  }

  render() {
    const { appData } = this.state;
    return <InternalLayout showHeader={true}>
      <div className="app-confirmation-wrapper-1">
      {this.state.loaded ? <React.Fragment>
        <h3>{appData.stage === 'Post-Project' ? 'Post-Project Application Submitted for Review': 'Application Draft Submitted for Review'}</h3>
        {appData.stage === 'Post-Project' ? <p>Your application has been submitted for review.</p> : <p>A draft of your application has been submitted for review and pre-approval.</p>}
        <p>Your application ID# is <span>SPEED-{appData.appNo}</span>.</p>
        <p>You can view the status of your application at any time. You should expect an update from our team within the next two business days. </p>
        <div className="button-set">
          <Button
            title={'LOG OUT'}
            uppercase
            className="inversed-btn"
            onClick={userService.logout}
          />
          <Link to="/account">
            <Button
              title={'Go to Account Page'}
              uppercase
            />
          </Link>
        </div>
        </React.Fragment> : <Loader/>}
      </div>
    </InternalLayout>
  }
}

export default ApplicationSentForReview;