import React from 'react';
import Button from '../../Common/Button/Button';
import Input from '../../Common/Input/Input';
import './AppSummary.css';
import { connect } from 'react-redux';
import { userActions } from '../../../redux/actions/userActions';
import { appActions } from '../../../redux/actions/appActions';
import { utils } from '../../../helpers/utils';
import LeftArrow from '../../../images/leftArrow.svg';
import { AuthContext } from '../../../firebaseAuthContext';
import InternalLayout from '../../Common/InternalLayout';
import TooltipInfo from '../../Common/TooltipInfo/TooltipInfo';
import Loader from '../../Common/Loader/Loader';
import { ReactComponent as ViewIcon } from '../../../images/ViewIcon.svg';
import { userService } from '../../../services/userService';
import { applicationService } from '../../../services/applicationService';
import firebase from '../../../helpers/Firebase';
import CheckBoxList from '../../Common/CheckboxList/CheckboxList';
import ResponsiveTable from '../../Common/ResponsiveTable/ResponsiveTable';
import Modal from '../../Common/Modal/Modal';
import { Redirect } from 'react-router-dom';

class AppSummary extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);

    this.state = {
      payeDetails:undefined,
      applicationId: props.match.params.id,
      submitAppModalOpen : false,
      agreementText:'',
      appReviewData : {},
      userInfo: null,
      editing: false,
      cancelModalOpen: false,
      deleteMeasureModalOpen: false,
      deleteDocumentModalOpen: false,
      submitForReviewModalOpen : false,
      addNoteModalOpen : false,
      slideIndex: 0,
      slideTotal: 6,
      dataLoaded: false,
      errors: {},
      documentsList: [],
      selectedFacility: {},
      selectedMeasure: {},
      openDrawer: false,
      opeModal: false,
      confirmingAppNote:false,
      appNoteSubmitting:false,
      appNoteSuccessfullySubmitted:false,
      appNote:'',
      unauthorisedAccess: false,
      applicationObj: {
        stage: 'Pre-Project',
        status: 'Application Draft',
        facilityId: '',
        contractorId: '',
        contractor: '',
        contractorCanEdit: false,
        projectName: '',
        projectStartDate: '',
        projectCompletionDate: '',
        isReceivingOtherFunding: '',
        sourcesOfAllOtherFunding: '',
        totalAmountOfAllOtherFunding: '',
        amountOfEligibleExpenses: '',
        agreeToApplicationTerms : false
      },
      participantAcceptanceCheckList: {
        participantAcceptance1: false,
        participantAcceptance2: false,
        participantAcceptance3: false,
        participantAcceptance4: false,
        participantAcceptance5: false,
        participantAcceptance6: false,
        participantAcceptance7: false,
        participantAcceptance8: false
      }
    }
  }

  editApplication = ()=>{
    this.props.history.push(`/application/${this.props.match.params.id}`);
  }

  addNoteToApp = async ()=>{
    let { applicationId, appNote } = this.state;
    let data = {
      applicationId,
      customerNote : appNote
    }
    this.setState({
      appNoteSubmitting: true
    },async()=>{
      await applicationService.addCustomerNoteToApp(data);
      this.setState({
        appNoteSubmitting: false,
        appNoteSuccessfullySubmitted:true
      })
    })
  }

  verifyParticipantAcceptance = ()=>{
    const {participantAcceptanceCheckList, agreementText} = this.state;
    let errorCount = 0;
    let errors = {};
    for(let key in participantAcceptanceCheckList){
      if(!participantAcceptanceCheckList[key]){
        errors[key] = true;
        errorCount++;
      }
    }
    if(utils.isNullOrEmpty(agreementText)){
      errors['agreementText'] = 'You are required to type the above sentence to proceed';
      errorCount++;
    } else {
      if(agreementText.toLowerCase() !== 'i represent and warrant to emissions reduction alberta that i have authority to bind the participant'){
        errors['agreementText'] = 'You are required to type the exact sentence mentioned above to proceed';
        errorCount++;
      }
    }
    if(errorCount > 0){
      this.setState({errors},()=>{
        let allErrorRefs = document.querySelectorAll('.input-box-error,.error-box');
        if(allErrorRefs.length > 0){
          let firstErrorRef = allErrorRefs[0];
          firstErrorRef.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        }
      });
    }
    return errorCount === 0;
  }

  submitApplication = ()=>{
    if(this.verifyParticipantAcceptance()){
      this.setState({
        submitAppModalOpen : true
      });
    }
  }

  finallySubmitApplication = ()=>{
    const { userInfo, applicationId,participantAcceptanceCheckList } = this.state;
    this.props.history.push(`/applicationsubmitted/${applicationId}`);
    let updateObj = {
      status: "Application Submitted",
      participantAcceptanceCheckList,
      actionTaker: `${userInfo.firstName} ${userInfo.lastName}`,
      actionNote: "",
    };
    this.props.updateApplication(applicationId, updateObj);
  }

  componentDidMount() {
    const appIdToLoad = this.props.match.params.id;
    userService.getUserDetails(firebase.auth.currentUser.uid, (success, userInfo) => {
      this.props.loadContractors();
      this.props.loadFacilityConfig();
      if(!utils.isNullOrEmpty(appIdToLoad)){
        applicationService.getApplication(appIdToLoad).then(async (data) => {
          if(userInfo.uid === data.customerId || userInfo.uid === data.contractorId){
          let appReviewData = null;
          let postProjectAppReviewData = null;
          try {
            appReviewData = await applicationService.getApplicationReview(appIdToLoad);
          } catch(err){

          }
          if(data.stage === 'Post-Project' || data.stage === 'Payment'){
            postProjectAppReviewData = await applicationService.getPostProjectApplicationReview(appIdToLoad);
          }
          userService.getUserDetails(data.customerId, (success, customerData) => {
            this.props.loadFacilities(data.customerId);
            this.props.loadAppMeasures(appIdToLoad);
            this.props.getAppDocuments(appIdToLoad);
            let applicationObj = { ...data };
            if (applicationObj.payeeAssigned && applicationObj.payeeAssigned === true) {
              userService.getUserDetails(applicationObj.payeeInfo.uid, (success, payeDetails) => {
                //console.log("Payee data pulled===",payeDetails);
                this.setState({
                  payeDetails: payeDetails,
                });
              });
            }
            let slideIndex = 0;
            if (userInfo.accountCategory === 'contractor') {
              slideIndex = 2;
            }
            this.setState({
              appReviewData,
              postProjectAppReviewData,
              customerId: data.customerId,
              customerData,
              userInfo,
              editing: true,
              applicationObj,
              slideIndex,
              dataLoaded: true
            },()=>{
              window.scrollTo(0, 0);
            });
          });
          } else{
            this.setState({unauthorisedAccess:true});
          }
      });
      }
    })
  }

  handleChangeSlide = (slideIndex) => {
    if (slideIndex === 2) {
      this.props.loadFacilities(this.state.customerId);
    }
    this.setState({ slideIndex: slideIndex++, errors: {} });
  }

  onContractorChange = (contractor) => {
    const { applicationObj, errors } = this.state;
    delete errors['contractor'];
    this.setState({
      applicationObj: {
        ...applicationObj,
        contractor,
        contractorId: contractor.value
      },
      errors
    })
  }

  onContractorAccessChange = (val) => {
    const { applicationObj, errors } = this.state;
    delete errors['contractorCanEdit'];
    this.setState({
      applicationObj: {
        ...applicationObj,
        contractorCanEdit: val.value
      },
      errors
    })
  }

  onChangeHandler = (name, value) => {
    const { applicationObj, errors } = this.state;
    delete errors[name];
    this.setState({
      applicationObj: {
        ...applicationObj,
        [name]: value
      },
      errors
    });
  }

  viewDocument = (document) => {
    window.open(document.url);
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  onChecklistChangeHandler = (name, value) => {
    let { participantAcceptanceCheckList, errors } = this.state;
    participantAcceptanceCheckList[name] = value;
    delete errors[name];
    this.setState({
      participantAcceptanceCheckList: {
        ...participantAcceptanceCheckList
      },
      errors
    });
  }

  onAgreementChangeText = (name, value) => {
    let {errors} = this.state;
    delete errors['agreementText'];
    this.setState({
      agreementText: value,
      errors
    })
  }


  confirmAppNote = ()=>{
    this.setState({
      confirmingAppNote: !this.state.confirmingAppNote
    });
  }

  applicationSummary = () => {
    const { applicationObj,appReviewData, postProjectAppReviewData, participantAcceptanceCheckList, errors ,payeDetails} = this.state;
    const { customerInfo } = applicationObj;
    const { measureList, documentList, facilityList } = this.props;
    let chosenFacility = null;
    let draftApproved =  applicationObj.status === 'Application Draft Approved' && applicationObj.stage === 'Pre-Approval';
    let appSubmitted =  applicationObj.status === 'Application Submitted' && applicationObj.stage === 'Pre-Approval';
    facilityList.forEach((f)=>{
      if(f.id === applicationObj.facilityId){
        chosenFacility = f;
      }
    });
    let approvalNote = '';
    if(appReviewData && appReviewData.informationRequest){
      approvalNote = appReviewData.informationRequest;
    }
    
    let maxVal = 250000.0;
    if(applicationObj && applicationObj['Reviewer Estimated Incentive']){
      maxVal = parseFloat(applicationObj['Reviewer Estimated Incentive']);
      maxVal = parseFloat((maxVal * 1.05).toFixed(2));// Set to 105% of original
    }

        //SET UP FACILITY TABLE
        let facilityObj = {
          headings : [
            { title : 'Facility Name', value : 'Facility Name'},
            { title : 'Region', value : 'Region'},
            { title : 'Facility Address', value : 'Facility Address'},
            { title : 'Facility Ownership', value : 'Facility Ownership'},
            { title : 'Facility Type', value : 'Facility Type'},
            { title : 'Facility Owner Approval Obtained', value : 'Facility Owner Approval Obtained'}
          ],
          rows : [
            {
              'Facility Name': (chosenFacility ? chosenFacility.facilityName : ""),
              'Region':(chosenFacility ? chosenFacility.facilityApplicableRegion.value : ""),
              'Facility Address':(chosenFacility
                ? `${chosenFacility.facilityAddressLine1.label}, ${
                    chosenFacility.facilityAddressLine2 ? ", " + chosenFacility.facilityAddressLine2 : ""
                  } ${chosenFacility.facilityCity} - ${chosenFacility.facilityPostalCode}`
                : ""),
              'Facility Ownership':(chosenFacility ? chosenFacility.facilityOwnership : ""),
              'Facility Type':(chosenFacility ? chosenFacility.facilityType.label : ""),
              'Facility Owner Approval Obtained':(chosenFacility ? chosenFacility.hasLandlordApprovedProject.label : "")
            }
          ]
        }
    
        //SET UP MEASURES TABLE
        let measureHeadings = [
          { title : 'Measure', value : 'measure'},
          { title : 'Quantity', value : 'quantity'},
          { title : ((applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment") ? "Pre-Approved Incentive ($)" : "Estimated Incentive" ), value : (applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment") ? "preApprovedIncentive" : "estimatedIncentive"}
        ];
        if(draftApproved || appSubmitted){
          measureHeadings.push({ title : 'Pre-Approved Incentive', value :'preApprovedIncentive'});
        }
        if(applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment" ){
          measureHeadings.push({ title : 'Post-Project Applicable Incentive ($)', value :'postProjectApplicableIncentive'});
        }
        if(applicationObj.stage === "Payment" ){
          measureHeadings.push({ title : 'Post-Project Approved Incentive ($)', value :'postProjectApprovedIncentive'});
        }
    
        let measureRows = measureList.map((measure) => {
          return { 
            measure : measure.measure.label,
            quantity : measure.Quantity,
            estimatedIncentive : utils.formatAsCAD(utils.getTEMI(measure)),
            preApprovedIncentive : ((appReviewData && appReviewData.measuresReviewerEstimatedIncentive) ?utils.formatAsCAD(appReviewData.measuresReviewerEstimatedIncentive[measure.id]):""),
            postProjectApplicableIncentive : utils.formatAsCAD(utils.getApplicableTEMI(measure)),
            postProjectApprovedIncentive : ((postProjectAppReviewData && postProjectAppReviewData.measuresPostProjectReviewerApprovedIncentive)?utils.formatAsCAD(postProjectAppReviewData.measuresPostProjectReviewerApprovedIncentive[measure.id]):'0.00'),
            view : <ViewIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewMeasureDetails(measure)}/>
          }
        });
    
        let measureFooter =  <div className="responsive-table-row">
        <div className="responsive-table-details">Total</div>
        <div className="responsive-table-details"></div>
        {(applicationObj.stage === "Post-Project" || applicationObj.stage === 'Payment') ? (
          <React.Fragment>
            <div className="responsive-table-details"><label>Pre-Approved Incentive ($)</label><span>{utils.formatAsCAD(applicationObj["Reviewer Estimated Incentive"] || 0.0)}</span></div>
            <div className="responsive-table-details">
            <label>Post-Project Applicable Incentive ($)</label>
              <span>{utils.formatAsCAD(utils.sumOfApplicableTEMIs(measureList, applicationObj))}</span>
              {parseFloat(utils.sumOfApplicableTEMIs(measureList, applicationObj)) >= maxVal ? (
                <div className="info-icon">
                  <TooltipInfo helpText={"The maximum applicable incentive is capped at 105% of the pre-approved incentive"} />
                </div>
              ) : (
                ""
              )}
            </div>
          </React.Fragment>
        ) : (
          <div className="responsive-table-details">
            <label>Estimated Incentive ($)</label>
            <span>{utils.formatAsCAD(utils.sumOfTEMIs(measureList))}</span>
            {parseFloat(utils.sumOfTEMIs(measureList)) >= 1000000 ? (
              <div className="info-icon">
                <TooltipInfo helpText={"Please note that the project incentive is capped at $1,000,000"} />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        {draftApproved || appSubmitted ? (<div className="responsive-table-details">
          <label>Estimated Incentive ($)</label><span>{utils.formatAsCAD(applicationObj["Reviewer Estimated Incentive"] || 0.0)}</span>
          </div>
        ) : (
          ""
        )}
        </div>;
    
        //SET UP DOCUMENTS TABLE
        let documentHeadings = [
          { title : 'Document Name', value : 'docName'},
          { title : 'Date of Upload', value : 'dateOfUpload'},
          { title : 'View', value : 'view'}
        ];
    
        let documentRows = documentList.map((document) => {
          return {
            docName : document.name,
            dateOfUpload : utils.formatAmericanDate(document.createdAt),
            view : <ViewIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewDocument(document)}/>
          }
        });

    return <div>
     
      <div className="form-sections application-summary">
      <div className="step-header-container">
        <div className="step-header-container-left">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: 'Application Summary' }}></h2>
        </div>
      </div>
      <div className="form-inner-section">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Facility Details" }}></h2>
          {chosenFacility ? <ResponsiveTable headings={facilityObj.headings} rows={facilityObj.rows} /> : (
            ""
          )}
      </div>
      <div className="horizontal-bar"></div>
      <div className="form-inner-section">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Measures Details" }}></h2>
          {measureList.length > 0 ? (
            <ResponsiveTable headings={measureHeadings} rows={measureRows} customFooter={measureFooter}/>) : (
            <div className="empty-summary-container">There are no measures added to this application</div>
          )}
        </div>
      <div className="horizontal-bar"></div>
      <div className="form-inner-section">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Documents Summary" }}></h2>
          {documentList.length ? (
            <ResponsiveTable headings={documentHeadings} rows={documentRows} />
          ) : (
            <div className="empty-summary-container">There are no documents uploaded currently.</div>
          )}
        </div><div className="horizontal-bar"></div>
      {applicationObj ? <div className="form-inner-section">
        <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: 'Project Summary' }}></h2>
        <div className="form-inner-elements">
          <div className="application-form-label">Contractor Name</div>
          <div className="application-form-element">{applicationObj.contractor ? applicationObj.contractor.label : ''}</div>
        </div>
        <div className="form-inner-elements">
          <div className="application-form-label">Application Name</div>
          <div className="application-form-element">{applicationObj.projectName || `SPEED-${applicationObj.appNo}`}</div>
        </div>
        <div className="form-inner-elements">
          <div className="application-form-label">Estimated Project Start Date</div>
          <div className="application-form-element">{utils.formatAmericanDate(applicationObj.projectStartDate)}</div>
        </div>
        <div className="form-inner-elements">
          <div className="application-form-label">Estimated Date of Project Completion</div>
          <div className="application-form-element">{utils.formatAmericanDate(applicationObj.projectCompletionDate)}</div>
        </div>
        {applicationObj.stage === 'Post-Project' || applicationObj.stage === 'Payment' ?
                <div className="form-inner-elements">
                  <div className="application-form-label">Actual Project Completion Date</div>
                  <div className="application-form-element">{utils.formatAmericanDate(applicationObj.actualProjectEndDate)}</div>
                </div> : ''}
        {(draftApproved || appSubmitted)  ? <div className="form-inner-elements">
          <div className="application-form-label">Project Completion Deadline</div>
          <div className="application-form-element">{applicationObj['Project Completion Deadline']}</div>
        </div> : ''}
        <div className="form-inner-elements">
          <div className="application-form-label">Is the project receiving other funding, financial incentives, grants or subsidies?</div>
          <div className="application-form-element">{applicationObj.isReceivingOtherFunding}</div>
        </div>
        {(applicationObj.isReceivingOtherFunding.toLowerCase() === 'yes') ? <React.Fragment>
          <div className="form-inner-elements">
            <div className="application-form-label">Sources of all other funding, financial incentives, grants and subsidies</div>
            <div className="application-form-element">{applicationObj.sourcesOfAllOtherFunding}</div>
          </div>
          <div className="form-inner-elements">
            <div className="application-form-label">Total amount of all other funding, financial incentives, grants and subsidies</div>
            <div className="application-form-element">{utils.formatAsCAD(applicationObj.totalAmountOfAllOtherFunding ? applicationObj.totalAmountOfAllOtherFunding : "0.0")}</div>
          </div>
        </React.Fragment> : ''}
        <div className="form-inner-elements">
          <div className="application-form-label">Amount of eligible expenses incurred Prior to Application Submission</div>
          <div className="application-form-element">{utils.formatAsCAD(applicationObj.amountOfEligibleExpenses ? applicationObj.amountOfEligibleExpenses : "0.0")}</div>
        </div>
      </div>
        : ''}
            {payeDetails && applicationObj.payeeAssigned===true ? (
              <React.Fragment>
            <div className="horizontal-bar"></div>
            <div className="form-inner-section">
              <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Payee Summary" }}></h2>
              <div className="summary-list-container">
                  <div className="company-details">
                    <div className="form-inner-elements">
                      <div className="application-form-label">Payee Full Legal Name</div>
                      <div className="application-form-element">{payeDetails.companyDetails.name}</div>
                    </div>
                    <div className="form-inner-elements">
                      <div className="application-form-label">Payee Address</div>
                      <div className="application-form-element">{`${payeDetails.companyDetails.address1}, ${
                        payeDetails.companyDetails.address2 ? payeDetails.companyDetails.address2 + ", " : ""
                      }${payeDetails.companyDetails.city}, ${payeDetails.companyDetails.province} - ${
                        payeDetails.companyDetails.postalCode
                      }`}</div>
                    </div>
                    <div className="form-inner-elements">
                      <div className="application-form-label">Payee Contact Name</div>
                      <div className="application-form-element">
                        {payeDetails.firstName} {payeDetails.lastName}
                      </div>
                    </div>
                    <div className="form-inner-elements">
                      <div className="application-form-label">Phone</div>
                      <div className="application-form-element">{utils.formatPhoneNumber(payeDetails.phoneNum)}</div>
                    </div>
                  </div>
               
              </div>
              <div className="horizontal-bar"></div>
            </div>
            </React.Fragment>
             ) : (
              ""
            )}
      {draftApproved ?  <React.Fragment><div className="horizontal-bar"></div>
            <div className="form-inner-section">
              <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: 'Participant Acceptance' }}></h2>
              <div className="summary-list-container">
                <div className="list-heading">By signing below, the Participant agrees to be bound by the Participant Acceptance.</div>
                <p>Without limiting the foregoing, the Participant confirms the following to ERA:</p>
                <div className="checkboxes-section">
                  <CheckBoxList
                    onChange={this.onChecklistChangeHandler}
                    errors={errors}
                    items={
                      [
                        { checked: participantAcceptanceCheckList['participantAcceptance1'], name: 'participantAcceptance1', title: <p>The Participant has reviewed the Application Summary, and represents and warrants that all information contained in the Application Summary is complete, true and accurate. </p> },
                        { checked: participantAcceptanceCheckList['participantAcceptance2'], name: 'participantAcceptance2', title: <p>The Participant has reviewed the <span><a target="_blank" rel="noreferrer" href='https://firebasestorage.googleapis.com/v0/b/dolphin-live-a906a.appspot.com/o/SPEED%20TermsAndConditions.pdf?alt=media&token=bf02d654-d692-4b34-936f-d64334a86aea'>Terms and Conditions</a></span>.</p> },
                        { checked: participantAcceptanceCheckList['participantAcceptance3'], name: 'participantAcceptance3', title: <p>The Participant confirms that it meets the eligibility requirements to be a Participant and that the Facility meets the requirements to be an Eligible Facility. </p> },
                        { checked: participantAcceptanceCheckList['participantAcceptance4'], name: 'participantAcceptance4', title: <p>The Participant confirms that the Measures set out in the Application Summary meet the requirements to be Eligible Measures, and that the project meets the requirement to be an Eligible Project.  </p> },
                        { checked: participantAcceptanceCheckList['participantAcceptance5'], name: 'participantAcceptance5', title: <p>The Participant has all rights, permits, licenses and authorizations required to carry out the Eligible Project in the Eligible Facility.</p> },
                        { checked: participantAcceptanceCheckList['participantAcceptance6'], name: 'participantAcceptance6', title: <p>If the Participant is not the owner of the Eligible Facility, then the Participant has obtained the owner’s consent to carry out the Eligible Project. </p> },
                        { checked: participantAcceptanceCheckList['participantAcceptance7'], name: 'participantAcceptance7', title: <p>The Participant Acceptance Form may be executed and delivered by electronic means. Any such signatures, including any contract formation on the Portal or record-keeping through electronic means, may be relied upon by the Participant and the ERA and shall have the same legal effect, validity of enforceability as a manually executed signature, physical delivery thereof, or the use of a paper-based record-keeping system, as the case may be.</p> },
                        { checked: participantAcceptanceCheckList['participantAcceptance8'], name: 'participantAcceptance8', title: <p>All expenses listed in this Application are <span>Eligible Expenses</span> as defined in section 6.2 of the <a href="https://firebasestorage.googleapis.com/v0/b/dolphin-live-a906a.appspot.com/o/SPEED%20TermsAndConditions.pdf?alt=media&token=bf02d654-d692-4b34-936f-d64334a86aea">Terms and Conditions</a> and do not include any expenses which are not Eligible Expenses.</p> }
                      ]
                    }
                  />
                </div>
                <div className='summary-list-container'>
                <div className="company-details">
                  <div className="form-inner-elements">
                    <div className="application-form-label">Company Full Legal Name </div>
                    <div className="application-form-element">{applicationObj.companyName}<span className="info-icon legal-name-info">
                        <TooltipInfo dynamicPosition={true} helpText={'This is the legal name of the entity that will be bound to the Terms and Conditions of the Small Producer Energy Efficiency Deployment program. If you need to change the name, please do it in the Account Page before you execute the Participant Acceptance and it will be automatically updated here.'} />
                      </span></div>
                  </div>
                  <div className="form-inner-elements">
                    <div className="application-form-label">Company Address</div>
                    <div className="application-form-element"> {`${customerInfo.companyDetails.address1}, ${customerInfo.companyDetails.address2 ? (customerInfo.companyDetails.address2 + ', ') : ''}${customerInfo.companyDetails.city}, ${customerInfo.companyDetails.province} - ${customerInfo.companyDetails.postalCode}`}</div>
                   </div> 
                  <div className="form-inner-elements">
                    <div className="application-form-label">Participant Contact Name</div>
                    <div className="application-form-element">{`${applicationObj.customerInfo.firstName} ${applicationObj.customerInfo.lastName}`}</div>
                  </div>
                  <div className="form-inner-elements">
                    <div className="application-form-label">Phone</div>
                    <div className="application-form-element">{`${utils.formatPhoneNumber(applicationObj.customerInfo.phoneNum)}`}</div>
                  </div>
                </div>
                <div className="agreement-text-container">
                  <p>Type the following sentence in the text box below ‘<span>I represent and warrant to Emissions Reduction Alberta that I have authority to bind the Participant</span>’ to mark the participant acceptance.</p>
                  <Input
                    className='agreement-text-area'
                    name='termsOfAgree'
                    disablePaste={true}
                    type='text'
                    placeholder='I represent and warrant to Emissions Reduction Alberta that I have authority to bind the Participant'
                    rows='1'
                    cols='50'
                    error={this.state.errors['agreementText']}
                    onChange={this.onAgreementChangeText}
                    value={this.state.agreementText}
                  />
                </div>
                <div className="form-declaration-container">
                  <div className="form-declaration-left">
                    <span>{`${applicationObj.customerInfo.firstName} ${applicationObj.customerInfo.lastName}`}</span>
                  </div>
                  <div className="form-declaration-right">
                    <span>{utils.getDate()}</span>
                  </div>
                </div>
              </div>
              </div>
            </div> </React.Fragment> : ''}
    </div></div>;
  }

  sumIt = (list, key) => {
    let ans = 0.0;
    for (let item of list) {
      ans += parseFloat(item[key]);
    }
    return ans;
  }

  sumOfTEMs = (measures) => {
    let ans = 0.0;
    for (let item of measures) {
      ans += parseFloat(this.getTEM(item));
    }
    return ans.toFixed(2);
  }

  getTEM = (measure) => {
    let ans = '';
    let options = ["Total Emissions Reduction",
    "Total Emissions Reduction - VFD for Fan/Pump Motor",
    "Total Emissions Reduction - Strip Curtain",
    "Total Emissions Reduction - Water Heater",
    "Total Emissions Reduction - Insulation",
    "Total Emissions Reduction - Windows",
    "Total Emissions Reduction - Economizer",
    "Total Emissions Reduction - Steam Fittings and Pipe Insulation",
    "Total Emissions Reduction - Boiler or Heater",
    "Total Emissions Reduction - ASHP or VRF",
    "Total Emissions Reduction - Make-Up Air Unit",
    "Total Emissions Reduction - HRV/ERV",
    "Total Emissions Reduction - Pipe Insulation"];
    for (let item of options) {
      if (measure[item]) {
        return parseFloat(measure[item]).toFixed(2);
      }
    }
    return '';
  }

  getTEMI = (measure) => {
    let ans = '';
    let options = ["Total Eligible Measure Incentive",
    "Total Eligible Measure Incentive - VFD for Fan/Pump Motor",
        "Total Eligible Measure Incentive - Strip Curtain",
        "Total Eligible Measure Incentive - Water Heater",
        "Total Eligible Measure Incentive - Insulation",
        "Total Eligible Measure Incentive - Windows",
        "Total Eligible Measure Incentive - Economizer",
        "Total Eligible Measure Incentive - Steam Fittings and Pipe Insulation",
        "Total Eligible Measure Incentive - Boiler or Heater",
        "Total Eligible Measure Incentive - ASHP or VRF",
        "Total Eligible Measure Incentive - Make-Up Air Unit",
        "Total Eligible Measure Incentive - HRV/ERV",
        "Total Eligible Measure Incentive - Pipe Insulation"];
    for (let item of options) {
      if (measure[item]) {
        return parseFloat(measure[item]).toFixed(2);
      }
    }
    return '';
  }

  sumOfTEMIs = (measures) => {
    let ans = 0.0;
    for (let item of measures) {
      ans += parseFloat(this.getTEMI(item));
    }
    if(ans >= 250000){
      ans = 250000.00;
    }
    return ans.toFixed(2);
  }
  getApplicableTEMI = (measure) => {
    let ans = "";
    let options = [
      "Total Applicable Measure Incentive",
      "Total Applicable Measure Incentive - VFD for Fan/Pump Motor",
      "Total Applicable Measure Incentive - Strip Curtain",
      "Total Applicable Measure Incentive - Water Heater",
      "Total Applicable Measure Incentive - Insulation",
      "Total Applicable Measure Incentive - Windows",
      "Total Applicable Measure Incentive - Economizer",
      "Total Applicable Measure Incentive - Steam Fittings and Pipe Insulation",
      "Total Applicable Measure Incentive - Boiler or Heater",
      "Total Applicable Measure Incentive - ASHP or VRF",
      "Total Applicable Measure Incentive - Make-Up Air Unit",
      "Total Applicable Measure Incentive - HRV/ERV",
      "Total Applicable Measure Incentive - Pipe Insulation",
    ];
    for (let item of options) {
      if (measure[item]) {
        return parseFloat(measure[item]).toFixed(2);
      }
    }
    return 0.0;
  };

  sumOfApplicableTEMIs = (measures, applicationObj) => {
    let maxVal = 250000.0;
    if(applicationObj && applicationObj['Reviewer Estimated Incentive']){
      maxVal = parseFloat(applicationObj['Reviewer Estimated Incentive']);
      maxVal = maxVal * 1.05;// Set to 105% of original
    }
    let ans = 0.0;
    if(measures &&measures.length>0){
      for (let item of measures) {
        ans += parseFloat(this.getApplicableTEMI(item));
      }
    }
    
    console.log('Ans=>',ans, ' maxVal=>', maxVal);
    if (ans >= maxVal) {
      ans = maxVal;
    }
    return ans.toFixed(2);
  }; 

  goToAccount = () => {
    this.props.history.push('/account');
  }
 
  saveAndExit = () => {
    const { slideIndex, slideTotal, applicationObj, userInfo, editing, applicationId } = this.state;
    const newSlideIndex = slideIndex + 1;
    if (!editing && slideIndex === 0) {
      applicationObj.companyName = userInfo.companyDetails.name;
      if (userInfo.accountCategory === 'customer') {
        applicationObj.customerId = userInfo.uid;
      }
      applicationService.createApplication(applicationObj).then((app) => {
        this.setState({
          applicationId: app.id
        });
        this.goToAccount();
      });;
    } else {
      if (this.verifyForm(applicationObj)) {
        if ((newSlideIndex) === slideTotal) {
          this.props.history.push('/applicationforreview');
        } else {
          delete applicationObj.createdAt;
          this.props.updateApplication(applicationId, applicationObj);
          this.goToAccount();
        }
      }
    }
  }

  nextStep = () => {
    const { slideIndex, slideTotal, applicationObj, userInfo, editing, applicationId } = this.state;  
    const isContractor = userInfo ? userInfo.accountCategory.toLowerCase() === 'contractor' : false;
    const finalStageForContractor = (slideIndex > 3) && isContractor;
    if(finalStageForContractor){
      //TODO: Save the data and trigger email to Customer from Contractor
      //TODO: Trigger Email and take to shared view
      this.props.history.push(`/applicationshared/${applicationId}`);
      return;
    }
    const newSlideIndex = slideIndex + 1;
    if (!editing && slideIndex === 0) {
      applicationObj.companyName = userInfo.companyDetails.name;
      if (userInfo.accountCategory === 'customer') {
        applicationObj.customerId = userInfo.uid;
      }
      applicationService.createApplication(applicationObj).then((app) => {
        this.setState({
          applicationId: app.id,
          slideIndex: newSlideIndex
        });
      });;
    } else {
      if (this.verifyForm(applicationObj)) {
        if ((newSlideIndex) === slideTotal) {
          this.setState({
            submitForReviewModalOpen : true
          });
        } else {
          delete applicationObj.createdAt;
          this.props.updateApplication(applicationId, applicationObj);
          this.setState({ slideIndex: newSlideIndex });
        }
      }
    }
  }

  toggleSubmitAppModal = (modalOpen) => {
    this.setState({ submitAppModalOpen: modalOpen });
  }

  toggleAddNoteModal = (modalOpen) => {
    let updateObj = {
      addNoteModalOpen: modalOpen
    };
    if(modalOpen){
      updateObj.appNoteSuccessfullySubmitted = false;
      updateObj.appNoteSubmitting = false;
      updateObj.appNote = '';
      updateObj.confirmAppNote = false;
    }
    this.setState(updateObj);
  }
  
  verifyForm = (applicationObj) => {
    let errors = {};
    let errorCount = 0;
    let { slideIndex } = this.state;
    if (slideIndex === 0) {
      if (utils.isNullOrEmpty(applicationObj.contractor.value)) {
        errors.contractor = 'Contractor is required.'
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.contractorCanEdit)) {
        errors.contractorCanEdit = 'Contractor Access Option is required.'
        errorCount++;
      }
    } else if (slideIndex === 1) {
      if (utils.isNullOrEmpty(applicationObj.projectName)) {
        errors.projectName = 'Application (or) Project Name is required.'
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.projectStartDate)) {
        errors.projectStartDate = 'Estimated Project Start Date is required.'
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.projectCompletionDate)) {
        errors.projectCompletionDate = 'Estimated Project Completion Date is required.'
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.isReceivingOtherFunding)) {
        errors.isReceivingOtherFunding = 'Please select an option.'
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.amountOfEligibleExpenses)) {
        errors.amountOfEligibleExpenses = 'Please enter amount.'
        errorCount++;
      }
    }
    if (errorCount > 0) {
      this.setState({ errors }, () => {
        let allErrorRefs = document.querySelectorAll('.input-box-error');
        if (allErrorRefs.length > 0) {
          let firstErrorRef = allErrorRefs[0];
          firstErrorRef.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      });
    }
    return errorCount === 0;
  }

  handleAppNoteChange = (name, value)=>{
    this.setState({
      appNote: value
    })
  }

  render() {
    const { applicationObj, appReviewData, postProjectAppReviewData, editing, dataLoaded,submitAppModalOpen,addNoteModalOpen,confirmingAppNote,appNote,appNoteSuccessfullySubmitted,appNoteSubmitting, unauthorisedAccess } = this.state;
    if(unauthorisedAccess){
      return <Redirect to="/error404" />;
    }
    const { applicationProcessing } = this.props;
    let draftApproved =  applicationObj.status === 'Application Draft Approved' && applicationObj.stage === 'Pre-Approval';
    let title = applicationObj.status === 'Application Information Requested' ? 'Information Requested' : applicationObj.status === 'Application Draft Approved' ? 'Pre-Approval Notice' : 'Application Rejected';
    let message = '';
    let reviewNote = appReviewData.informationRequest;
    if(applicationObj.status === 'Application Information Requested'){
      message = 'Your pre-project application has been reviewed by the SPEED Program team and the following additional clarifications/information regarding your application has been requested:';
    } else if(applicationObj.status === 'Application Rejected'){
      message = 'Your pre-project application has been reviewed by the SPEED Program team and it has been rejected. The reason your application has been rejected is as follows:';
    } else if(applicationObj.status === 'Application Draft Approved'){
      message = 'Your pre-project application has been approved by the Small Producer Energy Efficiency Deployment program. Please review the Application Summary and complete the Participant Acceptance below.';
    }

    if(postProjectAppReviewData && postProjectAppReviewData.informationRequest){
      reviewNote = postProjectAppReviewData.informationRequest;
    }
    
    return (
      <InternalLayout history={this.props.history}>
      <div className="view-application-container">
       <button onClick={() => { this.props.history.length > 2 ? this.props.history.goBack() : this.props.history.push('/') }} className="back-button"><img src={LeftArrow} alt='arrow' />Back</button>
        {dataLoaded ? <div className="section-header">
          <h2 className="section-title">{`Application ID: SPEED-${applicationObj.appNo}`}</h2>
        </div> : ''}
      {['Application Information Requested','Application Rejected','Application Draft Approved'].includes(applicationObj.status) ? <div className="container-box information-requested-box"><div className="form-sections application-summary">
      <div className="step-header-container">
        <div className="step-header-container-left">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: title }}></h2>
        </div>
      </div>
      <p>{message}</p>
      <div className="container-box" dangerouslySetInnerHTML={{ __html: reviewNote || ''}}/>
      {applicationObj.status === 'Application Information Requested' ? <p><span>Note:</span> Click 'Edit Application' to update your application as required and to submit it. Please note that only the Customer/Participant can submit the application, although a Contractor can make the edits and updates requested.</p> : ''}
      </div></div> : ''}
      <Modal
          HeaderText={'Confirmation'}
          IsOpen={submitAppModalOpen}
          handleCloseModal={() => this.toggleSubmitAppModal(false)}
        >
          <p>You are about to submit the application. Are you sure?</p>
          <div className='button-set'>
              <Button
                title={'No'}
                className='inversed-btn'
                uppercase
                onClick={() => this.toggleSubmitAppModal(false)}
              />
              <Button
                onClick={() => { this.finallySubmitApplication() }}
                title={'Yes'}
                uppercase
              />
              
          </div>
        </Modal>
        <Modal
          HeaderText={'Add Note'}
          IsOpen={addNoteModalOpen}
          loading={appNoteSubmitting}
          handleCloseModal={() => this.toggleAddNoteModal(false)}
        >
          {appNoteSuccessfullySubmitted ?
              <p>Note submitted successfully!</p> : <React.Fragment>
<p>Add a note in response to the information requested.</p>
          <Input disabled={confirmingAppNote} type={'richtext'} value={appNote} onChange={this.handleAppNoteChange} name="appNote" />
                        {confirmingAppNote ? <Button
                  className='signup-btn'
                  title={'Submit Note'}
                  uppercase
                  onClick={this.addNoteToApp}
                  disabled={!utils.cleanHTMLText(appNote)}
                />: <Button
                className='signup-btn'
                title={'Confirm Note'}
                uppercase
                onClick={this.confirmAppNote}
                disabled={!utils.cleanHTMLText(appNote)}
              /> }
                  {confirmingAppNote ? <Button
                    className='inversed-btn'
                    title={'EDIT'}
                    uppercase
                    onClick={this.confirmAppNote}
                  /> : <Button
                  className='inversed-btn'
                  title={'CANCEL'}
                  uppercase
                  onClick={() => this.toggleAddNoteModal(false)}
                />}
              </React.Fragment>}
        </Modal>
      {applicationObj.status === 'Application Information Requested' ? <div className="btn-container">
      <Button
            title={'EDIT APPLICATION'}
            className='enerva-btn edit-app-btn'
            uppercase
            onClick={this.editApplication}
          />
      </div> : ''}
        {(dataLoaded && !applicationProcessing) ? <React.Fragment><div className="container-box">
          {this.applicationSummary()}
        </div>
        {draftApproved ? <div className="btn-container"><Button
            title={'SUBMIT APPLICATION'}
            className='enerva-btn edit-app-btn'
            uppercase
            disabled={!utils.isNullOrEmpty(this.state.errors)}
            onClick={this.submitApplication}
          /></div> : ''}
        
        </React.Fragment> : <Loader />}
      </div></InternalLayout>
    );
  }
}

function mapState(state) {
  const { facilityConfig, facilityList, loadingFacilityConfig, loadingActiveContractors, activeContractors, loadingActiveContractorsError, applicationProcessing, applicationCreated, applicationId, measureCategories, measureCategoriesLoading, measureTypesLoading, measureTypes, measures, measuresLoading, measureConfig, measureConfigLoading, measureList, measureCategory, measureType, measureCode, measureDrawerConfig, documentList, editingMeasureId } = state.application;
  return { facilityConfig, facilityList, loadingFacilityConfig, loadingActiveContractors, activeContractors, loadingActiveContractorsError, applicationProcessing, applicationCreated, applicationId, measureCategories, measureCategoriesLoading, measureTypesLoading, measureTypes, measures, measuresLoading, measureConfig, measureConfigLoading, measureList, measureCategory, measureType, measureCode, measureDrawerConfig, documentList, editingMeasureId };
}

const actionCreators = {
  loadFacilityConfig: appActions.getFacilitiesConfig,
  loadContractors: appActions.getActiveContractors,
  loadUserDetails: userActions.getUserDetails,
  loadConfig: userActions.getAdminConfig,
  loadFacilities: appActions.getFacilitiesForCustomer,
  updateUserDetails: userActions.updateUserDetails,
  createFacility: appActions.createFacility,
  createApplication: appActions.createApplication,
  updateApplication: appActions.updateApplication,
  setMeasureConfig: appActions.setMeasureConfig,
  createAppMeasure: appActions.createAppMeasure,
  updateAppMeasure: appActions.updateAppMeasure,
  loadAppMeasures: appActions.getAppMeasures,
  deleteAppMeasure: appActions.deleteAppMeasure,
  logout: userActions.logout,
  setMeasureCategory: appActions.setMeasureCategory,
  setMeasureType: appActions.setMeasureType,
  setMeasure: appActions.setMeasure,
  clearMeasureDrawer: appActions.clearMeasureDrawer,
  createDocument: appActions.createAppDocument,
  updateDocument: appActions.updateAppDocument,
  deleteDocument: appActions.deleteAppDocument,
  getAppDocuments: appActions.getAppDocuments
}

export default connect(mapState, actionCreators)(AppSummary);