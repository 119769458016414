import { appActions } from '../actions/appActions';
import { appActionTypes } from '../actions/appActionTypes';
import { utils } from '../../helpers/utils';

export function application(state = { 
  measureDrawerConfig : {
    open : false, title : 'ADD MEASURE', buttonTitle :'GET CALCULATED VALUES', loading : false,
    finishButtonTitle : 'ADD MEASURE'
  }, 
  facilityList : [], measureCategories : [], measureTypes : [], measures : [], measureConfig : [], measureList : [], documentList : [],activeChangeReqLogs:[], preapprovedApps:[]}, action) {
  switch (action.type) {
    case appActionTypes.RELOAD_APPLICATION_MEASURE_REQUEST:
      return {
        ...state, 
        reloadingMeasureData : true,
        measureDrawerConfig : {
          open : true, title : 'EDIT MEASURE', buttonTitle :'GET CALCULATED VALUES', loading:true,
          finishButtonTitle : 'UPDATE MEASURE'
        }, 
      };
    case appActionTypes.CLEAR_MEASURE_DRAWER : 
      return {
        ...state,
        measureDrawerConfig : {
          open : action.drawerState, title : 'ADD MEASURE', buttonTitle :'GET CALCULATED VALUES',loading:false,
          finishButtonTitle : 'ADD MEASURE'
        }, 
        measureCategory : null,
        measureType : null,
        measureCode : null,
        editingMeasureId : null,
        measureConfig : []
      };
    case appActionTypes.RELOAD_APPLICATION_MEASURE_SUCCESS:
      return {
        ...state, 
        measureDrawerConfig : {
          open : true, title : 'EDIT MEASURE', buttonTitle :'GET CALCULATED VALUES', loading:false,
          finishButtonTitle : 'UPDATE MEASURE'
        }, 
        reloadingMeasureData : false,
        measureCategory:action.measureDoc.measureCategory,
        measureType:action.measureDoc.measureType,
        measureCode:action.measureDoc.measure,
        applicationMeasureObj : action.measureDoc,
        measureCategories : action.measureCategories,
        measureTypes : action.measureTypes,
        measures : action.measures,
        measureConfig : action.measureConfig.fields,
        editingMeasureId : action.measureDoc.id
      };
    case appActionTypes.RELOAD_APPLICATION_MEASURE_FAILURE:
      return {...state, reloadingMeasureDataError: action.error, reloadingMeasureData:false};    
    case appActionTypes.CREATE_APPLICATION_REQUEST:
      return {...state, applicationProcessing: true };
    case appActionTypes.CREATE_APPLICATION_SUCCESS:
      return {...state, applicationProcessing:false, applicationCreated: true, applicationId : action.applicationId };
    case appActionTypes.CREATE_APPLICATION_FAILURE:
      return {...state, applicationCreationError: action.error};
    case appActionTypes.CREATE_APPLICATION_MEASURE_REQUEST:
      return {...state, measureCreating: true };
    case appActionTypes.CREATE_APPLICATION_MEASURE_SUCCESS:
      return {...state, measureCreating:false, measureCreated: true, measureList : action.data };
    case appActionTypes.CREATE_APPLICATION_MEASURE_FAILURE:
      return {...state, measureCreationError: action.error, measureCreating:false};
    case appActionTypes.DELETE_APPLICATION_MEASURE_REQUEST:
      return {...state, measureDeleting: true };
    case appActionTypes.DELETE_APPLICATION_MEASURE_SUCCESS:
      return {...state, measureDeleting:false, measureDeleted: true, measureList : action.data };
    case appActionTypes.DELETE_APPLICATION_MEASURE_FAILURE:
      return {...state, measureCreationError: action.error, measureDeleting:false};
    case appActionTypes.UPDATE_APPLICATION_MEASURE_REQUEST:
      return {...state, measureUpdating: true };
    case appActionTypes.UPDATE_APPLICATION_MEASURE_SUCCESS:
      return {...state, measureUpdating:false, measureUpdated : true, measureList : action.data };
    case appActionTypes.UPDATE_APPLICATION_MEASURE_FAILURE:
      return {...state, measureUpdatingError: action.error, measureUpdating:false};
    case appActionTypes.GET_APPLICATION_MEASURES_REQUEST:
      return {...state, applicationMeasuresLoading: true };
    case appActionTypes.GET_APPLICATION_MEASURES_SUCCESS:
      return {...state, applicationMeasuresLoading:false, measureList : action.data };
    case appActionTypes.GET_APPLICATION_MEASURES_FAILURE:
      return {...state, getApplicationMeasuresError: action.error, applicationMeasuresLoading:false};
    case appActionTypes.CREATE_APPLICATION_DOCUMENT_REQUEST:
      return {...state, documentUploading: true };
    case appActionTypes.CREATE_APPLICATION_DOCUMENT_SUCCESS:
      return {...state, documentUploading:false, documentUploaded: true, documentList : action.documentList };
    case appActionTypes.CREATE_APPLICATION_DOCUMENT_FAILURE:
      return {...state, documentUploadError: action.error, documentUploading:false};
    case appActionTypes.DELETE_APPLICATION_DOCUMENT_REQUEST:
      return {...state, documentDeleting: true };
    case appActionTypes.DELETE_APPLICATION_DOCUMENT_SUCCESS:
      return {...state, documentDeleting:false, documentDeleted: true, documentList : action.documentList };
    case appActionTypes.DELETE_APPLICATION_DOCUMENT_FAILURE:
      return {...state, documentDeletionError: action.error, documentDeleting:false};
    case appActionTypes.UPDATE_APPLICATION_DOCUMENT_REQUEST:
      return {...state, documentUpdating: true };
    case appActionTypes.UPDATE_APPLICATION_DOCUMENT_SUCCESS:
      return {...state, documentUpdating:false, documentUpdated : true, documentList : action.documentList  };
    case appActionTypes.UPDATE_APPLICATION_DOCUMENT_FAILURE:
      return {...state, documentUpdatingError: action.error, documentUpdating:false};
    case appActionTypes.GET_APPLICATION_DOCUMENTS_REQUEST:
      return {...state, applicationDocumentsLoading: true };
    case appActionTypes.GET_APPLICATION_DOCUMENTS_SUCCESS:
      return {...state, applicationDocumentsLoading:false, documentList : action.data };
    case appActionTypes.GET_APPLICATION_DOCUMENTS_FAILURE:
      return {...state, getApplicationDocumentsError: action.error, applicationDocumentsLoading:false};
    case appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_REQUEST:
      return {...state, loadingApplications: true };
    case appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_SUCCESS:
      return {...state, loadingApplications: false, applicationList : action.apps };
    case appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_FAILURE:
      return {...state, loadingApplicationsError: action.error, loadingApplications : false};    
    case appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_BY_STATUS_REQUEST:
      return {...state, loadingApplicationsByStatus: true };
    case appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_BY_STATUS_SUCCESS:
      return {...state, loadingApplicationsByStatus: false, applicationListByStatus : action.apps };
    case appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_BY_STATUS_FAILURE:
      return {...state, loadingApplicationsError: action.error, loadingApplicationsByStatus : false};    
    case appActionTypes.GET_PREAPPROVED_APPLICATIONS_REQUEST:
      return {...state, loadingPreapprovedApps: true };
    case appActionTypes.GET_PREAPPROVED_APPLICATIONS_SUCCESS:
      return {...state, loadingPreapprovedApps: false, preapprovedApps : action.apps };
    case appActionTypes.GET_PREAPPROVED_APPLICATIONS_FAILURE:
      return {...state, loadingPreapprovedAppsError: action.error, loadingPreapprovedApps : false};    
    case appActionTypes.CREATE_FACILITY_REQUEST:
      return {...state, facilityBeingCreated: true };
    case appActionTypes.CREATE_FACILITY_SUCCESS:
      return {...state, facilityBeingCreated : false, facilityList : action.facilities };
    case appActionTypes.CREATE_FACILITY_FAILURE:
      return {...state, facilityCreationError: action.error};
    case appActionTypes.UPDATE_FACILITY_REQUEST:
      return {...state, applicationProcessing: true };
    case appActionTypes.UPDATE_APPLICATION_SUCCESS:
      return {...state, applicationProcessing:false, applicationUpdated: true};
    case appActionTypes.UPDATE_APPLICATION_FAILURE:
      return {...state, applicationCreationError: action.error};
    case appActionTypes.GET_ACTIVE_CONTRACTORS_REQUEST:
      return {...state, loadingActiveContractors: true };
    case appActionTypes.GET_ACTIVE_CONTRACTORS_SUCCESS:
      return {...state, loadingActiveContractors: false, activeContractors : action.data };
    case appActionTypes.GET_ACTIVE_CONTRACTORS_FAILURE:
      return {...state, loadingActiveContractorsError: action.error, loadingActiveContractors : false};
    case appActionTypes.GET_FACILITIES_FOR_CUSTOMER_REQUEST:
      return {...state, loadingFacilities: true };
    case appActionTypes.GET_FACILITIES_FOR_CUSTOMER_SUCCESS:
      return {...state, loadingFacilities: false, facilityList : action.facilities };
    case appActionTypes.GET_FACILITIES_FOR_CUSTOMER_FAILURE:
      return {...state, getFacilitiesError: action.error, loadingFacilities : false};      
    case appActionTypes.GET_FACILITY_CONFIG_REQUEST:
      return {...state, loadingFacilityConfig: true };
    case appActionTypes.GET_FACILITY_CONFIG_SUCCESS:
      return {...state, loadingFacilityConfig: false, facilityConfig : action.data };
    case appActionTypes.GET_FACILITY_CONFIG_FAILURE:
      return {...state, loadingFacilityConfig: false, facilityConfigError : action.error};
    case appActionTypes.GET_MEASURE_CATEGORIES_REQUEST:
      return {...state, measureCategoriesLoading: true };
    case appActionTypes.GET_MEASURE_CATEGORIES_SUCCESS:
      return {...state, measureCategoriesLoading:false, measureCategories : action.data, measureTypes:[], measures:[], measureConfig : [] };
    case appActionTypes.GET_MEASURE_CATEGORIES_FAILURE:
      return {...state, measureCategoriesError: action.error, measureCategoriesLoading : false};
    case appActionTypes.GET_MEASURE_TYPES_REQUEST:
      return {...state, measureTypesLoading: true };
    case appActionTypes.GET_MEASURE_TYPES_SUCCESS:
      return {...state, measureTypesLoading:false, measureTypes : action.data, measureConfig : [] };
    case appActionTypes.GET_MEASURE_TYPES_FAILURE:
      return {...state, measureTypesError: action.error, measureTypesLoading : false};
    case appActionTypes.GET_MEASURES_REQUEST:
      return {...state, measuresLoading: true };
    case appActionTypes.GET_MEASURES_SUCCESS:
      return {...state, measuresLoading:false, measures : action.data, measureConfig : [] };
    case appActionTypes.GET_MEASURES_FAILURE:
      return {...state, measuresError: action.error, measuresLoading : false};
    case appActionTypes.GET_MEASURE_CONFIG_REQUEST:
      return {...state, measureConfigLoading: true };
    case appActionTypes.GET_MEASURE_CONFIG_SUCCESS:
      return {...state, measureConfigLoading:false, measureConfig : action.data.fields };
    case appActionTypes.SET_MEASURE_CONFIG:
        return {...state, measureConfig : action.config};
    case appActionTypes.GET_MEASURE_CONFIG_FAILURE:
      return {...state, measureConfigError: action.error, measureConfigLoading : false};
    case appActionTypes.SET_MEASURE_CATEGORY:
      return {...state, measureCategory : action.measureCategory };
    case appActionTypes.SET_MEASURE_TYPE:
      return {...state, measureType : action.measureType };
    case appActionTypes.SET_MEASURE:
      return {...state, measureCode : action.measureCode };
    case appActionTypes.GET_APPLICATION_CHANGE_REQ_LOGS_REQUEST:
      return {...state,
          appChangeReqLogsLoading : true
      };
    case appActionTypes.GET_APPLICATION_CHANGE_REQ_LOGS_SUCCESS:
      return {...state,
          appChangeReqLogsLoading : false,
          activeChangeReqLogs : action.logs
      };
    default:
      return state
  }
}