import React from "react";
import {
    Router,
    Switch,
    Route
} from "react-router-dom";
import { history } from './helpers/history';
import SignUp from './components/Pages/SignUp/SignUp';
import Login from './components/Pages/Login/Login';
import AccountOverview from "./components/Pages/AccountOverview/AccountOverview";
import AccountManagement from "./components/Pages/AccountManagement/AccountManagement";
import Application from "./components/Pages/Application/Application";
import AppSummary from "./components/Pages/AppSummary/AppSummary";
import ViewAppDetails from "./components/Pages/ViewAppDetails/ViewAppDetails";
import ApplicationShared from "./components/Pages/ApplicationShared/ApplicationShared";
import ApplicationSubmitted from "./components/Pages/ApplicationSubmitted/ApplicationSubmitted";
import ApplicationSentForReview from "./components/Pages/ApplicationSentForReview/ApplicationSentForReview";
import ProtectedRoute from './components/Common/ProtectedRoute';
import EditDetails from "./components/Pages/EditDetails/EditDetails";
import ErrorNotFound from "./components/Pages/ErrorNotFound/ErrorNotFound";
import MaintenancePage from "./components/Pages/MaintenancePage/MaintenancePage";
import LegaleseContainer from "./components/Common/LegaleseContainer/LegaleseContainer";
import {userService} from "./services/userService";
import CookieConsent from "react-cookie-consent";
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
}

const downForMaintenance = process.env.REACT_APP_STAGE === 'maintenance';
export default class Main extends React.Component{

constructor(props){
    super(props);
    this.state = {
        legalLinks : []
    }
}

componentDidMount(){
    TagManager.initialize(tagManagerArgs);
    userService.getLegalDocs().then((legalLinks)=>{
        this.setState({
            legalLinks
        });
    });
}

renderLegalLinks = ()=>{
    const {legalLinks} = this.state;
    let arr = [];
    legalLinks.forEach((legalDoc,index)=>{
                arr.push(
                <Route key={`${legalDoc.title}${index}`} exact path={`${legalDoc.url}`}>
                <LegaleseContainer 
                {...legalDoc}
                />
                </Route>);
            });
    arr.push(<Route key={`Error-Not-Found-Route`} component={ErrorNotFound} />);
    return arr;
}

render() {
    const { legalLinks } = this.state;
    if(downForMaintenance){
        return <MaintenancePage/>
    }
    return (
        <Router history={history}>
            {legalLinks.length > 0 ? 
            <Switch>
                <Route key={`Route-Login`} exact path="/" component={Login} />
                <Route key={`Route-SignUp`} exact path="/register" component={SignUp} />
                <ProtectedRoute key={`Route-AccountOverview`} path="/account" redirectTo="/" component={AccountOverview}/>
                <ProtectedRoute key={`Route-EditDetails`} path="/editdetails" redirectTo="/" component={EditDetails}/>
                <Route key={`Route-`} path="/accountmanagement" component={AccountManagement} />
                <ProtectedRoute key={`Route-AccountManagement`} path="/application/:id" component={Application} />
                <ProtectedRoute key={`Route-viewapplication`} path="/viewapplication/:id" component={AppSummary} />
                <ProtectedRoute key={`Route-changeapplication`} path="/changeapplication/:id" component={AppSummary} />
                <ProtectedRoute key={`Route-viewdetails`} path="/viewdetails/:id" component={ViewAppDetails} />
                <ProtectedRoute key={`Route-applicationshared`} path="/applicationshared/:appid" component={ApplicationShared} />
                <ProtectedRoute key={`Route-applicationforreview`} path="/applicationforreview/:appid" component={ApplicationSentForReview} />
                <ProtectedRoute key={`Route-applicationsubmitted`} path="/applicationsubmitted/:appid" component={ApplicationSubmitted} />
                {this.renderLegalLinks()}
            </Switch> : ''}
            <CookieConsent
                location="bottom"
                containerClasses={'cookie-footer'}
                buttonClasses={'enerva-btn uppercase-btn'}
                buttonText="I understand"
                cookieName="esb-cookie-policy"
                expires={150}
                >
                <p>This website uses cookies to enhance the user experience. Read our <a rel="noreferrer" target="_blank" href='https://eralberta.ca/privacy'>Privacy Policy</a></p>
            </CookieConsent>
        </Router>
    );
}
}