import firebase from '../helpers/Firebase';
import { utils } from '../helpers/utils';
const API_URL = firebase.apiUrl;
// const API_URL = 'http://localhost:5001/dolphin-21153/northamerica-northeast1';

export const applicationService = {
    getActiveContractors,
    createApplication,
    getApplication,
    getApplicationReview,
    getPostProjectApplicationReview,
    updateApplication,
    getFacilitiesConfig,
    createFacility,
    getFacility,
    updateFacility,
    getFacilitiesForCustomer,
    getApplicationsForParticipant,
    getApplicationsForParticipantbyStatus,
    getMeasureCategories,
    getMeasureTypes,
    getMeasures,
    getMeasureConfig,
    createAppMeasure,
    updateAppMeasure,
    getAppMeasures,
    getAppMeasure,
    deleteAppMeasure,
    createAppDocument,
    deleteAppDocument,
    getAppDocument,
    getAppDocumentsByStage,
    updateAppDocument,
    getAppDocuments,
    calculateMeasureValues,
    addCustomerNoteToApp,
    createChangeRequest,
    getAppChangeRequestLogs,
    updateBankDetailsInApp,
    getAppBankDetails,
    getAppBankDetailsReview,
    getBankBranches,
    virusScanForFileUploads
};

function virusScanForFileUploads(fileObj, folderPath){
    return utils.handleFileUpload(fileObj, folderPath);
}

function getBankBranches(institutionCode){
    return utils.makeWebRequest(`${API_URL}/admin/bankroutinginfo/${institutionCode}`,"GET");
}

function getFacilitiesConfig(){
    return utils.getAdminConfigDoc('facilityConfig');
}

function getFacilitiesForCustomer(customerId){
    return utils.makeWebRequest(`${API_URL}/facilities/all/customer/${customerId}`,"GET");
}

function getMeasureCategories(){
    return utils.getAdminConfigDoc('measureCategories');
}

function getMeasureTypes(measureCategory){
    return utils.makeWebRequest(`${API_URL}/measures/spp/types/${measureCategory}`,"GET");
}

function getMeasures(measureType){
    return utils.makeWebRequest(`${API_URL}/measures/spp/measurecodes/${measureType}`,"GET");
}

function getMeasureConfig(stage, measureCode){
    return utils.makeWebRequest(`${API_URL}/measures/spp/${(stage === 'Post-Project' || stage === 'Payment') ? 'postproject' :''}measureconfig/${measureCode}`,"GET");
}

function getActiveContractors(){
    return utils.makeWebRequest(`${API_URL}/users/spp/all/contractors/active`,"GET");
}

function createApplication(data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications`,"POST", data);
}

function getApplication(appId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/${appId}`,"GET");
}

function getApplicationReview(appId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/review/${appId}`,"GET");
}

function getPostProjectApplicationReview(appId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/postprojectreview/${appId}`,"GET");
}

function updateBankDetailsInApp(applicationId, data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/bankdetails/${applicationId}`,"POST", data);
}

//BANK DETAILS
function getAppBankDetails(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/bankdetails/${applicationId}`,"GET");
}

function getAppBankDetailsReview(appId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/bankdetailsreview/${appId}`,"GET");
}

function calculateMeasureValues(measureConfig, formData){
    return utils.makeWebRequest(`${API_URL}/measures/calculate`,"POST", {measureConfig, formData});
}

function getApplicationsForParticipant(participantType, participantId, stage){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/all/${participantType}/${participantId}/${stage}`,"GET");
}
function getApplicationsForParticipantbyStatus(participantType, participantId, stage,status){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/all/${participantType}/${participantId}/${stage}/${status}`,"GET");
}

function updateApplication(appId, data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/${appId}`,"PUT", data);
}

function createFacility(data){
    return utils.makeWebRequest(`${API_URL}/facilities`,"POST", data);
}

function getFacility(facilityId){
    return utils.makeWebRequest(`${API_URL}/facilities/${facilityId}`,"GET");
}

function updateFacility(facilityId, data){
    return utils.makeWebRequest(`${API_URL}/facilities/${facilityId}`,"PUT", data);
}

function createAppMeasure(data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/measure`,"POST", data);
}

function updateAppMeasure(measureId, data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/measure/${measureId}`,"PUT", data);
}

function getAppMeasure(measureId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/measure/${measureId}`,"GET");
}

function deleteAppMeasure(measureId, program){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/measure/${measureId}/program/${program}`,"DELETE");
}

function getAppMeasures(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/measure/all/${applicationId}`,"GET");
}

function createAppDocument(data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/documents`,"POST", data);
}

function addCustomerNoteToApp(data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/customernote`,"POST", data);
}

function updateAppDocument(documentId, data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/documents/${documentId}`,"PUT", data);
}

function getAppDocument(documentId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/documents/${documentId}`,"GET");
}

function deleteAppDocument(documentId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/documents/${documentId}`,"DELETE");
}

function getAppDocuments(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/documents/all/${applicationId}`,"GET");
}

function getAppDocumentsByStage(applicationId,stage){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/documents/all/${applicationId}/${stage}`,"GET");
}
//===Amsi===Change Request Services===//

function createChangeRequest(data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/changerequests`,"POST", data);
}

function getAppChangeRequestLogs(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/changerequests/all/${applicationId}`,"GET");
}
//===Amsi===End of Change Request Services===//